import Leftmenu from '../element/leftmenu';
import Databorder from '../element/header';
import React, {Component} from "react";
import { DatePicker,Popconfirm,Button,Menu, Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Line } from '@antv/g2plot';
import Histogramecharts from "../element/histogramecharts";
import Pieecharts from "../element/pieecharts";
import Linecharts from "../element/lineecharts";
import Line1charts from "../element/line1echarts";
import Line2charts from "../element/line2echarts";
import Line3charts from "../element/line3echarts";
import Pagelist from "../element/pagelist";
import Dataheader from "../element/dataheader";
import Datamenu from "../element/datamenu";
import Factorymapcharts from "../element/factorymapcharts";
import Datapie from "../element/datapie";
import Datatitle from "../element/datatitle";
import moment from "moment";
import Datafunnel from "../element/datafunnel";
import Datalinebottom from "../element/datalinebottom";
import Datacategory from "../element/datacategory";
import Datatitlemonth from "../element/datatitlemonty";
import Dataring from "../element/dataring";
import Dataline from "../element/dataline";
import Databg from "../element/databg";
import Dataradar from "../element/dataradar";
import Datamaterialspie from "../element/datamaterialspie";
import Dataproductionline from "../element/dataproductionline";
import Datamaterialsline from "../element/datamaterialsline";

class DataMaterials extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            array: [{"id":60094,"chanliang":null,"taishichanliang":null,"yunzhuanlv":null,"gongxudanweidianhao":null,"cao":"43.20","sio2":"13.30","al2o3":"3.50","fe2o3":"2.20","mgo":"1.20","k2o":"0.70","na2o":"0.20","so3":"1.70","kh":"1.00"}],
            array2: [{"id":60094,"chanliang":null,"taishichanliang":null,"yunzhuanlv":null,"gongxudanweidianhao":null,"cao":"43.20","sio2":"13.30","al2o3":"3.50","fe2o3":"2.20","mgo":"1.20","k2o":"0.70","na2o":"0.20","so3":"1.70","kh":"1.00"}],
            raw: [{"id":24508,"chanliang":"485.00","taishichanliang":"485.00","yunzhuanlv":"100.00","gongxudanweidianhao":"16.08","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24507,"chanliang":"490.00","taishichanliang":"490.00","yunzhuanlv":"100.00","gongxudanweidianhao":"15.61","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24506,"chanliang":"103.00","taishichanliang":"206.00","yunzhuanlv":"50.00","gongxudanweidianhao":"19.33","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24505,"chanliang":"0.00","taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24504,"chanliang":"315.00","taishichanliang":"420.00","yunzhuanlv":"75.00","gongxudanweidianhao":"16.91","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24503,"chanliang":"493.00","taishichanliang":"493.00","yunzhuanlv":"100.00","gongxudanweidianhao":"14.88","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24501,"chanliang":"495.00","taishichanliang":"495.00","yunzhuanlv":"100.00","gongxudanweidianhao":"15.16","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null}],
            raw2: [{"id":24508,"chanliang":"485.00","taishichanliang":"485.00","yunzhuanlv":"100.00","gongxudanweidianhao":"16.08","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24507,"chanliang":"490.00","taishichanliang":"490.00","yunzhuanlv":"100.00","gongxudanweidianhao":"15.61","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24506,"chanliang":"103.00","taishichanliang":"206.00","yunzhuanlv":"50.00","gongxudanweidianhao":"19.33","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24505,"chanliang":"0.00","taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24504,"chanliang":"315.00","taishichanliang":"420.00","yunzhuanlv":"75.00","gongxudanweidianhao":"16.91","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24503,"chanliang":"493.00","taishichanliang":"493.00","yunzhuanlv":"100.00","gongxudanweidianhao":"14.88","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null},{"id":24501,"chanliang":"495.00","taishichanliang":"495.00","yunzhuanlv":"100.00","gongxudanweidianhao":"15.16","cao":null,"sio2":null,"al2o3":null,"fe2o3":null,"mgo":null,"k2o":null,"na2o":null,"so3":null,"kh":null}],
            area: pid,
            list: [
                {
                    floor:"矿粉",
                    unit:"4742.3",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"熟料",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"石灰石粉",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"2714.2",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"复合超细粉",
                    unit:"2714.2",
                    elevator:"010301043",
                    medium:"4742.3",
                    tag:"ZL60",
                    taboo:"35",
                },
            ]
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getpdneutron")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdneutron2")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdraw?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        raw: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdraw2?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        raw2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {
        return (
            <div>
                <div className="mainmax">
                    <Dataheader/>
                    <div className="colorbg">
                        <div className="datamheigh top10">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <p>1号生料磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texture"></div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-3"></i>
                                <p>产量<br/>{this.state.raw[0].chanliang}(吨/时)</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-2"></i>
                                <p>台时产量<br/>{this.state.raw[0].taishichanliang}(吨/时)</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-1"></i>
                                <p>运转率<br/>{this.state.raw[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-"></i>
                                <p>工序单位电耗<br/>{this.state.raw[0].gongxudanweidianhao}(千瓦/时)</p>
                            </div>
                            <div className="texturebottom"></div>
                        </div>
                        <div className="datamheigh top10 left20">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <p>2号生料磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texture"></div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-3"></i>
                                <p>产量<br/>{this.state.raw2[0].chanliang}(吨/时)</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-2"></i>
                                <p>台时产量<br/>{this.state.raw2[0].taishichanliang}(吨/时)</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-1"></i>
                                <p>运转率<br/>{this.state.raw2[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-gongcheng-"></i>
                                <p>工序单位电耗<br/>{this.state.raw2[0].gongxudanweidianhao}(千瓦/时)</p>
                            </div>
                            <div className="texturebottom"></div>
                        </div>
                        <div className="datawheigh top60 left5">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <p>1号中子质量</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>CaO<br/>{this.state.array[0].cao}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>SiO2<br/>{this.state.array[0].sio2}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>Al2O3<br/>{this.state.array[0].al2o3}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>Fe2O3<br/>{this.state.array[0].fe2o3}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>MgO<br/>{this.state.array[0].mgo}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>K2O<br/>{this.state.array[0].k2o}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>Na2O<br/>{this.state.array[0].na2o}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>SO3<br/>{this.state.array[0].so3}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>KH<br/>{this.state.array[0].kh}</p>
                            </div>
                        </div>
                        <div className="datawheigh top80 left5">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <p>2号中子质量</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>CaO<br/>{this.state.array2[0].cao}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>SiO2<br/>{this.state.array2[0].sio2}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>Al2O3<br/>{this.state.array2[0].al2o3}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>Fe2O3<br/>{this.state.array2[0].fe2o3}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>MgO<br/>{this.state.array2[0].mgo}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>K2O<br/>{this.state.array2[0].k2o}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>Na2O<br/>{this.state.array2[0].na2o}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>SO3<br/>{this.state.array2[0].so3}</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <p>KH<br/>{this.state.array2[0].kh}</p>
                            </div>
                        </div>
                        <div className="datamaterialsheigh right0 top15">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <p>累计产量曲线</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <Datamaterialsline />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DataMaterials;
