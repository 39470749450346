import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select, Divider,
    Popconfirm, Table, Tag, Modal, Form, Switch
} from 'antd';
import {EditOutlined, DeleteOutlined, WechatOutlined} from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
import Backgroung from '../img/seal.png';
import * as url from "url";

const {Title, Text} = Typography;
const {Content} = Layout;
const {Meta} = Card;

const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '提货编号',
        dataIndex: 'pick_pickid',
        key: 'pick_pickid',
    },
    {
        title: '提货单位',
        dataIndex: 'pick_clientname',
        key: 'pick_clientname',
    },
    {
        title: '提货类型',
        dataIndex: 'pick_stock',
        key: 'pick_stock',
    },
    {
        title: '提货量（吨）',
        dataIndex: 'pick_num',
        key: 'pick_num',
    },
    {
        title: '提货车辆',
        dataIndex: 'pick_truck',
        key: 'pick_truck',
    },
    {
        title: '提货时间',
        dataIndex: 'pick_time',
        key: 'pick_time',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Print id={record.pick_id}></Print>
            </Space>
        ),
    },
];

const Load = (that, id) => {
    fetch("http://pd.pokemonbye.com:8086/api/getsellclientwxbyclient?client=" + id)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;

                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const Print = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellpickstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button type="default" size="small" onClick={showPopconfirm}>
                打印
            </Button>
        </Popconfirm>
    );
};
const onGo = (id) => {
    window.location.href = "/pagepurrecord?id=" + id
};

const print = () => {
    window.document.body.innerHTML = window.document.getElementById('billDetails').innerHTML;
    window.print();
    window.location.reload();
}

class PagePurTicket extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        this.state = {
            title: "提货票据",
            county: [],
            id: id,
            list: [],
            admin: []
        };
    }

    componentDidMount() {
        const id = this.state.id;
        if (id) {
            fetch("http://pd.pokemonbye.com:8086/api/getsellpickbynumstate?state=2&id=" + id)
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data;
                        if (array != null && array.length > 0) {
                            for (let i = 0; i < array.length; i++) {
                                array[i].key = i + 1
                            }
                            this.setState({
                                isLoaded: true,
                                list: array[0]
                            });
                        }
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
        fetch("http://pd.pokemonbye.com:8086/api/getsellsysdata")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    if (array != null && array.length > 0) {
                        for (let i = 0; i < array.length; i++) {
                            array[i].key = i + 1
                        }
                        this.setState({
                            isLoaded: true,
                            admin: array[0]
                        });
                    }
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const handleChange = (value) => {
            Load(this, value)
        };
        return (
            <div>
                <Content className="site-layout-background">
                    <div className="site-page-header-ghost-wrapper">
                        <Space
                               id={'billDetails'}
                               direction="vertical"
                               style={{
                                   maxWidth: '300px',
                                   backgroundColor: '#FFFFFF'
                               }}>
                            <div className="background-seal"></div>
                            <PageHeader
                                ghost={false}
                                style={{
                                    backgroundColor: '#00000000'
                                }}
                            >
                                <Title level={4}
                                       style={{
                                           textAlign: 'center',
                                           borderBottom: 'solid 1px'
                                       }}>{this.state.admin.sysdata_name}<br/>产品提货单</Title>
                                <Space direction="vertical"
                                       style={{
                                           textAlign: 'left'
                                       }}>
                                    <Text>生产厂商：{this.state.admin.sysdata_name}</Text>
                                    <Text>品牌：{this.state.admin.sysdata_brand}</Text>
                                    <Text>提货客户：{this.state.list.pick_clientname}</Text>
                                    <Text>提货编号：{this.state.list.pick_pickid}</Text>
                                    <Text>货品类型：{this.state.list.pick_stock}</Text>
                                    <Text>净重（吨）：{this.state.list.pick_num}</Text>
                                    <Text>提货车辆：{this.state.list.pick_truck}</Text>
                                    <Text>进厂时间：{this.state.list.pick_pubtime}</Text>
                                    <Text>出厂时间：{this.state.list.pick_pubtime}</Text>
                                </Space>
                                <br/>
                                <br/>
                                <br/>
                                <Title level={4}
                                       style={{
                                           textAlign: 'center',
                                           borderBottom: 'solid 1px'
                                       }}>{this.state.admin.sysdata_name}<br/>产品合格证</Title>
                                <Space direction="vertical"
                                       style={{
                                           textAlign: 'left'
                                       }}>
                                    <Text>产品名称：矿渣微粉</Text>
                                    <Text>规格型号：S95</Text>
                                    <Text>包装形式：散装</Text>
                                    <Text>检验员：张三</Text>
                                    <Text>出厂编号：HGJC23016</Text>
                                    <Text>出厂日期：2023年9月10日</Text>
                                    <Text>检测结果评定：经检验本产品符合GB/T18046-2017技术要求及TB10424-2018标准要求。</Text>
                                </Space>
                                <hr/>
                            </PageHeader>
                        </Space>
                        <PageHeader
                            ghost={false}
                            style={{
                                maxWidth: '300px'
                            }}
                        >
                            <Space size={30}>
                                <Button type="primary" onClick={() => print()}>打印</Button>
                                <Button disabled>管理</Button>
                                <Button disabled>删除</Button>
                            </Space>
                        </PageHeader>
                    </div>
                </Content>
            </div>
        );
    }
}

export default PagePurTicket;


