import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Dataringmonth extends Component {
    constructor(props) {
        super(props);
        const gaugeData = [
            {
                value: 131818,
                name: '生产计划',
                title: {
                    offsetCenter: ['0%', '-40%']
                },
                detail: {
                    valueAnimation: true,
                    offsetCenter: ['0%', '-15%']
                }
            },
            {
                value: 73144,
                name: '实际生产',
                title: {
                    offsetCenter: ['0%', '15%']
                },
                detail: {
                    valueAnimation: true,
                    offsetCenter: ['0%', '40%']
                }
            }
        ];
        let option = {
            color: ['#103986','#4e7bd3'],
            backgroundColor:'rgba(128, 128, 128, 0)',
            radius: '55%',
            series: [
                {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#464646'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            width: 30
                        }
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 16
                    },
                    detail: {
                        offsetCenter: ["100%", "0"],
                        fontSize: 18,
                        color: 'inherit',
                        borderColor: 'inherit',
                        formatter: '{value}'
                    }
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getpdmonth")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        let pd = array[0]?array[0].shuinichanliang:0;
        let pd2 = array[0]?array[0].shuinijihua:0;
        const data = [
            {
                value: pd,
                name: '实际生产(吨)',
                title: {
                    offsetCenter: ['0%', '-40%']
                },
                detail: {
                    valueAnimation: true,
                    offsetCenter: ['0%', '-15%']
                }
            },
            {
                value: pd2,
                name: '计划完成率(%)',
                title: {
                    offsetCenter: ['0%', '15%']
                },
                detail: {
                    valueAnimation: true,
                    offsetCenter: ['0%', '40%']
                }
            }
        ];

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('dataringmonth'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            series: [{
                data
            }
            ]
        });
    }

    render() {
        return (
            <div id="dataringmonth" className="histogramlar"></div>
        )
    }
}

export default Dataringmonth;
