import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm,
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;
const content = (<Select
        defaultValue="lucy"
        style={{
            width: 200,
        }}
        options={[
            {
                label: '未绑定',
                options: [
                    {
                        value: 'jack',
                    },
                    {
                        value: 'lucy',
                    },
                ],
            },
            {
                label: '已绑定',
                options: [
                    {
                        value: 'Yiminghe',
                    },
                ],
            },
        ]}
    />
);

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const itemClick = (id) => {
    window.location.href = '/pagebusrecord?id='+id;
};
const confirm = (e) => {
    console.log(1);
};
const cancel = (e) => {
    console.log(2);
};
const onGo2 = () => {
    window.location.href="/pagebusrecord"
};

const Delete = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellclientstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <DeleteOutlined onClick={showPopconfirm}>
                Download
            </DeleteOutlined>
        </Popconfirm>
    );
};

class PageBusRecords extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "客户档案管理",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
        fetch("https://pd.pokemonbye.com:8086/api/getsellclient")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Button onClick={() => onGo2()}>新增</Button>
                                <br/>
                                <br/>
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 4,
                                        xl: 6,
                                        xxl: 4,
                                    }}
                                    dataSource={this.state.list}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Card
                                                title={item.name}
                                                hoverable={true}
                                                actions={[
                                                <EditOutlined key="edit" onClick={(e) => itemClick(item.id)}/>,
                                                <Delete id={item.id}>删除</Delete>
                                            ]}><Meta
                                                avatar={<Avatar size={50} style={item.state = 2 ?{backgroundColor: '#f56a00'}:{backgroundColor: '#87d068'}}>{item.name.slice(2,3)}</Avatar>}
                                                title={item.address}
                                                description={item.tel}
                                            /></Card>
                                        </List.Item>
                                    )}
                                />

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageBusRecords;


