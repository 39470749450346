import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const columns = [
    {
        title: '类型',
        dataIndex: 'stockname',
        key: 'stockname',
        render: (stockname) => <p>{stockname ? stockname : '/'}</p>,
    },
    {
        title: '销量（吨）',
        dataIndex: 'dayvalue',
        key: 'dayvalue',
        sorter: (a, b) => a.dayvalue - b.dayvalue,
        render: (dayvalue) => <p>{dayvalue ? dayvalue : '0.00'}</p>,
    },
    {
        title: '销售额（万元）',
        dataIndex: 'dayprice',
        key: 'dayprice',
        sorter: (a, b) => a.dayprice - b.dayprice,
        render: (dayprice) => <p>{dayprice ? (dayprice/10000).toFixed(2) : '0.00'}</p>,
    },
    {
        title: '订单量',
        dataIndex: 'num',
        key: 'num',
        sorter: (a, b) => a.num - b.num,
        render: (num) => <p>{num ? num : 0}</p>,
    }
];
const columnsman = [
    {
        title: '销售人员',
        dataIndex: 'stockname',
        key: 'stockname',
        render: (stockname) => <p>{stockname ? stockname : '/'}</p>,
    },
    {
        title: '销量（吨）',
        dataIndex: 'dayvalue',
        key: 'dayvalue',
        sorter: (a, b) => a.dayvalue - b.dayvalue,
        render: (dayvalue) => <p>{dayvalue ? dayvalue : '0.00'}</p>,
    },
    {
        title: '销售额（万元）',
        dataIndex: 'dayprice',
        key: 'dayprice',
        sorter: (a, b) => a.dayprice - b.dayprice,
        render: (dayprice) => <p>{dayprice ? (dayprice/10000).toFixed(2) : '0.00'}</p>,
    },
    {
        title: '订单量',
        dataIndex: 'num',
        key: 'num',
        sorter: (a, b) => a.num - b.num,
        render: (num) => <p>{num ? num : 0}</p>,
    }
];
const columnscus = [
    {
        title: '客户',
        dataIndex: 'stockname',
        key: 'stockname',
        render: (stockname) => <p>{stockname ? stockname : '/'}</p>,
    },
    {
        title: '销量（吨）',
        dataIndex: 'dayvalue',
        key: 'dayvalue',
        sorter: (a, b) => a.dayvalue - b.dayvalue,
        render: (dayvalue) => <p>{dayvalue ? dayvalue : '0.00'}</p>,
    },
    {
        title: '销售额（万元）',
        dataIndex: 'dayprice',
        key: 'dayprice',
        sorter: (a, b) => a.dayprice - b.dayprice,
        render: (dayprice) => <p>{dayprice ? (dayprice/10000).toFixed(2) : '0.00'}</p>,
    },
    {
        title: '订单量',
        dataIndex: 'num',
        key: 'num',
        sorter: (a, b) => a.num - b.num,
        render: (num) => <p>{num ? num : 0}</p>,
    }
];

const Load = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8087/api/getselldatasearchstate?start="+start+"&end="+end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                console.log(array)
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    fetch("http://pd.pokemonbye.com:8087/api/getselldatasearchman?start="+start+"&end="+end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                console.log(array)
                that.setState({
                    isLoaded: true,
                    listman: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    fetch("http://pd.pokemonbye.com:8087/api/getselldatasearchcus?start="+start+"&end="+end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                console.log(array)
                that.setState({
                    isLoaded: true,
                    listcus: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )

}

class PageDataSerch extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "数据查询",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        Load(this,moment().subtract(1, "days").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    }

    render() {
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="根据条件进行数据查询数据"
                            >
                                <Space>
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    options={[
                                        {
                                            value: '富平公司',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                                <RangePicker defaultValue={[moment().subtract(1, "days"), moment()]} onChange={onChange}/>
                            </Space>
                                <Tabs defaultActiveKey="1">
                                    <Tabs.TabPane tab="类型" key="1">
                                        <Table bordered columns={columns} dataSource={this.state.list}/>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="人员" key="2">
                                        <Table bordered columns={columnsman} dataSource={this.state.listman}/>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="客户" key="3">
                                        <Table bordered columns={columnscus} dataSource={this.state.listcus}/>
                                    </Tabs.TabPane>
                                </Tabs>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageDataSerch;


