import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs, Popover
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const DecimalStep = (that) => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={1000}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={1000}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const columns = [
    {
        title: '类型',
        dataIndex: 'stockname',
        key: 'stockname',
    },
    {
        title: '销量（吨）',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: '金额（元）',
        dataIndex: 'price',
        key: 'price',
        render: (price) => <p>{price ? (price/1).toFixed(2) : '0.00'}</p>,
    },
    {
        title: '区域',
        dataIndex: 'project',
        key: 'project',
    },
    {
        title: '车辆',
        dataIndex: 'truck',
        key: 'truck',
    },
    {
        title: '销售人',
        dataIndex: 'man',
        key: 'man',
    },
    {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
        render: (date) => <p>{date ? date.substring(0,19) : '0.00'}</p>,
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Popover content={content} title="订单调价（元）" trigger="click">
                    <Button size="small">
                        调价
                    </Button>
                </Popover>
                <Button size="small" onClick={confirm}>
                    化验单
                </Button>
                <Button type="primary" size="small" disabled>
                    删除
                </Button>
            </Space>
        ),
    },
];
const confirm = (e) => {
    window.open('http://booleango-stsn.oss-cn-beijing.aliyuncs.com/huayandan.jpg');
};

const content = (
    <>
        <Space direction="vertical"
               style={{
                   width: 300,
               }}>
            <DecimalStep/>
            <Button type="default" size="small" disabled>
                保存
            </Button>
        </Space>
    </>
);

const Load = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8087/api/getsellbillbydate?start=" + start + "&end=" + end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                console.log(array)
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class PagePurOrders extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "订单信息",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        Load(this,moment().subtract(1, "days").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    }

    render() {
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="筛选查询订单"
                            >
                                <Space>
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    options={[
                                        {
                                            value: '富平公司',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                                <RangePicker defaultValue={[moment().subtract(1, "days"), moment()]} onChange={onChange}/>
                            </Space>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PagePurOrders;


