import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'
import moment from "moment";

class Datafunnel extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            color: ['#103986','#4e7bd3','#4c29af'],
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c}'
            },
            series: [
                {
                    name: '隐患',
                    type: 'funnel',
                    left: '10%',
                    width: '80%',
                    label: {
                        formatter: '{b}'
                    },
                    labelLine: {
                        show: false
                    },
                    itemStyle: {
                        opacity: 0.7
                    },
                    emphasis: {
                        label: {
                            position: 'inside',
                            formatter: '{b}'
                        }
                    },
                    data: [
                        { value: 0, name: '三违隐患' },
                        { value: 0, name: '一般隐患' },
                        { value: 0, name: '重大隐患' },
                        { value: 0, name: '整改量' }
                    ]
                },
                {
                    name: '隐患',
                    type: 'funnel',
                    left: '10%',
                    width: '80%',
                    maxSize: '80%',
                    label: {
                        position: 'inside',
                        formatter: '{c}',
                        color: '#fff'
                    },
                    itemStyle: {
                        opacity: 0.5,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    emphasis: {
                        label: {
                            position: 'inside',
                            formatter: '{b}: {c}'
                        }
                    },
                    data: [
                        { value: 0, name: '三违隐患' },
                        { value: 0, name: '一般隐患' },
                        { value: 0, name: '重大隐患' },
                        { value: 0, name: '整改量' }
                    ],
                    // Ensure outer shape will not be over inner shape when hover.
                    z: 100
                }
            ]
        };
        this.state = {
            option: option,
            array: [],
            array2: []
        };
    }

    componentDidMount() {
        let yar = moment().format('YYYY-MM');
        yar = yar + "-01";
        yar = new Date(yar.replace(/-/g, "/")).getTime()/1000;
        fetch("https://pd.pokemonbye.com:8086/api/getamend?start="+ yar +"&end=" + moment().format('X'))
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("https://pd.pokemonbye.com:8086/api/getamendnum?start="+ yar +"&end=" + moment().format('X'))
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        let array2 = this.state.array2;
        const data = [];

        let data1 = null;
        for (var i = 0; i < array.length; i++) {

            if (array[i].level1 == 1) {
                data1 = array[i].level2
            }
        }

        let data2 = null;
        for (var i = 0; i < array.length; i++) {

            if (array[i].level1 == 2) {
                data2 = array[i].level2
            }
        }

        let data3 = null;
        for (var i = 0; i < array.length; i++) {

            if (array[i].level1 == 3) {
                data3 = array[i].level2
            }
        }

        data.push(
            { value:data1 ? data1 : 0, name: '三违' }
        );
        data.push(
            { value:data2 ? data2 : 0, name: '一般' }
        );
        data.push(
            { value:data3 ? data3 : 0, name: '重大' }
        );
        data.push(
            { value: array2[0]?array2[0].amend:0, name: '整改' }
        );


        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datafunnel'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            series: [{
                data
            },{
                data
            },
            ]
        });
    }

    render() {
        return (
            <div id="datafunnel" className="histogrambottom"></div>
        )
    }
}

export default Datafunnel;
