import { useSearchParams } from "react-router-dom";

function Pagelist(props) {
  let [searchParam , setSearchParam] = useSearchParams();
  let id = searchParam.get("id");
  let list = props.value.list;

  let listArr = list.map((item,index,arr) => {
    return (
        <ListItem value={item} id={index} key={index}/>
    )
  })
  return (
      <div className="panel panel-default transparency">
        <div className="panel-body">
          <div className="table-responsive">
            <table className="table" id="table1">
              <thead>
              <tr>
                <th>序号</th>
                <th>单品名称</th>
                <th>日累计</th>
                <th>月累计</th>
                <th>年累计</th>
              </tr>
              </thead>
              <tbody>
              {listArr}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}

function ListItem(props){
  return(
      <tr className="gradeA">
        <td>{props.id+1}</td>
        <td>{props.value.floor}</td>
        <td>{props.value.unit}</td>
        <td>{props.value.elevator}</td>
        <td>{props.value.medium}</td>
      </tr>
  )
}

export default Pagelist;
