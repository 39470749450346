import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined, FormOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Table, Tag, Modal, Form, Switch, notification
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const openNotification = () => {
    notification.open({
        message: '成功',
        description:
            '修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};
const handleOk = (id,value) => {
    console.log(id)
    console.log(value)
    setTimeout(() => {

        fetch("http://pd.pokemonbye.com:8086/api/setsellareaname?name=" + value + "&id=" + id.id)
            .then(res => res.json())
            .then(
                (result) => {
                    openNotification();
                    window.location.reload()
                },
                (error) => {
                }
            )
    }, 1000);
};
const DecimalStep = (id) => {
    const [inputValue, setInputValue] = useState('');
    const onChange = (value) => {
        console.log(value.target.value)
        setInputValue(value.target.value);
    };
    return (
        <>
            <Input onChange={onChange}></Input>
            <br/>
            <br/>
            <Button type="default" onClick={() => handleOk(id.id,inputValue)}>
                提交修改
            </Button>
        </>
    );
};
const content = (id) => (
    <>
        <Space direction="vertical"
               style={{
                   width: 300,
               }}>
            <DecimalStep id={id}/>
        </Space>
    </>
);
const Go = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popover content={() => content(id)} title="修改区域名" trigger="click">
            <Button>
                修改
            </Button>
        </Popover>
    );
};
const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '区域名',
        dataIndex: 'area_name',
        key: 'area_name',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Go id={record.area_id}></Go>
                <Delete id={record.area_id}></Delete>
            </Space>
        ),
    },
];

const ADD = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                新建账号
            </Button>
            <Modal
                open={open}
                title="账号新建"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        关闭
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        新建
                    </Button>,
                ]}
            >
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    layout="horizontal"
                    size="Large"
                >
                    <Form.Item label="客户编号">
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

const Load = (that,id) => {
    fetch("http://pd.pokemonbye.com:8086/api/getsellclientwxbyclient?client=" + id)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;

                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const Delete = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellclientwxstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button type="default" onClick={showPopconfirm} disabled>
                删除
            </Button>
        </Popconfirm>
    );
};
const onGo = (id) => {
    window.location.href="/pagepurrecord?id="+id
};
const onGo1 = () => {
    window.location.href="/pagepurrecord?level=1"
};
const onGo2 = () => {
    window.location.href="/pagepurrecord?level=2"
};
const onGo3 = () => {
    window.location.href="/pagepurrecord?level=3"
};

const newcontent = (
    <div>
        <Space>
        <Button onClick={() => onGo1()}>管理员</Button>
        <Button onClick={() => onGo2()}>业务员</Button>
        <Button onClick={() => onGo3()}>司机</Button>
        </Space>
    </div>
);
const New = () => (
    <Popover content={newcontent} title="选择账号类型" trigger="click" placement="bottomLeft">
        <Button type="primary">新建账号</Button>
    </Popover>
);

class PageSellAreas extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "区域管理",
            county: [],
            area: pid,
            list: [],
            menu: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getsellarealist")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].key = i + 1
                    }
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const handleChange = (value) => {
            Load(this,value)
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Button type="primary" disabled>新增</Button>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellAreas;


