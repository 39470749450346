import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs,
    Badge
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";
import Datasellbottom from "../element/datasellbottom";
import Datasellstaff from "../element/datasellstaff";
import Datasellonmonth from "../element/datasellonmonth";
import Datasellonyear from "../element/datasellonyear";
import Datasellonclient from "../element/datasellonclient";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const columns = [
    {
        title: '类型',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: '销量（吨）',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '销售额（万元）',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '订单量',
        dataIndex: 'address',
        key: 'address',
    }
];
const data = [
    {
        key: '1',
        name: '水泥P.O42.5级散',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];

const App = () => {
    const [size, setSize] = useState('middle');
    const handleSizeChange = (e) => {
        setSize(e.target.value);
    };
    return (
        <Space direction="vertical" size={12}>
            <Radio.Group value={size} onChange={handleSizeChange}>
                <Radio.Button value="日销">日销</Radio.Button>
                <Radio.Button value="月累计">月累计</Radio.Button>
                <Radio.Button value="年累计">年累计</Radio.Button>
            </Radio.Group>
        </Space>
    );
};

const content = ({ children, extra }) => (
    <div className="content">
        <div className="main">{children}</div>
        <div className="extra">{extra}</div>
    </div>
);

const Load = (that,day,client) => {

    fetch("http://pd.pokemonbye.com:8087/api/getselldatastateyearbyclient?client=" + client + "&day=" + day)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                that.setState({
                    isLoaded: true,
                    listnum: array
                });
                fetch("http://pd.pokemonbye.com:8087/api/getselldatastatemonthbyclient?client=" + client + "&day=" + day)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let array = result.data;
                            let list = that.state.listnum;
                            for (let i = 0; i < array.length; i++) {
                                for (let j = 0; j < list.length; j++) {
                                    if(array[i].stockid == list[j].stockid){
                                        list[j].monthvalue = array[i].dayvalue;
                                        list[j].monthprice = array[i].dayprice;
                                    }
                                }
                            }
                            that.setState({
                                isLoaded: true,
                                listnum1: list
                            });
                            fetch("http://pd.pokemonbye.com:8087/api/getselldatastatedaybyclient?client=" + client + "&day=" + day)
                                .then(res => res.json())
                                .then(
                                    (result) => {
                                        let array = result.data;
                                        let list = that.state.listnum1;
                                        for (let i = 0; i < array.length; i++) {
                                            for (let j = 0; j < list.length; j++) {
                                                if(array[i].stockid == list[j].stockid){
                                                    list[j].value = array[i].dayvalue;
                                                    list[j].price = array[i].dayprice;
                                                }
                                            }
                                        }
                                        console.log(list)
                                        that.setState({
                                            isLoaded: true,
                                            list: list
                                        });
                                    },
                                    // 注意：需要在此处处理错误
                                    // 而不是使用 catch() 去捕获错误
                                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                                    (error) => {
                                        that.setState({
                                            isLoaded: true,
                                            error
                                        });
                                    }
                                )
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            that.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
};

const Load2 = (that,day,client) => {

    fetch("http://pd.pokemonbye.com:8087/api/getselldatastateyearbyclient?client=" + client + "&day=" + day)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                that.setState({
                    isLoaded: true,
                    listnum2: array
                });
                fetch("http://pd.pokemonbye.com:8087/api/getselldatastatemonthbyclient?client=" + client + "&day=" + day)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let array = result.data;
                            let list = that.state.listnum2;
                            for (let i = 0; i < array.length; i++) {
                                for (let j = 0; j < list.length; j++) {
                                    if(array[i].stockid == list[j].stockid){
                                        list[j].monthvalue = array[i].dayvalue;
                                        list[j].monthprice = array[i].dayprice;
                                    }
                                }
                            }
                            that.setState({
                                isLoaded: true,
                                listnum12: list
                            });
                            fetch("http://pd.pokemonbye.com:8087/api/getselldatastatedaybyclient?client=" + client + "&day=" + day)
                                .then(res => res.json())
                                .then(
                                    (result) => {
                                        let array = result.data;
                                        let list = that.state.listnum12;
                                        for (let i = 0; i < array.length; i++) {
                                            for (let j = 0; j < list.length; j++) {
                                                if(array[i].stockid == list[j].stockid){
                                                    list[j].value = array[i].dayvalue;
                                                    list[j].price = array[i].dayprice;
                                                }
                                            }
                                        }
                                        console.log(list)
                                        that.setState({
                                            isLoaded: true,
                                            list2: list
                                        });
                                    },
                                    // 注意：需要在此处处理错误
                                    // 而不是使用 catch() 去捕获错误
                                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                                    (error) => {
                                        that.setState({
                                            isLoaded: true,
                                            error
                                        });
                                    }
                                )
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            that.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
};
class PageDataClient extends Component {

    handleOnClick = (name,value)=>{
        this.Child.current.func(name,value);
    }
    handleOnClick2 = (name,value)=>{
        this.Child.current.func2(name,value);
    }

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "客户对比",
            county: [],
            area: pid,
            menu: [],
            list: [],
            name: ""
        };
        this.Child = React.createRef();
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8087/api/getsellclientdao")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].value = array[i].stockid;
                        array[i].label = array[i].stockname;

                    }
                    this.setState({
                        isLoaded: true,
                        menu: array
                    });
                    console.log(array)
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const handleChange = (value) => {
            console.log(`selected ${value}`);

            let array = this.state.menu;
            var name = '';
            for (let i = 0; i < array.length; i++) {
                if(array[i].value == value){
                    name = array[i].label;
                }
            }
            this.handleOnClick(name,value);
            this.setState({
                name: name
            });
            Load(this,moment().format('YYYY-MM-DD'),value)
        };
        const handleChange2 = (value) => {
            console.log(`selected ${value}`);
            let array = this.state.menu;
            var name = '';
            for (let i = 0; i < array.length; i++) {
                if(array[i].value == value){
                    name = array[i].label;
                }
            }
            this.handleOnClick2(name,value);
            this.setState({
                name2: name
            });
            Load2(this,moment().format('YYYY-MM-DD'),value)
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="对比客户需求能力"
                            >
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: '0',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                                <br/>
                                <br/>
                                <Row>
                                    <Col span={12} className="site-des-background">
                                        <Select
                                            defaultValue="请选择"
                                            style={{
                                                width: 220,
                                            }}
                                            onChange={handleChange}
                                            options={this.state.menu}
                                        />
                                        <br/>
                                        <br/>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="企业名" span={3}
                                                               style={{
                                                                   width: 300,
                                                               }}>
                                                {this.state.name ? this.state.name : "请选择"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="交易量（吨）" span={3}>
                                                {this.state.list ? this.state.list[0] ? this.state.list[0].value ? this.state.list[0].value : "0.00" : "0.00" : "0.00"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="月累计（吨）" span={3}>
                                                {this.state.list ? this.state.list[0] ? this.state.list[0].monthvalue ? this.state.list[0].monthvalue : "0.00" : "0.00" : "0.00"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="年累计（吨）" span={3}>
                                                {this.state.list ? this.state.list[0] ? this.state.list[0].dayvalue ? this.state.list[0].dayvalue : "0.00" : "0.00" : "0.00"}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col span={12} className="site-des-background">

                                        <Select
                                            defaultValue="请选择"
                                            style={{
                                                width: 220,
                                            }}
                                            onChange={handleChange2}
                                            options={this.state.menu}
                                        />
                                        <br/>
                                        <br/>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="企业名" span={3}
                                                               style={{
                                                                   width: 300,
                                                               }}>
                                                {this.state.name2 ? this.state.name2 : "请选择"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="交易量（吨）" span={3}>
                                                {this.state.list2 ? this.state.list2[0] ? this.state.list2[0].value ? this.state.list2[0].value : "0.00" : "0.00" : "0.00"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="月累计（吨）" span={3}>
                                                {this.state.list2 ? this.state.list2[0] ? this.state.list2[0].monthvalue ? this.state.list2[0].monthvalue : "0.00" : "0.00" : "0.00"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="年累计（吨）" span={3}>
                                                {this.state.list2 ? this.state.list2[0] ? this.state.list2[0].dayvalue ? this.state.list2[0].dayvalue : "0.00" : "0.00" : "0.00"}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                                <br/>
                                <br/>
                                <Title level={4}>本年度需求量对比</Title>
                                <Datasellonclient ref={this.Child}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageDataClient;


