
function Leftmenu() {
  return (

      <div className="leftpanel">

        <div className="logopanel">
          <a href="/">
            <h1>陕西建材科技<span>销售管理系统</span></h1>
          </a>
        </div>

        <div className="leftpanelinner">


          <h5 className="sidebartitle">导航</h5>
          <ul className="nav nav-pills nav-stacked nav-bracket">
            <li className="nav-parent">
              <input id="ac-6" type="checkbox"/><a><label htmlFor="ac-6"><span>数据报表</span><i
                className="iconfont icon-under"></i></label></a>
              <ul className="children">
                <li><a href="/pagedatastate">销售报表</a></li>
              </ul>
            </li>
            <li className="nav-parent">
              <input id="ac-1" type="checkbox"/><a><label htmlFor="ac-1"><span>数据中心</span><i
                className="iconfont icon-under"></i></label></a>
              <ul className="children">
                <li><a href="/pagedatastate">销售报表</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagedataserch">数据查询</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagedatacurve">销量曲线</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagedatacompare">销售对比</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagedataclient">客户对比</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagedatacapital">资金分析</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagedataorder">订单查询</a></li>
              </ul>
            </li>
            <li className="nav-parent">
              <input id="ac-2" type="checkbox"/><a><label htmlFor="ac-2"><span>销售管理</span><i
                className="iconfont icon-under"></i></label></a>
              <ul className="children">
                <li><a href="/pagesellbasic">基础数据管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pageselltask">任务管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagesellmans">人员管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagesellstocks">产品类型</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagesellareas">区域管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagesellsys">系统管理</a></li>
              </ul>
            </li>
            <li className="nav-parent">
              <input id="ac-3" type="checkbox"/><a><label htmlFor="ac-3"><span>采购中心</span><i
                className="iconfont icon-under"></i></label></a>
              <ul className="children">
                <li><a href="/pagepurdemand">需求提报</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagepurrecords">客户账号</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagepurpicks">预提货信息</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagepurpickeds">提货单</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagepurorders">订单信息</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagepurprices">资金管理</a></li>
              </ul>
            </li>
            <li className="nav-parent">
              <input id="ac-4" type="checkbox"/><a><label htmlFor="ac-4"><span>业务中心</span><i
                className="iconfont icon-under"></i></label></a>
              <ul className="children">
                <li><a href="/pagebusrecords">客户档案管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagebuscontracts">合同管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagebusorders">流水管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagebusplan">发运计划</a></li>
              </ul>
            </li>
            <li className="nav-parent">
              <input id="ac-5" type="checkbox"/><a><label htmlFor="ac-5"><span>财务管理</span><i
                className="iconfont icon-under"></i></label></a>
              <ul className="children">
                <li><a href="/pagefincontracts">合同审核</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagefinorders">订单审核</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagefinloans">贷款回收</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagefinbills">发票管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagefincredits">信用管理</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagefinsets">财务扎帐</a></li>
              </ul>
              <ul className="children">
                <li><a href="/pagefinchecks">客户对账单</a></li>
              </ul>
            </li>
          </ul>

          <div className="infosummary">
            <ul>
            </ul>
          </div>


        </div>

      </div>

  );
}

export default Leftmenu;
