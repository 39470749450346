import React, {Component} from "react";

class Pageheader extends Component{
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div className="pageheader">
                <h2>{this.props.value.title}</h2>
                <div className="breadcrumb-wrapper">
                    <ol className="breadcrumb">
                        <li><a href="/">首页</a></li>
                        <li className="active">{this.props.value.title}</li>
                    </ol>
                </div>
            </div>
        )
    }
}

export default Pageheader;
