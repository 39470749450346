import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Datacategory3 extends Component {
    constructor(props) {
        super(props);
        const color = {
            '氮氧化物':'#103986',
            '颗粒物':'#4e7bd3',
            '二氧化硫':'#4c29af',
        }
        const data = [];
        let option = {
            color: ['#103986','#4e7bd3','#4c29af'],
            backgroundColor:'rgba(128, 128, 128, 0)',
            legend: {},
            xAxis: {
                min: 0,
                max: 50
            },
            yAxis: {
                inverse: true,
                type: 'category',
                data: ['1线窑尾', '2线窑尾'],
                animationDuration: 300,
                animationDurationUpdate: 300,
                max: 1,
            },
            visualMap: {
                itemHeight:100,
                itemWidth:10,
                show: true,
                left: 'right',
                top:'center',
                padding:0,
                min: 0,
                max: 50,
                text: ['50', '0'],
                // Map the score column to color
                dimension: 0,
                inRange: {
                    color: ['#65B581', '#FFCE34', '#FD665F']
                }
            },
            series: [
                {
                    type: 'bar',
                    data: data,
                    label: {
                        show: true,
                        position: 'right',
                        valueAnimation: true
                    }
                }
            ],
            animationDuration: 0,
            animationDurationUpdate: 3000,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear'
        };
        this.state = {
            option: option,
            array: [],
            data: data
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getkafka3")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let data = this.state.array;
        function run() {

            fetch("http://pd.pokemonbye.com:8088/api/getkafka3")
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result;
                        data = array;

                        myChart.setOption({
                            series: [
                                {
                                    data
                                }
                            ]
                        });
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
        setTimeout(function () {
            run();
        }, 0);
        setInterval(function () {
            run();
        }, 1000);
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datacategory3'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="datacategory3" className="histogramlar1"></div>
        )
    }
}

export default Datacategory3;
