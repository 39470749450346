import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;
function number_chinese(str) {
    var num = parseFloat(str);
    var strOutput = "",
        strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
    num += "00";
    var intPos = num.indexOf('.');
    if (intPos >= 0){
        num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
    }
    strUnit = strUnit.substr(strUnit.length - num.length);
    for (var i=0; i < num.length; i++){
        strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i,1),1) + strUnit.substr(i,1);
    }
    return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元")

}
const columns = [
    {
        title: '提货日期',
        dataIndex: 'date',
        key: 'date',
        render: (date) => <p>{date ? date.substring(0,10) : '0.00'}</p>
    },
    {
        title: '订单号',
        dataIndex: 'lid',
        key: 'lid',
    },
    {
        title: '品名',
        dataIndex: 'stockname',
        key: 'stockname',
    },
    {
        title: '数量（吨）',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: '金额（元）',
        dataIndex: 'price',
        key: 'price',
        render: (price) => <p>{price ? (price/1).toFixed(2) : '0.00'}</p>,
    }
];

const Load = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8087/api/getsellbillbydate?start=" + start + "&end=" + end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                console.log(array)
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class PageFinChecks extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "客户对账单",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8087/api/getsellclientdao")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].value = array[i].stockid;
                        array[i].label = array[i].stockname;

                    }
                    this.setState({
                        isLoaded: true,
                        menu: array
                    });
                    Load(this,moment().subtract(1, 'day').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'));
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        Load(this,moment().subtract(1, "days").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    }

    render() {
        const handleChange = (value) => {

            let array = this.state.menu;
            var name = '';
            for (let i = 0; i < array.length; i++) {
                if(array[i].value == value){
                    name = array[i].label;
                }
            }
            this.setState({
                name: name
            });
        };
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Space>
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    options={[
                                        {
                                            value: '富平公司',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                                <DatePicker defaultValue={moment()} onChange={onChange} picker="month" />
                                    <Select
                                        defaultValue="请选择"
                                        style={{
                                            width: 220,
                                        }}
                                        onChange={handleChange}
                                        options={this.state.menu}
                                    />
                            </Space>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}
                                       pagination={{
                                           position: ['none', 'none'],
                                       }}/>

                                <Descriptions bordered>
                                    <Descriptions.Item label="当月合计金额（元）" span={2}>90132.30</Descriptions.Item>
                                    <Descriptions.Item label="客户对账情况" span={1}>未对账</Descriptions.Item>
                                </Descriptions>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageFinChecks;


