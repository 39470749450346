import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Table, Tag, Modal, Form, Switch
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '提货编号',
        dataIndex: 'pick_pickid',
        key: 'pick_pickid',
    },
    {
        title: '提货单位',
        dataIndex: 'pick_clientname',
        key: 'pick_clientname',
    },
    {
        title: '提货类型',
        dataIndex: 'pick_stock',
        key: 'pick_stock',
    },
    {
        title: '提货量（吨）',
        dataIndex: 'pick_num',
        key: 'pick_num',
    },
    {
        title: '提货车辆',
        dataIndex: 'pick_truck',
        key: 'pick_truck',
    },
    {
        title: '提货时间',
        dataIndex: 'pick_time',
        key: 'pick_time',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Print id={record.pick_pickid}></Print>
            </Space>
        ),
    },
];

const Load = (that,id) => {
    fetch("http://pd.pokemonbye.com:8086/api/getsellclientwxbyclient?client=" + id)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;

                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const Print = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellpickstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button onClick={()=>onGo(id)}>
                打印提货单
            </Button>
        </Popconfirm>
    );
};
const onGo = (id) => {
    window.open("/pagepurticket?id="+id.id)
};


class PagePurPickEds extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "提货单",
            county: [],
            area: pid,
            list: [],
            menu: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getsellpickbystate?state=2")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].key = i + 1
                    }
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const handleChange = (value) => {
            Load(this,value)
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="已提货单信息"
                            >
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PagePurPickEds;


