import React, {Component, useState} from "react";
import cookie from 'react-cookies'

class Header extends Component {

  constructor(props) {
    super(props);
    let userList = cookie.load('userList');

    this.state = {
      userList: userList,
    };
  }

  render() {

    return (

        <div className="headerbar">

          <a className="menutoggle">
            <input className="in" id="ac" type="checkbox"/><label htmlFor="ac"><i className="fa iconfont icon-icon-test"></i></label>
            <ul className="nav nav-pills nav-stacked nav-bracket">
              <li className="nav-parent">
                <input id="ac-1" type="checkbox"/><a><label htmlFor="ac-1"><span>数据中心</span><i
                  className="iconfont icon-under"></i></label></a>
                <ul className="children">
                  <li><a href="/pagedatastate">销售报表</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagedataserch">数据查询</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagedatacurve">销量曲线</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagedatacompare">销售对比</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagedataclient">客户对比</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagedatacapital">资金分析</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagedataorder">订单查询</a></li>
                </ul>
              </li>
              <li className="nav-parent">
                <input id="ac-2" type="checkbox"/><a><label htmlFor="ac-2"><span>销售管理</span><i
                  className="iconfont icon-under"></i></label></a>
                <ul className="children">
                  <li><a href="/pagesellbasic">基础数据管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pageselltask">任务管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagesellmans">人员管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagesellstocks">产品类型</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagesellsys">系统管理</a></li>
                </ul>
              </li>
              <li className="nav-parent">
                <input id="ac-3" type="checkbox"/><a><label htmlFor="ac-3"><span>采购中心</span><i
                  className="iconfont icon-under"></i></label></a>
                <ul className="children">
                  <li><a href="/pagepurdemand">需求提报</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagepurrecords">客户资料</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagepurorders">订单信息</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagepurprices">资金管理</a></li>
                </ul>
              </li>
              <li className="nav-parent">
                <input id="ac-4" type="checkbox"/><a><label htmlFor="ac-4"><span>业务中心</span><i
                  className="iconfont icon-under"></i></label></a>
                <ul className="children">
                  <li><a href="/pagebusrecords">客户档案管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagebuscontracts">合同管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagebusorders">流水管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagebusplan">发运计划</a></li>
                </ul>
              </li>
              <li className="nav-parent">
                <input id="ac-5" type="checkbox"/><a><label htmlFor="ac-5"><span>财务管理</span><i
                  className="iconfont icon-under"></i></label></a>
                <ul className="children">
                  <li><a href="/pagefincontracts">合同审核</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagefinorders">订单审核</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagefinloans">贷款回收</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagefinbills">发票管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagefincredits">信用管理</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagefinsets">财务扎帐</a></li>
                </ul>
                <ul className="children">
                  <li><a href="/pagefinchecks">客户对账单</a></li>
                </ul>
              </li>
            </ul>
          </a>
          {/*
        <form className="searchform" action="index.html" method="post">
          <input type="text" className="form-control" name="keyword" placeholder="搜索..."/>
        </form>*/}

          <div className="header-right">
            <ul className="headermenu">
              <li>{/*
              <div className="btn-group">
                <button className="btn btn-default dropdown-toggle tp-icon" data-toggle="dropdown">
                  <label htmlFor="mu-2">
                  <i className="iconfont icon-chat"></i>
                  <span className="badge">2</span>
                  </label>
                </button>
                <input id="mu-2" type="checkbox"/>
                <div className="dropdown-menu dropdown-menu-head pull-right">
                  <h5 className="title">2 Newly Registered Users</h5>
                  <ul className="dropdown-list user-list">
                    <li className="new">
                      <div className="thumb"><a href=""><img src="images/photos/user1.png" alt=""/></a></div>
                      <div className="desc">
                        <h5><a href="">Draniem Daamul (@draniem)</a> <span className="badge badge-success">new</span>
                        </h5>
                      </div>
                    </li>
                    <li className="new">
                      <div className="thumb"><a href=""><img src="images/photos/user2.png" alt=""/></a></div>
                      <div className="desc">
                        <h5><a href="">Zaham Sindilmaca (@zaham)</a> <span className="badge badge-success">new</span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="thumb"><a href=""><img src="images/photos/user3.png" alt=""/></a></div>
                      <div className="desc">
                        <h5><a href="">Weno Carasbong (@wenocar)</a></h5>
                      </div>
                    </li>
                    <li>
                      <div className="thumb"><a href=""><img src="images/photos/user4.png" alt=""/></a></div>
                      <div className="desc">
                        <h5><a href="">Nusja Nawancali (@nusja)</a></h5>
                      </div>
                    </li>
                    <li>
                      <div className="thumb"><a href=""><img src="images/photos/user5.png" alt=""/></a></div>
                      <div className="desc">
                        <h5><a href="">Lane Kitmari (@lane_kitmare)</a></h5>
                      </div>
                    </li>
                    <li className="new"><a href="">See All Users</a></li>
                  </ul>
                </div>
              </div>*/}
              </li>
              <li>
                <div className="btn-group">
                  <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                    <label htmlFor="mu-1">
                      你好，{this.state.userList.level}{this.state.userList.name}
                      <span className="caret"></span>
                    </label>
                  </button>
                  <input id="mu-1" type="checkbox"/>
                  <ul className="dropdown-menu dropdown-menu-usermenu pull-right">{/*
                  <li><a href="profile.html"><i className="glyphicon glyphicon-user"></i> My Profile</a></li>
                  <li><a href="#"><i className="glyphicon glyphicon-cog"></i> Account Settings</a></li>
                  <li><a href="#"><i className="glyphicon glyphicon-question-sign"></i> Help</a></li>*/}
                    <li><a href="/login"><i className="fa iconfont icon-getout"></i> 登出</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

        </div>
    );
  }
}

export default Header;
