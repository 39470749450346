import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'
import moment from "moment";

class Dataproductionline extends Component {
    constructor(props) {
        super(props);
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
                xAxis: {
                    type: 'category',
                    data: [moment().subtract(7,'days').format('M-D'), moment().subtract(6,'days').format('M-D'), moment().subtract(5,'days').format('M-D'), moment().subtract(4,'days').format('M-D'), moment().subtract(3,'days').format('M-D'), moment().subtract(2,'days').format('M-D'), moment().subtract(1,'days').format('M-D')]
                },
            yAxis: {
                type: 'value',
                axisLabel:{
                    formatter:"{value}吨"
                }
            },
                legend: {
                    data: ['水泥产量','熟料产量', '矿粉产量']
                },
                series: [
                    {
                        name: '水泥产量',
                        data: [7015, 5051, 7657, 6840, 7550, 9051, 7150],
                        type: 'line'
                    },
                    {
                        name: '熟料产量',
                        data: [12109, 12967, 12941, 12147, 13105, 12937, 12268],
                        type: 'line'
                    },
                    {
                        name: '矿粉产量',
                        data: [0, 468, 138, 378, 228, 600, 464],
                        type: 'line'
                    },
                ]
            };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getpdconllect?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let array = this.state.array;
        let data = [];
        let data2 = [];
        let data3 = [];
        for (var i = 0; i < array.length; i++) {
            data[i] = array[i].shuinichanliang * 1;
            data2[i] = array[i].shuliaochanliang * 1;
            data3[i] = array[i].kuangfenchanliang * 1;
        }
        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('dataproductionline'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);


        myChart.setOption({
            series: [
                {
                    name: '水泥产量',
                    data: data.reverse(),
                    type: 'line'
                },
                {
                    name: '熟料产量',
                    data: data2.reverse(),
                    type: 'line'
                },
                {
                    name: '矿粉产量',
                    data: data3.reverse(),
                    type: 'line'
                },
            ]
        });
    }

    render() {
        return (
            <div id="dataproductionline" className="histogramm"></div>
        )
    }
}

export default Dataproductionline;
