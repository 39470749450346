import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Dataline extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            color: ['#103986','#4e7bd3','#4c29af'],
            backgroundColor:'rgba(128, 128, 128, 0)',
                xAxis: {
                    type: 'category',
                    data: ['1日', '2日', '3日', '4日', '5日', '6日', '7日', '8日', '9日', '10日', '11日', '12日']
                },
                yAxis: {
                    type: 'value'
                },
                legend: {
                    data: ['氮氧化物均值', '颗粒物均值','二氧化硫均值']
                },
                series: [
                    {
                        name: '氮氧化物均值',
                        data: [150, 230, 224, 218, 135, 147, 260],
                        type: 'line'
                    },
                    {
                        name: '颗粒物均值',
                        data: [10, 130, 424, 118, 235, 347, 220],
                        type: 'line'
                    },
                    {
                        name: '二氧化硫均值',
                        data: [250, 430, 324, 218, 135, 147, 260],
                        type: 'line'
                    },
                ]
            };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getelevatormap")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let array = this.state.array;
        let keyMap = {
            "num": "value"
        };
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            for (var key in obj) {
                var newKey = keyMap[key];
                if (newKey) {
                    obj[newKey] = obj[key];
                    delete obj[key];
                }
            }
        }
        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('dataline'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="dataline" className="histogramline"></div>
        )
    }
}

export default Dataline;
