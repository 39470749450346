import Leftmenu from '../element/leftmenu';
import Databorder from '../element/header';
import React, {Component} from "react";
import { DatePicker,Popconfirm,Button,Menu, Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Line } from '@antv/g2plot';
import Histogramecharts from "../element/histogramecharts";
import Pieecharts from "../element/pieecharts";
import Linecharts from "../element/lineecharts";
import Line1charts from "../element/line1echarts";
import Line2charts from "../element/line2echarts";
import Line3charts from "../element/line3echarts";
import Pagelist from "../element/pagelist";
import Dataheader from "../element/dataheader";
import Datamenu from "../element/datamenu";
import Factorymapcharts from "../element/factorymapcharts";
import Datapie from "../element/datapie";
import Datatitle from "../element/datatitle";
import moment from "moment";
import Datafunnel from "../element/datafunnel";
import Datalinebottom from "../element/datalinebottom";
import Datacategory from "../element/datacategory";
import Datatitlemonth from "../element/datatitlemonty";
import Dataring from "../element/dataring";
import Dataline from "../element/dataline";
import Databg from "../element/databg";
import Dataradar from "../element/dataradar";
import Datamaterialspie from "../element/datamaterialspie";
import Datafiringline from "../element/datafiringline";
import Datamadeline from "../element/datamadeline";
import Datamadebg from "../element/datamadebg";

class DataMade extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            sum: 8837.7,
            sum2: 483.5,
            array:[{"id":24442,"chanliang":"43.19","chanliang325":"0.00","chanliang425":"0.00","chanliang525":"0.00","taishichanliang":"172.76","yunzhuanlv":"100.00","gongxudanweidianhao":"34.48","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24441,"chanliang":"24.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"96.80","yunzhuanlv":"25.00","gongxudanweidianhao":"42.58","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24440,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24439,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24438,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24437,"chanliang":"15.39","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"89.83","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24436,"chanliang":"202.70","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"202.70","yunzhuanlv":"100.00","gongxudanweidianhao":"32.11","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            array2:[{"id":24442,"chanliang":"43.19","chanliang325":"0.00","chanliang425":"0.00","chanliang525":"0.00","taishichanliang":"172.76","yunzhuanlv":"100.00","gongxudanweidianhao":"34.48","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24441,"chanliang":"24.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"96.80","yunzhuanlv":"25.00","gongxudanweidianhao":"42.58","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24440,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24439,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24438,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24437,"chanliang":"15.39","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"89.83","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24436,"chanliang":"202.70","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"202.70","yunzhuanlv":"100.00","gongxudanweidianhao":"32.11","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            array5:[{"id":24442,"chanliang":"43.19","chanliang325":"0.00","chanliang425":"0.00","chanliang525":"0.00","taishichanliang":"172.76","yunzhuanlv":"100.00","gongxudanweidianhao":"34.48","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24441,"chanliang":"24.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"96.80","yunzhuanlv":"25.00","gongxudanweidianhao":"42.58","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24440,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24439,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24438,"chanliang":"0.00","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24437,"chanliang":"15.39","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"89.83","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null},{"id":24436,"chanliang":"202.70","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"202.70","yunzhuanlv":"100.00","gongxudanweidianhao":"32.11","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            count: [{"id":24277,"chanliang":null,"chanliang325":"0.00","chanliang425":"142.29","chanliang525":"0.00","taishichanliang":null,"yunzhuanlv":null,"gongxudanweidianhao":null,"shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            count2: [{"id":24277,"chanliang":null,"chanliang325":"0.00","chanliang425":"142.29","chanliang525":"0.00","taishichanliang":null,"yunzhuanlv":null,"gongxudanweidianhao":null,"shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            count5: [{"id":24277,"chanliang":null,"chanliang325":"0.00","chanliang425":"142.29","chanliang525":"0.00","taishichanliang":null,"yunzhuanlv":null,"gongxudanweidianhao":null,"shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            slag: [{"id":24521,"chanliang":"108.34","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"108.34","yunzhuanlv":"100.00","gongxudanweidianhao":"26.51","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            slag2: [{"id":24521,"chanliang":"108.34","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"108.34","yunzhuanlv":"100.00","gongxudanweidianhao":"26.51","shuliaoxiaohao":null,"shuliaocanjiabi":null,"shuinichanliang":null,"kuangfenchanliang":null}],
            day: [{"id":24417,"chanliang":"3059.28","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"200.61","yunzhuanlv":"63.54","gongxudanweidianhao":"32.18","shuliaoxiaohao":"2512.00","shuliaocanjiabi":"82.11","shuinichanliang":null,"kuangfenchanliang":null},{"id":24387,"chanliang":"1660.81","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"201.31","yunzhuanlv":"34.38","gongxudanweidianhao":"32.80","shuliaoxiaohao":"1401.00","shuliaocanjiabi":"84.36","shuinichanliang":null,"kuangfenchanliang":null},{"id":24361,"chanliang":"3135.91","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"209.06","yunzhuanlv":"62.50","gongxudanweidianhao":"32.22","shuliaoxiaohao":"2577.00","shuliaocanjiabi":"82.18","shuinichanliang":null,"kuangfenchanliang":null},{"id":24333,"chanliang":"1870.90","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"213.82","yunzhuanlv":"36.46","gongxudanweidianhao":"30.55","shuliaoxiaohao":"1500.00","shuliaocanjiabi":"80.18","shuinichanliang":null,"kuangfenchanliang":null},{"id":24304,"chanliang":"1847.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"205.24","yunzhuanlv":"37.50","gongxudanweidianhao":"31.92","shuliaoxiaohao":"1515.00","shuliaocanjiabi":"82.02","shuinichanliang":null,"kuangfenchanliang":null},{"id":24275,"chanliang":"1761.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"201.28","yunzhuanlv":"36.46","gongxudanweidianhao":"33.24","shuliaoxiaohao":"1436.00","shuliaocanjiabi":"81.54","shuinichanliang":null,"kuangfenchanliang":null},{"id":24249,"chanliang":"1827.80","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"203.09","yunzhuanlv":"37.50","gongxudanweidianhao":"32.59","shuliaoxiaohao":"1516.00","shuliaocanjiabi":"82.94","shuinichanliang":null,"kuangfenchanliang":null}],
            day2: [{"id":24417,"chanliang":"3059.28","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"200.61","yunzhuanlv":"63.54","gongxudanweidianhao":"32.18","shuliaoxiaohao":"2512.00","shuliaocanjiabi":"82.11","shuinichanliang":null,"kuangfenchanliang":null},{"id":24387,"chanliang":"1660.81","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"201.31","yunzhuanlv":"34.38","gongxudanweidianhao":"32.80","shuliaoxiaohao":"1401.00","shuliaocanjiabi":"84.36","shuinichanliang":null,"kuangfenchanliang":null},{"id":24361,"chanliang":"3135.91","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"209.06","yunzhuanlv":"62.50","gongxudanweidianhao":"32.22","shuliaoxiaohao":"2577.00","shuliaocanjiabi":"82.18","shuinichanliang":null,"kuangfenchanliang":null},{"id":24333,"chanliang":"1870.90","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"213.82","yunzhuanlv":"36.46","gongxudanweidianhao":"30.55","shuliaoxiaohao":"1500.00","shuliaocanjiabi":"80.18","shuinichanliang":null,"kuangfenchanliang":null},{"id":24304,"chanliang":"1847.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"205.24","yunzhuanlv":"37.50","gongxudanweidianhao":"31.92","shuliaoxiaohao":"1515.00","shuliaocanjiabi":"82.02","shuinichanliang":null,"kuangfenchanliang":null},{"id":24275,"chanliang":"1761.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"201.28","yunzhuanlv":"36.46","gongxudanweidianhao":"33.24","shuliaoxiaohao":"1436.00","shuliaocanjiabi":"81.54","shuinichanliang":null,"kuangfenchanliang":null},{"id":24249,"chanliang":"1827.80","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"203.09","yunzhuanlv":"37.50","gongxudanweidianhao":"32.59","shuliaoxiaohao":"1516.00","shuliaocanjiabi":"82.94","shuinichanliang":null,"kuangfenchanliang":null}],
            day5: [{"id":24417,"chanliang":"3059.28","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"200.61","yunzhuanlv":"63.54","gongxudanweidianhao":"32.18","shuliaoxiaohao":"2512.00","shuliaocanjiabi":"82.11","shuinichanliang":null,"kuangfenchanliang":null},{"id":24387,"chanliang":"1660.81","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"201.31","yunzhuanlv":"34.38","gongxudanweidianhao":"32.80","shuliaoxiaohao":"1401.00","shuliaocanjiabi":"84.36","shuinichanliang":null,"kuangfenchanliang":null},{"id":24361,"chanliang":"3135.91","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"209.06","yunzhuanlv":"62.50","gongxudanweidianhao":"32.22","shuliaoxiaohao":"2577.00","shuliaocanjiabi":"82.18","shuinichanliang":null,"kuangfenchanliang":null},{"id":24333,"chanliang":"1870.90","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"213.82","yunzhuanlv":"36.46","gongxudanweidianhao":"30.55","shuliaoxiaohao":"1500.00","shuliaocanjiabi":"80.18","shuinichanliang":null,"kuangfenchanliang":null},{"id":24304,"chanliang":"1847.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"205.24","yunzhuanlv":"37.50","gongxudanweidianhao":"31.92","shuliaoxiaohao":"1515.00","shuliaocanjiabi":"82.02","shuinichanliang":null,"kuangfenchanliang":null},{"id":24275,"chanliang":"1761.20","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"201.28","yunzhuanlv":"36.46","gongxudanweidianhao":"33.24","shuliaoxiaohao":"1436.00","shuliaocanjiabi":"81.54","shuinichanliang":null,"kuangfenchanliang":null},{"id":24249,"chanliang":"1827.80","chanliang325":null,"chanliang425":null,"chanliang525":null,"taishichanliang":"203.09","yunzhuanlv":"37.50","gongxudanweidianhao":"32.59","shuliaoxiaohao":"1516.00","shuliaocanjiabi":"82.94","shuinichanliang":null,"kuangfenchanliang":null}],
            area: pid,
            list: [
                {
                    floor:"矿粉",
                    unit:"4742.3",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"熟料",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"石灰石粉",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"2714.2",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"复合超细粉",
                    unit:"2714.2",
                    elevator:"010301043",
                    medium:"4742.3",
                    tag:"ZL60",
                    taboo:"35",
                },
            ]
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        day: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement2?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        day2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement5?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        day5: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let slag2 = this.state.array2[0].chanliang;
                    let slag5 = this.state.array5[0].chanliang;
                    this.setState({
                        isLoaded: true,
                        array: array,
                        sum: (parseFloat(array[0].chanliang)+parseFloat(slag2)+parseFloat(slag5)).toFixed(2)
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement2?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let slag = this.state.array[0].chanliang;
                    let slag5 = this.state.array5[0].chanliang;
                    this.setState({
                        isLoaded: true,
                        array2: array,
                        sum: (parseFloat(array[0].chanliang)+parseFloat(slag)+parseFloat(slag5)).toFixed(2)
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement5?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let slag = this.state.array[0].chanliang;
                    let slag2 = this.state.array2[0].chanliang;
                    this.setState({
                        isLoaded: true,
                        array5: array,
                        sum: (parseFloat(array[0].chanliang)+parseFloat(slag)+parseFloat(slag2)).toFixed(2)
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcementvar?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        count: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcementvar2?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        count2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcementvar5?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        count5: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcementSlag?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let slag = this.state.slag2[0].chanliang;
                    this.setState({
                        isLoaded: true,
                        slag: array,
                        sum2: (parseFloat(array[0].chanliang)+parseFloat(slag)).toFixed(2)
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcementSlag2?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let slag = this.state.slag[0].chanliang;
                    this.setState({
                        isLoaded: true,
                        slag2: array,
                        sum2: (parseFloat(array[0].chanliang)+parseFloat(slag)).toFixed(2)
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <div>
                <div className="mainmax">
                    <Dataheader/>
                    <div className="colorbg">
                        <Datamadebg/>
                        <div className="datamadeheigh3 top10">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <p>累计生产曲线</p>
                            </div>
                            <Datamadeline />
                        </div>
                        <div className="datafheigh top10 left35">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-turangyanfen"></i>
                                <p>1线矿渣磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texture"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.slag[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.slag[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.slag[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.slag[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="texturebottom"></div>
                        </div>
                        <div className="datafheigh top10 left70">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-turangyanfen"></i>
                                <p>2线矿渣磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texture"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.slag2[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.slag2[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.slag2[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.slag2[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="texturebottom"></div>
                        </div>
                        <div className="datamadeheigh top50">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-gongcheng-3"></i>
                                <p>1线水泥磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texturebottom"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.array[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>325产量<br/>{this.state.count[0].chanliang325}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>425产量<br/>{this.state.count[0].chanliang425}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.array[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.array[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.array[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>熟料消耗<br/>{this.state.array[0].shuliaoxiaohao}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>熟料掺加比<br/>{this.state.array[0].shuliaocanjiabi}(%)</p>
                            </div>
                            <div className="texture"></div>
                        </div>
                        <div className="datamadeheigh top30 left30">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-gongcheng-3"></i>
                                <p>2线水泥磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texturebottom"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.array2[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>325产量<br/>{this.state.count2[0].chanliang325}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>425产量<br/>{this.state.count2[0].chanliang425}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.array2[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.array2[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.array2[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>熟料消耗<br/>{this.state.array2[0].shuliaoxiaohao}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>熟料掺加比<br/>{this.state.array2[0].shuliaocanjiabi}(%)</p>
                            </div>
                            <div className="texture"></div>
                        </div>
                        <div className="datamadeheigh top30 left70">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-gongcheng-3"></i>
                                <p>5线水泥磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texturebottom"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.array5[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>425产量<br/>{this.state.count5[0].chanliang425}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>525产量<br/>{this.state.count5[0].chanliang525}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.array5[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.array5[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.array5[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>熟料消耗<br/>{this.state.array5[0].shuliaoxiaohao}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>熟料掺加比<br/>{this.state.array5[0].shuliaocanjiabi}(吨)</p>
                            </div>
                            <div className="texture"></div>
                        </div>
                        <div className="datamadeheigh2 top75 left40">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-tudui"></i>
                                <p>水泥产量<br/>{this.state.sum}(吨)</p>
                            </div>
                            <div className="mineral">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <i className="fa iconfont icon-turangyanfen"></i>
                                <p>矿粉产量<br/>{this.state.sum2}(吨)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DataMade;
