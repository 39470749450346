import React, {Component} from 'react';
import * as echarts from 'echarts';
import moment from "moment";
import geo from '../json/xian.json'

const Load = (that,day,man,name) => {
    fetch("http://pd.pokemonbye.com:8087/api/getsellmonthbyman?man="+man+"&code="+day)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                that.setState({
                    isLoaded: true,
                    array: array,
                    name: name
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}
const Load2 = (that,day,man,name) => {
    fetch("http://pd.pokemonbye.com:8087/api/getsellmonthbyman?man="+man+"&code="+day)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                that.setState({
                    isLoaded: true,
                    array2: array,
                    name2: name
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}
class Datasellonman extends Component {
    func(name,value){
        let yar = moment().format('YYYY');
        Load(this,yar,value,name)
    }
    func2(name,value){
        let yar = moment().format('YYYY');
        Load2(this,yar,value,name)
    }
    constructor(props) {
        super(props);
        this.state = {
            array: [],
            array2: [],
            name: '',
            name2: ''
        };
        // 绘制图表
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                valueFormatter: function (value) {
                    return value + '（万吨）';
                }
            },
            xAxis: {
                type: 'category',
                data: ['1月', '2月', '3月', '4月','5月', '6月', '7月', '8月','9月', '10月', '11月', '12月']
            },
            yAxis: {
                type: 'value',
                axisLabel:{
                    formatter:"{value}万吨"
                }
            },
            series: [
            ]
        };
        this.state = {
            option: option
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        let array2 = this.state.array2;
        let name = '';
        let name2 = '';
        const data = [];
        const data2 = [];

        if(array){

            for (var i = 0; i < array.length; i++) {

                data.push(
                    (array[i].sum/10000).toFixed(2)
                );
            }
            name = this.state.name;
        }
        if(array2) {
            for (var i = 0; i < array2.length; i++) {

                data2.push(
                    (array2[i].sum / 10000).toFixed(2)
                );
            }
            name2 = this.state.name2;
        }
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datasellonyear'),'light');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            legend: {
                data: [name,name2]
            },
            series: [
                {
                    name: name,
                    data: data,
                    type: 'bar'
                },
                {
                    name: name2,
                    data: data2,
                    type: 'bar'
                },
            ]
        });
    }

    render() {
        return (
            <div id="datasellonyear" className="histogramm"></div>
        )
    }
}

export default Datasellonman;
