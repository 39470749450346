import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Homeecharts extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            title: {
                text: '资源',
                subtext: '地图'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br/>空闲{c}个'
            },
            toolbox: {
                show: true,
                orient: 'vertical',
                left: 'left',
                top: 'center',
                showTitle: false,
                feature: {
                    restore: {},
                    dataView: {readOnly: false},
                    saveAsImage: {}
                }
            },
            visualMap: {
                min: 0,
                max: 1000,
                text: ['充足', '紧缺'],
                realtime: false,
                calculable: true,
                inRange: {
                    color: ['orangered', 'yellow', 'lightskyblue']
                }
            },
            series: [
                {
                    name: '资源',
                    type: 'map',
                    map: 'XIAN',
                    label: {
                        show: false
                    },
                    data: [
                    ]
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getelevatormap")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let array = this.state.array;
        let keyMap = {
            "num": "value"
        };
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            for (var key in obj) {
                var newKey = keyMap[key];
                if (newKey) {
                    obj[newKey] = obj[key];
                    delete obj[key];
                }
            }
        }
        let option = this.state.option;
        option.series[0].data = array;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('forms'));
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        echarts.registerMap('XIAN', geo);
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="forms"></div>
        )
    }
}

export default Homeecharts;
