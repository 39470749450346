import React, {Component} from 'react';
import * as echarts from 'echarts';
import moment from "moment";
import geo from '../json/xian.json'

class Datasellfunnel extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            grid: {
                top: 100
            },
            angleAxis: {
                type: 'category',
                data: []
            },
            tooltip: {
                show: true
            },
            radiusAxis: {},
            polar: {},
            series: [
                {
                    type: 'bar',
                    itemStyle: {
                        color: 'transparent'
                    },
                    data: [],
                    coordinateSystem: 'polar',
                    stack: 'Min Max',
                    silent: true
                },
                {
                    type: 'bar',
                    data: [],
                    coordinateSystem: 'polar',
                    stack: 'Min Max'
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        const start = moment().format('YYYY') + "-01-01";
        const end = moment().add(1, 'years').format('YYYY') + "-01-01";
        fetch("http://pd.pokemonbye.com:8087/api/getsellcus?num=11&start=" + start + "&end=" + end)
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        const data = [];
        const cus = [];

        for (var i = 0; i < array.length; i++) {

            data.push((array[i].sum));
            cus.push((array[i].stockname).split("有限")[0])
        }

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datasellfunnel'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            angleAxis: {
                type: 'category',
                data: cus
            },
            series: [
                {
                    type: 'bar',
                    itemStyle: {
                        color: 'transparent'
                    },
                    data: data,
                    coordinateSystem: 'polar',
                    stack: 'Min Max',
                    silent: true
                },
                {
                    type: 'bar',
                    data: data,
                    coordinateSystem: 'polar',
                    stack: 'Min Max'
                }
            ]
        });
    }

    render() {
        return (
            <div id="datasellfunnel" className="histogramlar"></div>
        )
    }
}

export default Datasellfunnel;
