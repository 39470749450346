import React, {Component} from "react";
import {Breadcrumb,Layout} from "antd";

class PageBreadcrumb extends Component{
    constructor(props){
        super(props);
    }
    render() {
        return (
            <Layout className="breadcrumb-layout">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href="/home">首页</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{this.props.title}</Breadcrumb.Item>
            </Breadcrumb>
            </Layout>
        )
    }
}

export default PageBreadcrumb;
