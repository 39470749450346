import React, {Component} from 'react';
import * as echarts from 'echarts';
import svg from '../img/logo.svg'

class Datafiringbg extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {},
            geo: {
                map: 'ksia-ext-plan',
                layoutCenter: ['50%', '50%'],
                layoutSize: '100%'
            },
            series: [
                {
                    name: 'Route',
                    type: 'lines',
                    coordinateSystem: 'geo',
                    geoIndex: 0,
                    polyline: true,
                    lineStyle: {
                        color: '#FF8D50',
                        width: 1
                    },
                    effect: {
                        show: true,
                        period: 8,
                        color: '#3BD7F7',
                        trailLength: 0,
                        symbolSize: [10, 40],
                        symbol:'roundRect'

                    },
                    z: 100,
                    data: [
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [-250, 150],
                                [-250, 350]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [150, 150],
                                [150, 350]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [550, 150],
                                [550, 350]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [1350, 150],
                                [1350, 350]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [1750, 150],
                                [1750, 350]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [2150, 150],
                                [2150, 350]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [2150, 450],
                                [2150, 650]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [1750, 450],
                                [1750, 650]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [-250, 450],
                                [-250, 650]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [150, 450],
                                [150, 650]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [550, 450],
                                [550, 700]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [550, 700],
                                [400, 700]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [400, 700],
                                [250, 700]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [1350, 450],
                                [1350, 700]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [1350, 700],
                                [1500, 700]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [1500, 700],
                                [1650, 700]
                            ]
                        }
                    ]
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch(svg)
            .then(res => res.text())
            .then(
                (result) => {
                    let option = this.state.option;

                    // 基于准备好的dom，初始化echarts实例
                    var myChart = echarts.init(document.getElementById('datafiringbg'),'dark');
                    echarts.registerMap('ksia-ext-plan', { svg: result });
                    //点击事件
                    myChart.on('click', function (params) {
                        //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
                    });
                    myChart.setOption((option));
                    option && myChart.setOption(option);
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <div id="datafiringbg" className="mapgb"></div>
        )
    }
}

export default Datafiringbg;
