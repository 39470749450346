import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs, List, Card, Avatar
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const columns = [
    {
        title: '类型',
        dataIndex: 'stockname',
        key: 'stockname',
    },
    {
        title: '销量（吨）',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: '金额（元）',
        dataIndex: 'price',
        key: 'price',
        render: (price) => <p>{price ? (price/1).toFixed(2) : '0.00'}</p>,
    },
    {
        title: '区域',
        dataIndex: 'project',
        key: 'project',
    },
    {
        title: '车辆',
        dataIndex: 'truck',
        key: 'truck',
    },
    {
        title: '销售人',
        dataIndex: 'man',
        key: 'man',
    },
    {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
        render: (date) => <p>{date ? date.substring(0,19) : '0.00'}</p>,
    }
];

const Load = (that,start,end) => {

    fetch("https://pd.pokemonbye.com:8086/api/getsellbill?id=KH0295")
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                console.log(array)
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class PageFinBills extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "发票管理",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8087/api/getsellclientdao")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].value = array[i].stockid;
                        array[i].label = array[i].stockname;

                    }
                    this.setState({
                        isLoaded: true,
                        menu: array
                    });
                    Load(this,moment().subtract(1, 'day').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'));
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        Load(this,moment().subtract(1, "days").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    }

    render() {
        const handleChange = (value) => {

            let array = this.state.menu;
            var name = '';
            for (let i = 0; i < array.length; i++) {
                if(array[i].value == value){
                    name = array[i].label;
                }
            }
            this.setState({
                name: name
            });
        };
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Space>
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    options={[
                                        {
                                            value: '富平公司',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                                <RangePicker defaultValue={[moment().subtract(1, "days"), moment()]} onChange={onChange}/>
                                    <Select
                                        defaultValue="请选择"
                                        style={{
                                            width: 220,
                                        }}
                                        onChange={handleChange}
                                        options={this.state.menu}
                                    />
                            </Space>
                                <br/>
                                <br/>
                                <Button disabled>新增</Button>
                                <br/>
                                <br/>
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 4,
                                        xl: 4,
                                        xxl: 4,
                                    }}
                                    dataSource={this.state.list}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Card
                                                title={item.num}
                                                extra={<a href="#"> 删除</a>}
                                            >
                                                <p>开票公司：{item.client}</p>
                                                <p>发票金额：{item.price}</p>
                                                <p>发票税率：{item.tax}</p>
                                                <p>发票状态：已开票</p>
                                                <p>开票时间：{item.pubtime}</p>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageFinBills;


