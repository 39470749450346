import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Linecharts extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            color: ['#ef7567'],
            backgroundColor:'rgba(128, 128, 128, 0)',
                title: {
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    show:false,
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['Mon', 'Tue', 'Wed', 'Thu']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '历史',
                        type: 'line',
                        stack: 'Total',
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: '今年',
                        type: 'line',
                        stack: 'Total',
                        data: [220, 182, 191, 234, 290, 330, 310]
                    }
                ]
            };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getelevatormap")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let array = this.state.array;
        let keyMap = {
            "num": "value"
        };
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            for (var key in obj) {
                var newKey = keyMap[key];
                if (newKey) {
                    obj[newKey] = obj[key];
                    delete obj[key];
                }
            }
        }
        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('lineforms'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="lineforms" className="histogram"></div>
        )
    }
}

export default Linecharts;
