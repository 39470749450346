import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Datapie extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            title: {
                left: 'center',
                top: 20,
                textStyle: {
                    color: '#2552aa'
                }
            },
            tooltip: {
                trigger: 'item'
            },
            visualMap: {
                show: false,
                min: 80,
                max: 600,
                inRange: {
                    colorLightness: [0, 1]
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: '100%',
                    center: ['50%', '50%'],
                    data: [
                        { value: 335, name: '隐患数量' },
                        { value: 310, name: '三违数量' },
                        { value: 310, name: '整改总数' }
                    ].sort(function (a, b) {
                        return a.value - b.value;
                    }),
                    roseType: 'radius',
                    itemStyle: {
                        color: '#2552aa',
                        shadowBlur: 200,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200;
                    }
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getelevatormap")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datapie'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="datapie" className="histogram"></div>
        )
    }
}

export default Datapie;
