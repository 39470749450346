import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Form, Switch,
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;
const data = [
    {
        title: '档案ID',
    },
    {
        title: 'Title 2',
    },
    {
        title: 'Title 3',
    },
    {
        title: 'Title 4',
    },
    {
        title: 'Title 5',
    },
    {
        title: 'Title 6',
    },
];
const content = (<Select
        defaultValue="lucy"
        style={{
            width: 200,
        }}
        options={[
            {
                label: 'Manager',
                options: [
                    {
                        label: 'Jack',
                        value: 'jack',
                    },
                    {
                        label: 'Lucy',
                        value: 'lucy',
                    },
                ],
            },
            {
                label: 'Engineer',
                options: [
                    {
                        label: 'yiminghe',
                        value: 'Yiminghe',
                    },
                ],
            },
        ]}
    />
);

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};

class PageBusOrder extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "客户档案管理",
            county: [],
            area: pid,
            list: []
        };
        console.log(this.props)
    }

    componentDidMount() {
        fetch("https://pd.pokemonbye.com:8086/api/getsellclientnum?id=1")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data[0];
                    console.log(array.name)
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >

                                <Title level={5}>客户档案</Title>
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 10,
                                    }}
                                    layout="horizontal"
                                    size="Large"
                                >
                                    <Form.Item label="客户名称">
                                        <Input value={this.state.list.name} />
                                    </Form.Item>
                                    <Form.Item label="客户编号">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="地址">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="联系方式">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="组织机构代码">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="开户行">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="开户行账号">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="审核状态" valuePropName="checked">
                                        <Switch checkedChildren="已审核" unCheckedChildren="待审核"/>
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 4,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">提交修改</Button>
                                    </Form.Item>
                                </Form>

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageBusOrder;


