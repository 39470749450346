import logo from '../img/logo.svg';
import Leftmenu from '../element/leftmenu';
import Header from '../element/header';
import Homeecharts from '../element/homeecharts';
import Pageheader from "../element/pageheader";
import React, {Component} from "react";

class Map extends Component{
    constructor(props) {
        super(props);
        this.state = {
            title: "",
        };
    }
    render() {
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <Pageheader value={this.state}/>
                    <Homeecharts />
                </div>
            </div>
        );
    }
}

export default Map;
