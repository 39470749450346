import { useSearchParams } from "react-router-dom";

function Pagefilter(props) {
  let [searchParam , setSearchParam] = useSearchParams();
  let id = searchParam.get("area");
  let county = props.value.county;

  let listArr = county.map((item,index,arr) => {
    return (
        <FilterItem value={item} id={id} key={id}/>
    )
  })

  let changeEvent = (e) => {
    if( e.target.selectedIndex == 0){
      window.open('/list','_self');
    }else{
      window.open('/list?area=' + parseInt(county[e.target.selectedIndex - 1].id),'_self');
    }
  }
  return (
      <div className="panel panel-default">
        <div className="panel-body panel-body-nopadding">
          <form className="form-horizontal form-bordered">
            <div className="form-group">
              <label className="col-sm-2 control-label">行政区</label>
              <div className="col-sm-2">
                <select className="form-control mb15" onChange={(e)=>changeEvent(e)}>
                  <option id="0" value="全部" selected="selected">全部</option>
                  {listArr}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
  );
}

function FilterItem(props){
  return(
        <option id={props.value.id} value={props.value.value} selected={props.value.id == props.id ? "selected" : ""}>{props.value.value}</option>
  )
}

export default Pagefilter;
