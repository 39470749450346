import Leftmenu from '../element/leftmenu';
import Databorder from '../element/header';
import React, {Component} from "react";
import { DatePicker,Popconfirm,Button,Menu, Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Line } from '@antv/g2plot';
import Histogramecharts from "../element/histogramecharts";
import Pieecharts from "../element/pieecharts";
import Linecharts from "../element/lineecharts";
import Line1charts from "../element/line1echarts";
import Line2charts from "../element/line2echarts";
import Line3charts from "../element/line3echarts";
import Pagelist from "../element/pagelist";

class Data extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            county: [],
            area: pid,
            list: [
                {
                    floor:"矿粉",
                    unit:"4742.3",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"熟料",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"石灰石粉",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"2714.2",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"复合超细粉",
                    unit:"2714.2",
                    elevator:"010301043",
                    medium:"4742.3",
                    tag:"ZL60",
                    taboo:"35",
                },
            ]
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getxian")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let keyMap = {
                        "name": "value"
                    };
                    for (var i = 0; i < array.length; i++) {
                        var obj = array[i];
                        for (var key in obj) {
                            var newKey = keyMap[key];
                            if (newKey) {
                                obj[newKey] = obj[key];
                                delete obj[key];
                            }
                        }
                    };
                    this.setState({
                        isLoaded: true,
                        county: array
                    });
                    let listUrl = this.state.area !=null ? "http://ad.pokemonbye.com/api/getelevator?area="+this.state.area : "http://ad.pokemonbye.com/api/getelevator";
                    console.log(listUrl)
                    fetch(listUrl,{
                    })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    isLoaded: true,
                                    list: this.state.list
                                });
                            },
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {

        const menu = (
            <Menu
                items={[
                    {
                        label: (
                            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                                1st menu item
                            </a>
                        ),
                    },
                    {
                        label: (
                            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                                2nd menu item (disabled)
                            </a>
                        ),
                        disabled: true,
                    },
                    {
                        label: (
                            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                                3rd menu item (disabled)
                            </a>
                        ),
                        disabled: true,
                    },
                    {
                        danger: true,
                        label: 'a danger item',
                    },
                ]}
            />
        );

        return (
            <div>
                <div className="mainmax">
                    <div className="bg"/>
                    <div className="topheader">
                        <div className="logo"/>
                        <h1>陕西建材科技集团数据中心</h1>
                        <h3>2022年12月3日</h3>
                        <select id="name" className="rightscreen right">
                            <option value="1">陕西生态水泥富平公司</option>
                            <option value="2">陕西生态水泥黄陵公司</option>
                        </select>
                        <div className="rightbutton right">
                            管理中心
                        </div>
                    </div>
                    <div className="width">
                        <div className="border">
                            <p>生产情况</p>
                            <Histogramecharts />
                        </div>
                        <div className="borderllh center">
                            <h3 className="left10">总销售额（万元）</h3>
                            <h4 className="left10">400.00</h4>
                            <h5 className="left40">去年同比 上升30%</h5>
                            <h5 className="left40 top">上月环比 上升50%</h5>
                        </div>
                        <div className="borderlh center top">
                            <p>各类型收益</p>
                            <Pieecharts />
                        </div>
                        <div className="borderll right">
                            <p>销售情况</p>
                            <Line1charts />
                        </div>{/*
                        <div className="borderll right">
                            <p>生产在线良率</p>
                                <Button className="transparency">
                                    <Space>
                                        日
                                    </Space>
                                </Button>
                                <Button>
                                    <Space>
                                        月
                                    </Space>
                                </Button>
                                <Button>
                                    <Space>
                                        年
                                    </Space>
                                </Button>
                            <Line3charts />
                        </div>*/}
                    </div>
                    <div className="width">
                        <div className="borderl">
                            <p>质检合格率</p>
                            <Linecharts />
                        </div>
                        <div className="borderl left20">
                            <p>安全隐患情况</p>
                            <Line2charts />
                        </div>
                        <div className="borderw right">
                            <p>各类型销售排行</p>
                            <Pagelist  value={this.state}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Data;
