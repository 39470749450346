import {Component} from "react";
import cookie from "react-cookies";
import '../css/login.css';
import { Form,Input,Button,Checkbox,notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';


class Login extends Component{
    constructor(props) {
        super(props);
    }
    render() {

        const openNotification = () => {
            notification.open({
                message: '请输入正确的账号和密码',
                description:
                    '账号或密码错误，请检查。',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        };
        const onFinish = (values) => {
            console.log('Success:', values);


            fetch("http://pd.pokemonbye.com:8086/api/getselladmin?admin=" + values.admin + "&password=" + values.password)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.data!=null&&result.data.length>0){

                            cookie.save('userId', result.data[0].id);
                            cookie.save('userList', result.data[0]);

                            window.location.href="/home"
                        }else{

                            openNotification();
                        }
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        openNotification();
                    }
                )
        };
        const onFinishFailed = (errorInfo) => {
            openNotification();
        };
        return (
            <div className="signinpanel">
                <div className="col-md-2">
                </div>
                <div className="col-md-8">
                    <div className="login-title-center">
                        <h2 className="mb20">陕西建材科技集团信息数据调度中心
                    </h2>
                    </div>
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            style={{
                                background: '#FFFFFF00'
                            }}
                        >
                            <h4 className="mt5 mb20">账号密码登录系统</h4>
                            <Form.Item
                                name="admin"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入账号!',
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="密码"/>
                            </Form.Item>
                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                                wrapperCol={{
                                    offset: 0,
                                    span: 16,
                                }}
                            >
                                <Checkbox
                                    style={{
                                        color: '#FFFFFF'
                                    }}
                                >记住密码</Checkbox>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    offset: 0
                                }}
                            >
                                <Button type="primary" htmlType="submit"
                                        style={{
                                            width: '100%'
                                        }}>
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>

                    </div>
            </div>
        );
}}

export default Login;
