import Leftmenu from '../element/leftmenu';
import Databorder from '../element/header';
import React, {Component} from "react";
import { DatePicker,Popconfirm,Button,Menu, Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Line } from '@antv/g2plot';
import Histogramecharts from "../element/histogramecharts";
import Pieecharts from "../element/pieecharts";
import Linecharts from "../element/lineecharts";
import Line1charts from "../element/line1echarts";
import Line2charts from "../element/line2echarts";
import Line3charts from "../element/line3echarts";
import Pagelist from "../element/pagelist";
import Dataheader from "../element/dataheader";
import Datamenu from "../element/datamenu";
import Factorymapcharts from "../element/factorymapcharts";
import Datapie from "../element/datapie";
import Datatitle from "../element/datatitle";
import moment from "moment";
import Datafunnel from "../element/datafunnel";
import Datalinebottom from "../element/datalinebottom";
import Datacategory from "../element/datacategory";
import Datatitlemonth from "../element/datatitlemonty";
import Dataring from "../element/dataring";
import Dataringmonth from "../element/dataringmonty";
import Dataradar from "../element/dataradar";
import Datadadar from "../element/datadadar";
import Datacategory2 from "../element/datacategory2";
import Datacategory3 from "../element/datacategory3";

class DataGeneral extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            county: [],
            area: pid,
            list: [
                {
                    floor:"矿粉",
                    unit:"4742.3",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"熟料",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"石灰石粉",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"2714.2",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"复合超细粉",
                    unit:"2714.2",
                    elevator:"010301043",
                    medium:"4742.3",
                    tag:"ZL60",
                    taboo:"35",
                },
            ]
        };
    }

    componentDidMount() {
    }
    render() {
        return (
            <div>
                <div className="mainmax">
                    <Dataheader/>
                    <div className="gborderlh">

                        <Factorymapcharts />
                        <div className="histogramfooter"></div>
                        <div className="heigh top0">
                            <div className="bordert">
                                <div className="title">
                                    <p>{moment().format('YYYY')}年销售任务完成情况</p>
                                </div>
                                <Datatitle />
                            </div>
                            <div className="bordert top30">
                                <div className="title">
                                    <p>{moment().format('YYYY')}年生产任务完成情况</p>
                                </div>
                                <Dataring />
                            </div>
                            <div className="borderl top75">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>余热发电曲线</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datalinebottom />
                            </div>
                        </div>
                        <div className="heigh left15 top0">
                            <div className="bordert">
                                <div className="title">
                                    <p>{moment().format('M')}月销售任务完成情况</p>
                                </div>
                                <Datatitlemonth />
                            </div>
                            <div className="bordert top30">
                                <div className="title">
                                    <p>{moment().format('M')}月生产任务完成情况</p>
                                </div>
                                <Dataringmonth />
                            </div>
                        </div>
                        <div className="heigh right top0">
                            <div className="borderwh">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>实时排放</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <div className="titletop">
                                    <i className="fa iconfont icon-1301"></i>
                                    <p className="p">颗粒物（mg/m³）</p>
                                </div>
                                <Datacategory />
                                <div className="titlebottom">
                                    <i className="fa iconfont icon-eryanghualiu"></i>
                                    <p className="p">二氧化硫（mg/m³）</p>
                                </div>
                                <Datacategory2 />
                                <div className="titlebottom">
                                    <i className="fa iconfont icon-qiti"></i>
                                    <p className="p">氮氧化物（mg/m³）</p>
                                </div>
                                <Datacategory3 />
                            </div>
                            <div className="borderw top75">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>本月隐患</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datafunnel />
                            </div>{/*
                            <div className="borderw top80">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>余热发电</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datadadar/>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DataGeneral;
