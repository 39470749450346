import React, {Component} from 'react';
import * as echarts from 'echarts';
import moment from "moment";
import geo from '../json/xian.json'

class Datasellstaff extends Component {
    constructor(props) {
        super(props);
        let category = [];
        // 绘制图表
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['计划完成', '实际完成'],
                textStyle: {
                    color: '#ccc'
                }
            },
            xAxis: {
                data: ['1月', '2月', '3月', '4月','5月', '6月', '7月', '8月','9月', '10月', '11月', '12月'],
                axisLine: {
                    lineStyle: {
                        color: '#ccc'
                    }
                }
            },
            yAxis: {
                splitLine: { show: false },
                axisLine: {
                    lineStyle: {
                        color: '#ccc'
                    }
                }
            },
            series: [
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8087/api/getselleverymonth?code="+moment().format('YYYY'))
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        const data = [];
        const lineData = [];
        for (var i = 0; i < array.length; i++) {

            let d = Math.random() * 100000;
            data.push(
                parseFloat(array[i].sum).toFixed(2)
            );
            lineData.push(
                (parseFloat(array[i].sum) + d).toFixed(2)
            );
        }

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datasellstaff'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            series: [
                {
                    name: '计划完成',
                    type: 'line',
                    smooth: true,
                    showAllSymbol: true,
                    symbol: 'emptyCircle',
                    symbolSize: 15,
                    data: lineData
                },
                {
                    name: '实际完成',
                    type: 'bar',
                    barWidth: 10,
                    itemStyle: {
                        borderRadius: 5,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#14c8d4' },
                            { offset: 1, color: '#43eec6' }
                        ])
                    },
                    data: data
                },
                {
                    name: 'line',
                    type: 'bar',
                    barGap: '-100%',
                    barWidth: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: 'rgba(20,200,212,0.5)' },
                            { offset: 0.2, color: 'rgba(20,200,212,0.2)' },
                            { offset: 1, color: 'rgba(20,200,212,0)' }
                        ])
                    },
                    z: -12,
                    data: lineData
                }
            ]
        });
    }

    render() {
        return (
            <div id="datasellstaff" className="histogramlar"></div>
        )
    }
}

export default Datasellstaff;
