import React, {Component} from 'react';
import * as echarts from 'echarts';
import svg from '../img/logo.svg'

class Datamadebg extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {},
            geo: {
                map: 'ksia-ext-plan',
                layoutCenter: ['50%', '50%'],
                layoutSize: '100%'
            },
            series: [
                {
                    name: 'Route',
                    type: 'lines',
                    coordinateSystem: 'geo',
                    geoIndex: 0,
                    polyline: true,
                    lineStyle: {
                        color: '#FF8D50',
                        width: 1
                    },
                    effect: {
                        show: true,
                        period: 8,
                        color: '#3BD7F7',
                        trailLength: 0,
                        symbolSize: [10, 40],
                        symbol:'roundRect'

                    },
                    z: 100,
                    data: [
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [240, 1200],
                                [440, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [440, 1200],
                                [640, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [640, 1200],
                                [840, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [840, 1200],
                                [1040, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [1040, 1200],
                                [1240, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [1240, 1200],
                                [1440, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [240, 1300],
                                [440, 1300]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [440, 1300],
                                [640, 1300]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [640, 1300],
                                [840, 1300]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [840, 1300],
                                [1040, 1300]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [1040, 1300],
                                [1240, 1300]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 100,
                                delay: 0
                            },
                            coords: [
                                [1240, 1300],
                                [1440, 1300]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 60,
                                delay: 0
                            },
                            coords: [
                                [1080, 1040],
                                [1080, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 60,
                                delay: 0
                            },
                            coords: [
                                [800, 1040],
                                [800, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 60,
                                delay: 0
                            },
                            coords: [
                                [2400, 1040],
                                [2400, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [2400, 1200],
                                [2250, 1200]
                            ]
                        },
                        {
                            effect: {
                                constantSpeed: 50,
                                delay: 0
                            },
                            coords: [
                                [2250, 1200],
                                [2100, 1200]
                            ]
                        }
                    ]
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch(svg)
            .then(res => res.text())
            .then(
                (result) => {
                    let option = this.state.option;

                    // 基于准备好的dom，初始化echarts实例
                    var myChart = echarts.init(document.getElementById('datamadebg'),'dark');
                    echarts.registerMap('ksia-ext-plan', { svg: result });
                    //点击事件
                    myChart.on('click', function (params) {
                        //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
                    });
                    myChart.setOption((option));
                    option && myChart.setOption(option);
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <div id="datamadebg" className="mapgb"></div>
        )
    }
}

export default Datamadebg;
