import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Table
} from 'antd';
import 'moment/locale/zh-cn';
const { Title,Text } = Typography;
const { Content } = Layout;

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const columns = [
    {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '类型',
        dataIndex: 'stockname',
        key: 'stockname',
    },
    {
        title: '编号',
        dataIndex: 'stocknum',
        key: 'stocknum',
    },
    {
        title: '规格',
        dataIndex: 'str',
        key: 'str',
    },
    {
        title: '库存量',
        dataIndex: 'stockprice',
        key: 'stockprice',
    },
    {
        title: '散袋装',
        dataIndex: 'type',
        key: 'type',
        render: (type) => <p>{type==2 ? '袋装' : '散装'}</p>,
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button type="primary" size="small" disabled>
                    管理
                </Button>
                <Button type="primary" size="small" disabled>
                    删除
                </Button>
            </Space>
        ),
    },
];

class PageSellBasic extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "基础数据管理",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
        fetch("https://pd.pokemonbye.com:8086/api/getsellstock")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="销售基础数据设置"
                            >
                                <Title level={5}>库存管理</Title>
                                <Button type="primary" disabled>
                                    新增
                                </Button>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellBasic;


