import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import { Layout, Col, Row, PageHeader, Button, Descriptions, Typography, InputNumber, Slider, Input, Space, DatePicker} from 'antd';
import 'moment/locale/zh-cn';
const { Title,Text } = Typography;
const { Content } = Layout;

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={10}>
                <Slider
                    min={0}
                    max={10000}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={3}>
                <InputNumber
                    min={0}
                    max={10000}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                    addonAfter="万吨"
                />
            </Col>
            <Col span={1}>
                <Button>提交</Button>
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};

class PageSellTask extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "任务管理",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="管理销售任务"
                            >
                                <Title level={5}>厂区销售计划</Title>
                                <br/>
                                <Space size={10}>
                                    <Text>年度销售计划</Text>
                                </Space>
                                <DecimalStep />
                                <br/>
                                <Space size={10}>
                                    <Text>月度销售计划</Text>
                                </Space>
                                <br/>
                                <br/>
                                <Space size={10}>
                                    <DatePicker onChange={onChange} picker="month" />
                                    <InputNumber
                                        style={{
                                            width: '100%',
                                        }}
                                        step={0.01}
                                        defaultValue="3600.00"
                                    />
                                    <Button>提交</Button>
                                </Space>
                                <br/>
                                <br/>
                                <Title level={5}>人员销售计划</Title>
                                <br/>
                                <Space size={10}>
                                    <Text>年度销售计划</Text>
                                </Space>
                                <DecimalStep />
                                <br/>
                                <Space size={10}>
                                    <Text>月度销售计划</Text>
                                </Space>
                                <br/>
                                <br/>
                                <Space size={10}>
                                    <DatePicker onChange={onChange} picker="month" />
                                    <InputNumber
                                    style={{
                                        width: '100%',
                                    }}
                                    step={0.01}
                                    defaultValue="3600.00"
                                    />
                                    <Button>提交</Button>
                                </Space>

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellTask;


