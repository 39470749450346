import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Card,
    List, Avatar, Form, Switch, notification, Select
} from 'antd';
import 'moment/locale/zh-cn';

const {Title, Text} = Typography;
const {Content} = Layout;
const { Meta } = Card;

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const openNotification = () => {
    notification.open({
        message: '提交成功',
        description:
            '档案修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};

class PageSellSysAdmin extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        this.state = {
            title: "账号管理",
            county: [],
            id: id,
            list: []
        };
    }

    componentDidMount() {
        let id = this.state.id
        fetch("http://pd.pokemonbye.com:8086/api/getselladminlevellist")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    var options = [];
                    if(array!=null&&array.length>0){
                        for (var i = 0; i < array.length; i++) {
                            options.push(
                                {
                                    value: array[i].id,
                                    label: array[i].name,
                                }
                            )
                        };
                    }
                    this.setState({
                        isLoaded: true,
                        options: options
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8086/api/getselladminbyid?id=" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    if(array!=null&&array.length>0){
                        console.log(array)

                        this.setState({
                            isLoaded: true,
                            list: array[0]
                        });
                        this.formRef.current.setFieldsValue(array)
                    }
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    formRef = React.createRef();
    render() {
        const handleChange = (value) => {
            console.log(value)
            this.setState({
                level: value
            });
        };
        const onFinish = (values) => {
            var list = {};
            list.admin_admin = values.admin_admin ? values.admin_admin : this.state.list.admin_admin;
            list.admin_password = values.admin_password ? values.admin_password : this.state.list.admin_password;
            list.admin_level = this.state.level ? this.state.level : this.state.list.admin_level;
            list.admin_name = values.admin_name ? values.admin_name : this.state.list.admin_name;

            function queryParse(query){
                let queryText = "";
                for(let key in query){
                    queryText += `${key}=${query[key]}&`;
                }
                return queryText.slice(0,-1);
            }
            var id = this.state.id
            if(id){
            }else{
                fetch("http://pd.pokemonbye.com:8086/api/insertselladmin",
                    {
                        method:'post',
                        body:queryParse(list),
                        headers:{
                            'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
                        }
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            openNotification();

                            setTimeout(window.location.href="/pagesellsys",1000);
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="新建/编辑账号信息"
                            >
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 10,
                                    }}
                                    layout="horizontal"
                                    size="Large"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Form.Item label="账号" name="admin">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="密码" name="password">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="角色" name="level">
                                        <Select
                                            defaultValue={'请选择'}
                                            style={{
                                                width: 120,
                                            }}
                                            options={this.state.options}
                                            onChange={handleChange}
                                        />
                                    </Form.Item>
                                    <Form.Item label="姓名" name="name">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 4,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">{this.state.id?'提交修改':'创建账号'}</Button>
                                    </Form.Item>
                                </Form>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellSysAdmin;


