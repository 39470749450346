import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm,
    Form,
    Radio,
    Switch,
    Cascader,
    TreeSelect,
    notification,
    message
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;
const content = (<Select
        defaultValue="lucy"
        style={{
            width: 200,
        }}
        options={[
            {
                label: 'Manager',
                options: [
                    {
                        label: 'Jack',
                        value: 'jack',
                    },
                    {
                        label: 'Lucy',
                        value: 'lucy',
                    },
                ],
            },
            {
                label: 'Engineer',
                options: [
                    {
                        label: 'yiminghe',
                        value: 'Yiminghe',
                    },
                ],
            },
        ]}
    />
);
const onChange = (checked) => {
    console.log(`switch to ${checked}`);
};
const onChange1 = (value) => {
    console.log(value);
};

const openNotification = () => {
    notification.open({
        message: '提交成功',
        description:
            '档案修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};
const info = () => {
    message.info('This is a normal message');
};
class PageBusRecord extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        this.state = {
            title: "客户档案管理",
            county: [],
            id: id,
            list: []
        };
    }

    componentDidMount() {
        var id = this.state.id
        if(id) {
            fetch("https://pd.pokemonbye.com:8086/api/getsellclientnum?id=" + id)
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data[0];
                        var s = array.state == 2 ? true : false;
                        array.state = s;
                        this.setState({
                            isLoaded: true,
                            list: array
                        });
                        this.formRef.current.setFieldsValue(array)

                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }
    formRef = React.createRef();
    render() {
        const onFinish = (values) => {
            var list = {};
            list.name = values.name ? values.name : this.state.list.name;
            list.clientid = values.clientid ? values.clientid : this.state.list.clientid;
            list.num = values.num ? values.num : this.state.list.num;
            list.address = values.address ? values.address : this.state.list.address;
            list.tel = values.tel ? values.tel : this.state.list.tel;
            list.bank = values.bank ? values.bank : this.state.list.bank;
            list.banknum = values.banknum ? values.banknum : this.state.list.banknum;
            list.state = values.state ? '2' : '1';
            list.pubtime = values.pubtime ? values.pubtime : this.state.list.pubtime;
            list.time = moment().format('YYYY-MM-DD HH:mm:ss') ;

            var id = this.state.id
            if(this.state.id){
            fetch("https://pd.pokemonbye.com:8086/api/setsellclient?name="+list.name+"&address="+list.address+"&tel="+list.tel+"&num="+list.bank+"&bank="+list.bank+"&banknum="+list.banknum+"&state="+list.state+"&clientid="+list.clientid+"&id=" + id)
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data[0];
                        this.setState({
                            isLoaded: true,
                            list: array
                        });
                        openNotification();
                        setTimeout(window.location.href="/pagebusrecords",1000);
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
            }else{
                fetch("http://pd.pokemonbye.com:8086/api/insertsellclient?name="+list.name+"&address="+list.address+"&tel="+list.tel+"&num="+list.bank+"&bank="+list.bank+"&banknum="+list.banknum+"&state=2"+"&clientid="+list.clientid+"&pubtime=" + list.time)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let array = result.data[0];
                            this.setState({
                                isLoaded: true,
                                list: array
                            });
                            openNotification();

                            setTimeout(window.location.href="/pagebusrecords",1000);
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 10,
                                    }}
                                    layout="horizontal"
                                    size="Large"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Form.Item label="客户编号">
                                        <Input disabled={false} bordered={false} placeholder={this.state.list.clientid}/>
                                    </Form.Item>
                                    <Form.Item label="客户名称" name="name">
                                        <Input placeholder={this.state.list.name}/>
                                    </Form.Item>
                                    <Form.Item label="地址" name="address">
                                        <Input placeholder={this.state.list.address}/>
                                    </Form.Item>
                                    <Form.Item label="联系方式" name="tel">
                                        <Input placeholder={this.state.list.tel}/>
                                    </Form.Item>
                                    <Form.Item label="组织机构代码" name="num">
                                        <Input placeholder={this.state.list.num}/>
                                    </Form.Item>
                                    <Form.Item label="开户行" name="bank">
                                        <Input placeholder={this.state.list.bank}/>
                                    </Form.Item>
                                    <Form.Item label="开户行账号" name="banknum">
                                        <Input placeholder={this.state.list.banknum}/>
                                    </Form.Item>
                                    <Form.Item label="状态" name="state" valuePropName="checked">
                                        <Switch checkedChildren="启用" unCheckedChildren="禁用" onChange={onChange} defaultChecked/>
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 4,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">{this.state.id?'提交修改':'新建'}</Button>
                                    </Form.Item>
                                </Form>

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageBusRecord;


