import React, {Component} from 'react';
import * as echarts from 'echarts';
import moment from "moment";
import geo from '../json/xian.json'

class Datasellonmonth extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                valueFormatter: function (value) {
                    return value + '%';
                }
            },
            xAxis: {
                type: 'category',
                data: ['1月', '2月', '3月', '4月','5月', '6月', '7月', '8月','9月', '10月', '11月', '12月']
            },
            yAxis: {
                type: 'value',
                axisLabel:{
                    formatter:"{value}%"
                }
            },
            legend: {
                data: ['同比销量','环比销量']
            },
            series: [
                {
                    name: '同比',
                    data: [],
                    type: 'line'
                },
                {
                    name: '环比',
                    data: [],
                    type: 'line'
                },
            ]
        };
        this.state = {
            option: option,
            array: [],
            array2: []
        };
    }

    componentDidMount() {
        let yar = moment().format('YYYY') - 1;
        fetch("http://pd.pokemonbye.com:8087/api/getselleverymonth?code="+yar)
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                    fetch("http://pd.pokemonbye.com:8087/api/getselleverymonth?code="+moment().format('YYYY'))
                        .then(res => res.json())
                        .then(
                            (result) => {
                                let array = result.data;
                                let array2 = this.state.array;
                                for (let i = 0; i < array.length; i++) {
                                    array[i].up = ((array[i].sum - array2[i].sum)/array[i].sum * 100).toFixed(2)
                                    if(i == 0 ){

                                        array[i].down = ((array[i].sum - array2[array2.length - 1].sum)/array[i].sum * 100).toFixed(2)
                                    }else{

                                        array[i].down = ((array[i].sum - array[i - 1].sum)/array[i].sum * 100).toFixed(2)
                                    }
                                }
                                console.log(array)
                                this.setState({
                                    isLoaded: true,
                                    array2: array
                                });
                            },
                            // 注意：需要在此处处理错误
                            // 而不是使用 catch() 去捕获错误
                            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        let array2 = this.state.array2;
        const data = [];
        const data2 = [];

        for (var i = 0; i < array2.length; i++) {

            data.push(
                array2[i].up
            );
        }
        for (var i = 0; i < array2.length; i++) {

            data2.push(
                array2[i].down
            );
        }
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datasellonmonth'),'light');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            series: [
                {
                    name: '同比销量',
                    data: data,
                    type: 'line'
                },
                {
                    name: '环比销量',
                    data: data2,
                    type: 'line'
                },
            ]
        });
    }

    render() {
        return (
            <div id="datasellonmonth" className="histogramm"></div>
        )
    }
}

export default Datasellonmonth;
