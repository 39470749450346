import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Dataradar extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            color: ['#103986','#4e7bd3','#4c29af'],
            backgroundColor:'rgba(128, 128, 128, 0)',
            radar: {
                // shape: 'circle',
                indicator: [
                    { name: '氮氧化物均值', max: 6500 },
                    { name: '颗粒物均值', max: 16000 },
                    { name: '二氧化硫均值', max: 30000 },
                ]
            },
            series: [
                {
                    name: 'Budget vs spending',
                    type: 'radar',
                    data: [
                        {
                            value: [4200, 3000, 20000, 35000, 50000, 18000],
                            name: '用电量'
                        },
                        {
                            value: [5000, 14000, 28000, 26000, 42000, 21000],
                            name: '用气量'
                        },
                        {
                            value: [5000, 14000, 28000, 26000, 42000, 21000],
                            name: '用水量'
                        }
                    ]
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getelevatormap")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let array = this.state.array;
        let keyMap = {
            "num": "value"
        };
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            for (var key in obj) {
                var newKey = keyMap[key];
                if (newKey) {
                    obj[newKey] = obj[key];
                    delete obj[key];
                }
            }
        }
        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('dataradar'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="dataradar" className="histogramline"></div>
        )
    }
}

export default Dataradar;
