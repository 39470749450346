import Leftmenu from '../element/leftmenu';
import Header from '../element/header';
import React, {Component} from "react";
import {DatePicker, Popconfirm, Button, Breadcrumb, PageHeader, List, Avatar, Popover, Layout} from 'antd';
import {DeleteOutlined, DownloadOutlined, EditOutlined, UserOutlined, WechatOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Typography, Space, Table, Tag } from 'antd';
import PageBreadcrumb from "../element/pagebreadcrumb";
import Datasellstaff from "../element/datasellstaff";
import Datasellbottom from "../element/datasellbottom";
import Datasellkind from "../element/datasellkind";
import Datasellfunnel from "../element/datasellfunnel";
import Datasellpat from "../element/datasellpat";
const { Title,Text } = Typography;
const { Content } = Layout;
const columns = [
    {
        title: '种类',
        dataIndex: 'stock',
        key: 'stock',
        render: (text) => <a>{text}</a>,
    },
    {
        title: '车辆',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '销售量',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '销售额',
        dataIndex: 'money',
        key: 'money',
    },
    {
        title: '时间',
        dataIndex: 'address',
        key: 'address',
    }
];
const data = [
    {
        key: '1',
        name: '陕AX7162',
        age: "31.36",
        money: "340.00",
        stock: "水泥P.O42.5级 散",
        address: '2023-06-27',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        name: '陕EE0156',
        age: "12.00",
        money: "320.00",
        stock: "水泥M32.5级袋",
        address: '2023-06-27',
        tags: ['nice', 'developer'],
    },
    {
        key: '1',
        name: '陕ED2693',
        age: "30.74",
        money: "270.00",
        stock: "水泥P.O42.5级 散",
        address: '2023-06-27',
        tags: ['nice', 'developer'],
    },
    {
        key: '3',
        name: '陕B37445',
        age: "31.42",
        money: "350.00",
        stock: "水泥P.O42.5级 散",
        address: '2023-06-27',
        tags: ['nice', 'developer'],
    },
    {
        key: '4',
        name: '陕AAR189',
        age: "33.24",
        money: "365.00",
        stock: "水泥P.O42.5级 散",
        address: '2023-06-27',
        tags: ['nice', 'developer'],
    },
    {
        key: '5',
        name: '陕A8M26J',
        age: "32.44",
        money: "340.00",
        stock: "水泥P.O42.5级 散",
        address: '2023-06-27',
        tags: ['nice', 'developer'],
    },
];

class Home extends Component{

    componentDidMount(){

    }
    render() {
        return (

            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <Content className="site-layout-background">
                        <div className="site-card-border-less-wrapper">
                            <PageHeader
                                ghost={false}
                                title="数据概况"
                            >
                            </PageHeader>
                            <Title level={5}>销售情况概览</Title>

                            <div className="site-statistic-demo-card">
                                <Row gutter={[24, 24]}>
                                    <Col span={6}>
                                        <Card hoverable>
                                            <Statistic
                                                title="销售额"
                                                value={8600.27}
                                                precision={2}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                }}
                                                prefix={<ArrowDownOutlined />}
                                                suffix="万元"
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card hoverable>
                                            <Statistic
                                                title="销售量"
                                                value={20.04}
                                                precision={2}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                }}
                                                prefix={<ArrowDownOutlined />}
                                                suffix="万吨"
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card hoverable>
                                            <Statistic
                                                title="注册客户"
                                                value={648}
                                                precision={0}
                                                valueStyle={{
                                                    color: '#cf1322',
                                                }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix="人"
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card hoverable>
                                            <Statistic
                                                title="销售人员"
                                                value={56}
                                                precision={0}
                                                valueStyle={{
                                                    color: '#cf1322',
                                                }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix="人"
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                <Space size={1000}>
                                    <Title level={5}>销售同比曲线</Title>
                                    <Title level={5}>最新订单概览</Title>
                                </Space>
                                <Row gutter={24}>
                                    <Col span={16}>
                                        <Card hoverable>
                                            <Datasellbottom />
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card hoverable>
                                            <Table columns={columns} dataSource={data} pagination={false} bordered scroll={{
                                                y: 200,
                                            }} size={'small'}/>
                                        </Card>
                                    </Col>
                                </Row>
                                <Space size={450}>
                                    <Title level={5}>合作客户排名</Title>
                                    <Title level={5}>销售人员排名</Title>
                                </Space>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Card hoverable>
                                            <Datasellfunnel />
                                        </Card>
                                    </Col>
                                    <Col span={16}>
                                        <Card hoverable>
                                            <Datasellpat />
                                        </Card>
                                    </Col>
                                </Row>
                                <Space size={100}>
                                    <Title level={5}>销售计划完成情况</Title>
                                </Space>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Card hoverable>
                                            <Datasellstaff/>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
        )
    }
}

export default Home;
