import React from 'react';
import cookie from 'react-cookies'
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useRoutes, useSearchParams,
} from "react-router-dom";
import App from './js/App';
import About from './js/Iome';
import Home from './pages/home';
import Data from './pages/data';
import Map from './pages/map';
import List from './pages/list'
import Login from './pages/login';
import './css/style.default.css';/*
import 'antd/dist/antd.css';*/
import {DatasetComponent} from "echarts/components";
import DataHome from "./pages/datahome";
import DataGeneral from "./pages/datageneral";
import DataSell from "./pages/datasell";
import DataProduction from "./pages/dataproduction";
import DataMaterials from "./pages/datamaterials";
import DataFiring from "./pages/datafiring";
import DataMade from "./pages/datamade";
import PageSellTask from "./pages/pageselltask";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import PageDataState from "./pages/pagedatastate";
import PageDataSerch from "./pages/pagedataserch";
import PageDataCurve from "./pages/pagedatacurve";
import PageDataCompare from "./pages/pagedatacompare";
import PageSellBasic from "./pages/pagesellbasic";
import PageSellSys from "./pages/pagesellsys";
import PageBusRecord from "./pages/pagebusrecord";
import PageBusContract from "./pages/pagebuscontract";
import PageBusOrder from "./pages/pagebusorder";
import PageBusPlan from "./pages/pagebusplan";
import PageBusRecords from "./pages/pagebusrecords";
import PageBusOrders from "./pages/pagebusorders";
import PageBusContracts from "./pages/pagebuscontracts";
import PageSellMan from "./pages/pagesellman";
import PageSellStock from "./pages/pagesellstock";
import PageSellMans from "./pages/pagesellmans";
import PagePurDemand from "./pages/pagepurdemand";
import PageDataClient from "./pages/pagedataclient";
import PageDataCapital from "./pages/pagedatacapital";
import PageDataOrder from "./pages/pagedataorder";
import PagePurRecords from "./pages/pagepurrecords";
import PageFinChecks from "./pages/pagefinchecks";
import PageFinLoans from "./pages/pagefinloans";
import PageFinSets from "./pages/pagefinsets";
import PageFinCredits from "./pages/pagefincredits";
import PageFinBills from "./pages/pagefinbills";
import PageFinOrders from "./pages/pagefinorders";
import PagePurOrders from "./pages/pagepurorders";
import PagePurPrices from "./pages/pagepurprices";
import PageSellStocks from "./pages/pagesellstocks";
import PagePurRecord from "./pages/pagepurrecord";
import PageFinContracts from "./pages/pagefincontracts";
import PageFinLoan from "./pages/pagefinloan";
import PageSellSysAdmin from "./pages/pagesellsysadmin";
import PagePurPicks from "./pages/pagepurpicks";
import PagePurPickEds from "./pages/pagepurpickeds";
import PagePurTicket from "./pages/pagepurticket";
import PageSellAreas from "./pages/pagesellareas";


class Logincookie{

    static getUserId() {
        return cookie.load('userId');
    }
}

export class Init extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var i = Logincookie.getUserId();

        if(i != "" && i != null){
            return(
                <ConfigProvider locale={zhCN}>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/datahome" element={<DataHome />} />
                    <Route path="/datageneral" element={<DataGeneral />} />
                    <Route path="/datasell" element={<DataSell />} />
                    <Route path="/dataproduction" element={<DataProduction />} />
                    <Route path="/datamaterials" element={<DataMaterials />} />
                    <Route path="/datafiring" element={<DataFiring />} />
                    <Route path="/datamade" element={<DataMade />} />
                    <Route path="/data" element={<Data />} />
                    <Route path="/pagesellbasic" element={<PageSellBasic />} />
                    <Route path="/pageselltask" element={<PageSellTask />} />
                    <Route path="/pagesellsys" element={<PageSellSys />} />
                    <Route path="/pagesellsysadmin" element={<PageSellSysAdmin />} />
                    <Route path="/pagesellmans" element={<PageSellMans />} />
                    <Route path="/pagesellman" element={<PageSellMan />} />
                    <Route path="/pagesellstock" element={<PageSellStock />} />
                    <Route path="/pagesellstocks" element={<PageSellStocks />} />
                    <Route path="/pagesellareas" element={<PageSellAreas />} />
                    <Route path="/pagedatastate" element={<PageDataState />} />
                    <Route path="/pagedataserch" element={<PageDataSerch />} />
                    <Route path="/pagedatacurve" element={<PageDataCurve />} />
                    <Route path="/pagedatacompare" element={<PageDataCompare />} />
                    <Route path="/pagedataclient" element={<PageDataClient />} />
                    <Route path="/pagedatacapital" element={<PageDataCapital />} />
                    <Route path="/pagedataorder" element={<PageDataOrder />} />
                    <Route path="/pagebusrecords" element={<PageBusRecords />} />
                    <Route path="/pagebusrecord" element={<PageBusRecord />} />
                    <Route path="/pagebuscontracts" element={<PageBusContracts />} />
                    <Route path="/pagebuscontract" element={<PageBusContract />} />
                    <Route path="/pagebusorders" element={<PageBusOrders />} />
                    <Route path="/pagebusorder" element={<PageBusOrder />} />
                    <Route path="/pagebusplan" element={<PageBusPlan />} />
                    <Route path="/pagepurdemand" element={<PagePurDemand />} />
                    <Route path="/pagepurorders" element={<PagePurOrders />} />
                    <Route path="/pagepurprices" element={<PagePurPrices />} />
                    <Route path="/pagepurrecords" element={<PagePurRecords />} />
                    <Route path="/pagepurrecord" element={<PagePurRecord />} />
                    <Route path="/pagepurpicks" element={<PagePurPicks />} />
                    <Route path="/pagepurpickeds" element={<PagePurPickEds />} />
                    <Route path="/pagepurticket" element={<PagePurTicket />} />
                    <Route path="/pagefinchecks" element={<PageFinChecks />} />
                    <Route path="/pagefinloans" element={<PageFinLoans />} />
                    <Route path="/pagefinloan" element={<PageFinLoan />} />
                    <Route path="/pagefinsets" element={<PageFinSets />} />
                    <Route path="/pagefincredits" element={<PageFinCredits />} />
                    <Route path="/pagefinbills" element={<PageFinBills />} />
                    <Route path="/pagefinorders" element={<PageFinOrders />} />
                    <Route path="/pagefincontracts" element={<PageFinContracts />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/map" element={<Map />} />
                    <Route path="/list" element={<List />} />
                    <Route path="/:404" element={<Login />} />
                </Routes>
            </Router>
                </ConfigProvider>
        );
        }else{
            return(
                <Router>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/:404" element={<Login />} />
                        <Route path="/pagepurticket" element={<PagePurTicket />} />
                    </Routes>
                </Router>
            );
        }
        return null;

    }

}
ReactDOM.render(
    <Init/>,
  document.getElementById('root')
);

