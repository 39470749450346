import Leftmenu from '../element/leftmenu';
import Databorder from '../element/header';
import React, {Component} from "react";
import { DatePicker,Popconfirm,Button,Menu, Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Line } from '@antv/g2plot';
import Histogramecharts from "../element/histogramecharts";
import Pieecharts from "../element/pieecharts";
import Linecharts from "../element/lineecharts";
import Line1charts from "../element/line1echarts";
import Line2charts from "../element/line2echarts";
import Line3charts from "../element/line3echarts";
import Pagelist from "../element/pagelist";
import Dataheader from "../element/dataheader";
import Datamenu from "../element/datamenu";
import Factorymapcharts from "../element/factorymapcharts";
import Datapie from "../element/datapie";
import Datatitle from "../element/datatitle";
import moment from "moment";
import Datafunnel from "../element/datafunnel";
import Datalinebottom from "../element/datalinebottom";
import Datacategory from "../element/datacategory";
import Datatitlemonth from "../element/datatitlemonty";
import Dataring from "../element/dataring";
import Dataline from "../element/dataline";
import Datasellkind from "../element/datasellkind";
import Datasellline from "../element/datasellline";
import Datasellbottom from "../element/datasellbottom";
import Datadadar from "../element/datadadar";
import Datasellstaff from "../element/datasellstaff";
import Datasellfunnel from "../element/datasellfunnel";
import Datasellpat from "../element/datasellpat";

class DataSell extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            county: [],
            area: pid,
            list: [
                {
                    floor:"矿粉",
                    unit:"4742.3",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"熟料",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"石灰石粉",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"2714.2",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"复合超细粉",
                    unit:"2714.2",
                    elevator:"010301043",
                    medium:"4742.3",
                    tag:"ZL60",
                    taboo:"35",
                },
            ]
        };
    }

    componentDidMount() {
    }
    render() {

        return (
            <div>
                <div className="mainmax">
                    <Dataheader/>
                    <div className="gborder">
                        <div className="heigh">
                            <div className="bordersellw">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>{moment().format('M')}月分类销售占比</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datasellkind />
                            </div>
                            <div className="borderselll top80">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>{moment().format('YYYY')}年销量曲线</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datasellbottom />
                            </div>
                        </div>
                        <div className="heigh left35">
                            <div className="borderselll">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>{moment().format('YYYY')}年月份计划完成曲线</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datasellstaff/>
                            </div>
                            <div className="bordersellww top40">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>{moment().format('YYYY')}年销售任务</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datatitle />
                            </div>
                        </div>
                        <div className="heigh right">
                            <div className="bordersellww top40">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>合作伙伴排名</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datasellfunnel />
                            </div>
                            <div className="bordersellwww top80 right5">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <div className="title">
                                    <p>销售成绩排名</p>
                                    <div className="rbgm"></div>
                                    <div className="rbgm"></div>
                                    <div className="rbg"></div>
                                </div>
                                <Datasellpat />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DataSell;
