import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Datadadar extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            color: ['#103986','#4e7bd3','#4c29af'],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            title: {
            },
            legend: {
            },
            radar: {
                // shape: 'circle',
                indicator: [
                    { name: '余热发电'},
                    { name: '自用电量'},
                    { name: '净发电量'},
                    { name: '总发电量'},
                    { name: '吨熟料发电量'}
                ]
            },
            series: [
                {
                    type: 'radar',
                    data: [
                        {
                            value: [3636, 70.28, 3636, 4634, 0.54]
                        }
                    ],
                    lineStyle: {
                        type: 'dashed'
                    },
                    label: {
                        show: true,
                        formatter: function (params) {
                            return params.value;
                        }
                    }
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getelevatormap")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datadadar'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="datadadar" className="histogramlar"></div>
        )
    }
}

export default Datadadar;
