import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Card,
    List,
    Popconfirm
} from 'antd';
import 'moment/locale/zh-cn';

const {Title, Text} = Typography;
const {Content} = Layout;

const Delete = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellstockstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button onClick={showPopconfirm}>
                删除
            </Button>
        </Popconfirm>
    );
};

const onGo = (id) => {
    window.location.href="/pagesellstock?id=" + id
};
const onGo2 = () => {
    window.location.href="/pagesellstock"
};

class PageSellStocks extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "产品类型",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getsellstock")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].pubid = i + 1
                        /*
                        array[i].href = "/pagesellman?id="+array[i].id;
                        array[i].type = array[i].type == 2 ? '袋装' : '散装';
                        */
                    }
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="管理编辑产品类型及对应价格"
                            >
                                <Button onClick={() => onGo2()}>新增</Button>
                                <br/>
                                <br/>
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 4,
                                        xl: 6,
                                        xxl: 4,
                                    }}
                                    dataSource={this.state.list}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Card
                                            title={item.pubid}
                                            extra={<Space><Button onClick={() => onGo(item.id)}>编辑</Button><Delete id={item.id}>删除</Delete></Space>}
                                            >
                                                <p>名称：{item.stockname}</p>
                                                <p>编号：{item.stocknum}</p>
                                                <p>规格：{item.type}</p>
                                                <p>价格：{item.stockprice}</p>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellStocks;


