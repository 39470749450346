import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Homeecharts extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            color: ['#ef7567'],
            backgroundColor:'rgba(128, 128, 128, 0)',
            title: {
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['投入', '产出']
            },
            toolbox: {
                show: false,
                feature: {
                    dataView: { show: true, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    // prettier-ignore
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: '生产料',
                    type: 'bar',
                    data: [
                        23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
                    ],
                    markLine: {
                        data: [{ type: 'average', name: 'Avg' }]
                    }
                },
                {
                    name: '回转窑',
                    type: 'bar',
                    data: [
                        26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                    ],
                    markLine: {
                        data: [{ type: 'average', name: 'Avg' }]
                    }
                },
                {
                    name: '水泥磨',
                    type: 'bar',
                    data: [
                        11.4, 48.7, 40.7, 115.6, 282.2, 68.7, 28.8, 16.0, 12.3
                    ],
                    markLine: {
                        data: [{ type: 'average', name: 'Avg' }]
                    }
                },
                {
                    name: '矿粉磨',
                    type: 'bar',
                    data: [
                        116.4, 118.7, 117.7, 175.6, 182.2, 218.7, 228.8, 116.0, 112.3
                    ],
                    markLine: {
                        data: [{ type: 'average', name: 'Avg' }]
                    }
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getelevatormap")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let array = this.state.array;
        let keyMap = {
            "num": "value"
        };
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            for (var key in obj) {
                var newKey = keyMap[key];
                if (newKey) {
                    obj[newKey] = obj[key];
                    delete obj[key];
                }
            }
        }
        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('forms'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
    }

    render() {
        return (
            <div id="forms" className="histogram"></div>
        )
    }
}

export default Homeecharts;
