import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Table, Tag,
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const columns = [
    {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '类型',
        dataIndex: 'stockname',
        key: 'stockname',
    },
    {
        title: '销量（吨）',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: '价格（元）',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: '区域',
        dataIndex: 'project',
        key: 'project',
    },
    {
        title: '车辆',
        dataIndex: 'truck',
        key: 'truck',
    },
    {
        title: '销售',
        dataIndex: 'man',
        key: 'man',
    },
    {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button type="primary" size="small" disabled>
                    管理
                </Button>
                <Button type="primary" size="small" onClick={confirm}>
                    化验单
                </Button>
                <Button type="primary" size="small" disabled>
                    删除
                </Button>
            </Space>
        ),
    },
];
const confirm = (e) => {
    window.open('http://booleango-stsn.oss-cn-beijing.aliyuncs.com/huayandan.jpg');
};

const content = (<Select
        defaultValue="lucy"
        style={{
            width: 200,
        }}
        options={[
            {
                label: 'Manager',
                options: [
                    {
                        label: 'Jack',
                        value: 'jack',
                    },
                    {
                        label: 'Lucy',
                        value: 'lucy',
                    },
                ],
            },
            {
                label: 'Engineer',
                options: [
                    {
                        label: 'yiminghe',
                        value: 'Yiminghe',
                    },
                ],
            },
        ]}
    />
);

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const handleChange = (value) => {

    fetch("http://pd.pokemonbye.com:8087/api/getsellbill?code=" + value)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;

                for (let i = 0; i < array.length; i++) {
                    array[i].id = i + 1;
                }
                this.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
};

class PageBusOrders extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "流水管理",
            county: [],
            area: pid,
            list: [],
            menu: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8087/api/getsellclientdao")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    var menu = [];
                    for (let i = 0; i < array.length; i++) {
                        menu.push(
                            {
                                value: array[i].stockid,
                                label: array[i].stockname,
                            }
                        )
                    }
                    this.setState({
                        isLoaded: true,
                        menu: menu
                    });

                    fetch("http://pd.pokemonbye.com:8087/api/getsellbill?code=KH0908")
                        .then(res => res.json())
                        .then(
                            (result) => {
                                let array = result.data;

                                for (let i = 0; i < array.length; i++) {
                                    array[i].id = i + 1;
                                }
                                this.setState({
                                    isLoaded: true,
                                    list: array
                                });
                            },
                            // 注意：需要在此处处理错误
                            // 而不是使用 catch() 去捕获错误
                            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Space>
                                    <Select
                                        defaultValue="富平公司"
                                        style={{
                                            width: 120,
                                        }}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: '富平公司',
                                                label: '富平公司',
                                            }
                                        ]}
                                    />
                                    <Select
                                        defaultValue='陕西建材科技集团'
                                        style={{
                                            width: 200,
                                        }}
                                        onChange={handleChange}
                                        options={this.state.menu}
                                    />
                                </Space>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageBusOrders;


