import Leftmenu from '../element/leftmenu';
import Databorder from '../element/header';
import React, {Component} from "react";
import { DatePicker,Popconfirm,Button,Menu, Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Line } from '@antv/g2plot';
import Histogramecharts from "../element/histogramecharts";
import Pieecharts from "../element/pieecharts";
import Linecharts from "../element/lineecharts";
import Line1charts from "../element/line1echarts";
import Line2charts from "../element/line2echarts";
import Line3charts from "../element/line3echarts";
import Pagelist from "../element/pagelist";
import Dataheader from "../element/dataheader";
import Datamenu from "../element/datamenu";
import Factorymapcharts from "../element/factorymapcharts";
import Datapie from "../element/datapie";
import Datatitle from "../element/datatitle";
import moment from "moment";
import Datafunnel from "../element/datafunnel";
import Datalinebottom from "../element/datalinebottom";
import Datacategory from "../element/datacategory";
import Datatitlemonth from "../element/datatitlemonty";
import Dataring from "../element/dataring";
import Dataline from "../element/dataline";
import Databg from "../element/databg";
import Dataradar from "../element/dataradar";
import Datamaterialspie from "../element/datamaterialspie";
import Datamaterialsline from "../element/datamaterialsline";
import Datafiringline from "../element/datafiringline";
import Datamadebg from "../element/datamadebg";
import Datafiringbg from "../element/datafiringbg";

class DataFiring extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            array: [{"id":24549,"chanliang":"0.00","taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24548,"chanliang":"20.90","taishichanliang":"41.80","yunzhuanlv":"50.00","gongxudanweidianhao":"47.85","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24547,"chanliang":"42.20","taishichanliang":"42.20","yunzhuanlv":"100.00","gongxudanweidianhao":"35.55","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24546,"chanliang":"41.50","taishichanliang":"41.50","yunzhuanlv":"100.00","gongxudanweidianhao":"36.15","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24545,"chanliang":"41.80","taishichanliang":"41.80","yunzhuanlv":"100.00","gongxudanweidianhao":"33.50","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24544,"chanliang":"31.90","taishichanliang":"31.90","yunzhuanlv":"100.00","gongxudanweidianhao":"43.89","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24543,"chanliang":"0.00","taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null}],
            array2: [{"id":24549,"chanliang":"0.00","taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24548,"chanliang":"20.90","taishichanliang":"41.80","yunzhuanlv":"50.00","gongxudanweidianhao":"47.85","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24547,"chanliang":"42.20","taishichanliang":"42.20","yunzhuanlv":"100.00","gongxudanweidianhao":"35.55","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24546,"chanliang":"41.50","taishichanliang":"41.50","yunzhuanlv":"100.00","gongxudanweidianhao":"36.15","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24545,"chanliang":"41.80","taishichanliang":"41.80","yunzhuanlv":"100.00","gongxudanweidianhao":"33.50","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24544,"chanliang":"31.90","taishichanliang":"31.90","yunzhuanlv":"100.00","gongxudanweidianhao":"43.89","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24543,"chanliang":"0.00","taishichanliang":"0.00","yunzhuanlv":"0.00","gongxudanweidianhao":"0.00","gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null}],
            count: [{"id":24516,"chanliang":"196.90","taishichanliang":"262.53","yunzhuanlv":"100.00","gongxudanweidianhao":"24.87","gongxudanweimeihao":"123.51","gongxudanweirehao":"635804.98","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24515,"chanliang":"258.40","taishichanliang":"258.40","yunzhuanlv":"100.00","gongxudanweidianhao":"23.87","gongxudanweimeihao":"126.20","gongxudanweirehao":"649748.45","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24514,"chanliang":"257.78","taishichanliang":"257.78","yunzhuanlv":"100.00","gongxudanweidianhao":"24.34","gongxudanweimeihao":"128.29","gongxudanweirehao":"660388.70","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24513,"chanliang":"257.15","taishichanliang":"257.15","yunzhuanlv":"100.00","gongxudanweidianhao":"24.46","gongxudanweimeihao":"128.64","gongxudanweirehao":"662006.61","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24512,"chanliang":"255.91","taishichanliang":"255.91","yunzhuanlv":"100.00","gongxudanweidianhao":"25.27","gongxudanweimeihao":"130.59","gongxudanweirehao":"672072.21","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24511,"chanliang":"252.80","taishichanliang":"252.80","yunzhuanlv":"100.00","gongxudanweidianhao":"25.86","gongxudanweimeihao":"129.43","gongxudanweirehao":"666455.70","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24510,"chanliang":"254.03","taishichanliang":"254.03","yunzhuanlv":"100.00","gongxudanweidianhao":"28.37","gongxudanweimeihao":"128.84","gongxudanweirehao":"663228.75","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null}],
            count2: [{"id":24516,"chanliang":"196.90","taishichanliang":"262.53","yunzhuanlv":"100.00","gongxudanweidianhao":"24.87","gongxudanweimeihao":"123.51","gongxudanweirehao":"635804.98","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24515,"chanliang":"258.40","taishichanliang":"258.40","yunzhuanlv":"100.00","gongxudanweidianhao":"23.87","gongxudanweimeihao":"126.20","gongxudanweirehao":"649748.45","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24514,"chanliang":"257.78","taishichanliang":"257.78","yunzhuanlv":"100.00","gongxudanweidianhao":"24.34","gongxudanweimeihao":"128.29","gongxudanweirehao":"660388.70","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24513,"chanliang":"257.15","taishichanliang":"257.15","yunzhuanlv":"100.00","gongxudanweidianhao":"24.46","gongxudanweimeihao":"128.64","gongxudanweirehao":"662006.61","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24512,"chanliang":"255.91","taishichanliang":"255.91","yunzhuanlv":"100.00","gongxudanweidianhao":"25.27","gongxudanweimeihao":"130.59","gongxudanweirehao":"672072.21","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24511,"chanliang":"252.80","taishichanliang":"252.80","yunzhuanlv":"100.00","gongxudanweidianhao":"25.86","gongxudanweimeihao":"129.43","gongxudanweirehao":"666455.70","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null},{"id":24510,"chanliang":"254.03","taishichanliang":"254.03","yunzhuanlv":"100.00","gongxudanweidianhao":"28.37","gongxudanweimeihao":"128.84","gongxudanweirehao":"663228.75","meifenxiaohao":null,"yuanmeixiaohao":null,"anshuixiaohao":null}],
            num: [{"id":59661,"chanliang":null,"taishichanliang":null,"yunzhuanlv":null,"gongxudanweidianhao":null,"gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":"7.40","yuanmeixiaohao":"8.41","anshuixiaohao":"425.28"}],
            num2: [{"id":59661,"chanliang":null,"taishichanliang":null,"yunzhuanlv":null,"gongxudanweidianhao":null,"gongxudanweimeihao":null,"gongxudanweirehao":null,"meifenxiaohao":"7.40","yuanmeixiaohao":"8.41","anshuixiaohao":"425.28"}],
            area: pid,
            list: [
                {
                    floor:"矿粉",
                    unit:"4742.3",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"熟料",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"958004.49",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"石灰石粉",
                    unit:"13419.36",
                    elevator:"010301043",
                    medium:"2714.2",
                    tag:"ZL60",
                    taboo:"35",
                },
                {
                    floor:"复合超细粉",
                    unit:"2714.2",
                    elevator:"010301043",
                    medium:"4742.3",
                    tag:"ZL60",
                    taboo:"35",
                },
            ]
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getpdcoal?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcoal2?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdkiln?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        count: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdkiln2?code=%E5%B0%8F%E6%97%B6")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        count2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdkilnconsume")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        num: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdkilnconsume2")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        num2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {
        return (
            <div>
                <div className="mainmax">
                    <Dataheader/>
                    <div className="colorbg">
                        <Datafiringbg/>
                        <div className="datafheigh top10 left10">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-turangcanshu"></i>
                                <p>1号煤磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texture"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.array[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.array[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.array[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.array[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="texturebottom"></div>
                        </div>
                        <div className="datafheigh top10 left40">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-turangcanshu"></i>
                                <p>2号煤磨</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texture"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.array2[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.array2[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.array2[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.array2[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="texturebottom"></div>
                        </div>
                        <div className="datafheigh2 top30 left5">
                            <div className="stockbg">
                                <i className="fa iconfont icon-turangyanfen"></i>
                                <p>1号窑<br/>煤粉消耗<br/>{this.state.num[0].meifenxiaohao}(吨)</p>
                            </div>
                            <div className="stockbg">
                                <i className="fa iconfont icon-tudui"></i>
                                <p>1号窑<br/>原煤消耗<br/>{this.state.num[0].yuanmeixiaohao}(吨)</p>
                            </div>
                            <div className="stockbg">
                                <i className="fa iconfont icon-gongcheng-3"></i>
                                <p>1号窑<br/>氨水消耗<br/>{this.state.num[0].anshuixiaohao}(吨)</p>
                            </div>
                            <div className="stockbg left10">
                                <i className="fa iconfont icon-turangyanfen"></i>
                                <p>2号窑<br/>煤粉消耗<br/>{this.state.num2[0].meifenxiaohao}(吨)</p>
                            </div>
                            <div className="stockbg left10">
                                <i className="fa iconfont icon-tudui"></i>
                                <p>2号窑<br/>原煤消耗<br/>{this.state.num2[0].yuanmeixiaohao}(吨)</p>
                            </div>
                            <div className="stockbg left10">
                                <i className="fa iconfont icon-gongcheng-3"></i>
                                <p>2号窑<br/>氨水消耗<br/>{this.state.num2[0].anshuixiaohao}(吨)</p>
                            </div>
                        </div>
                        <div className="datafheigh3 top50 left5">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-turangyanfen"></i>
                                <p>1号窑</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texturebottom"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.count[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.count[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.count[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.count[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位煤耗<br/>{this.state.count[0].gongxudanweimeihao}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位热耗<br/>{this.state.count[0].gongxudanweirehao}(焦)</p>
                            </div>
                            <div className="texture"></div>
                        </div>
                        <div className="datafheigh3 top50 left70">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <i className="fa iconfont icon-turangyanfen"></i>
                                <p>2号窑</p>
                                <div className="rbgm"></div>
                                <div className="rbgm"></div>
                                <div className="rbg"></div>
                            </div>
                            <div className="texturebottom"></div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>产量<br/>{this.state.count2[0].chanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>台时产量<br/>{this.state.count2[0].taishichanliang}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>运转率<br/>{this.state.count2[0].yunzhuanlv}(%)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位电耗<br/>{this.state.count2[0].gongxudanweidianhao}(千瓦)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位标煤耗<br/>{this.state.count2[0].gongxudanweimeihao}(吨)</p>
                            </div>
                            <div className="firing">
                                <div className="lt"></div>
                                <div className="rt"></div>
                                <br/>
                                <p>工序单位热耗<br/>{this.state.count2[0].gongxudanweirehao}(焦)</p>
                            </div>
                            <div className="texture"></div>
                        </div>
                        <div className="datafiringheigh left30 top51">
                            <div className="lt"></div>
                            <div className="rt"></div>
                            <div className="title">
                                <p>窑产量曲线</p>
                            </div>
                            <Datafiringline />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DataFiring;
