import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Table, Tag,
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const Go = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellcontractstate?state=2&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.href="/pagebuscontracts"

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="创建合同？"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button type="primary" onClick={showPopconfirm}>
                合同
            </Button>
        </Popconfirm>
    );
};
const Delete = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellcontractstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button onClick={showPopconfirm}>
                删除
            </Button>
        </Popconfirm>
    );
};
const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '客户',
        dataIndex: 'client',
        key: 'client',
    },
    {
        title: '类型',
        dataIndex: 'stock',
        key: 'stock',
    },
    {
        title: '需求量（吨）',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: '价格（元/吨）',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: '联系人',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '联系方式',
        dataIndex: 'tel',
        key: 'tel',
    },
    {
        title: '提报时间',
        dataIndex: 'pubtime',
        key: 'pubtime',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Go id={record.id}>合同</Go>
                <Delete id={record.id}>删除</Delete>
            </Space>
        ),
    },
];

const confirm = (e) => {
    //window.location.href = '/pagebuscontracts';
    console.log(e);
};
const cancel = (e) => {
    console.log(2);
};

const content = (<Select
        defaultValue="lucy"
        style={{
            width: 200,
        }}
        options={[
            {
                label: 'Manager',
                options: [
                    {
                        label: 'Jack',
                        value: 'jack',
                    },
                    {
                        label: 'Lucy',
                        value: 'lucy',
                    },
                ],
            },
            {
                label: 'Engineer',
                options: [
                    {
                        label: 'yiminghe',
                        value: 'Yiminghe',
                    },
                ],
            },
        ]}
    />
);

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};

const Load = (that,id) => {

    fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystateclient?state=1&id=" + id)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;

                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class PagePurDemand extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "需求提报",
            county: [],
            area: pid,
            list: [],
            menu: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getsellclientlist")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    var menu = [];
                    for (let i = 0; i < array.length; i++) {
                        menu.push(
                            {
                                value: array[i].id,
                                label: array[i].name,
                            }
                        )
                    }
                    console.log(menu)
                    this.setState({
                        isLoaded: true,
                        options: menu
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystate?state=1")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;

                    for (let i = 0; i < array.length; i++) {
                        array[i].key = i + 1;
                    }
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const handleChange = (value) => {
            Load(this,value)
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Space>
                                    <Select
                                        defaultValue="富平公司"
                                        style={{
                                            width: 120,
                                        }}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: '富平公司',
                                                label: '富平公司',
                                            }
                                        ]}
                                    />
                                    <Select
                                        defaultValue="请选择"
                                        style={{
                                            width: 220,
                                        }}
                                        onChange={handleChange}
                                        options={this.state.options}
                                    />
                                </Space>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PagePurDemand;