import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Calendar, Modal
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const onPanelChange = (value) => {
    console.log(value.format('YYYY-MM-DD'));
};
const onChange = (value) => {
    console.log('changed', value);
};

const DecimalStep = () => {

    const [open, setOpen] = useState(100);

    const onPanelChange = (value) => {
        console.log(value.format('YYYY-MM-DD'));
        setOpen(value.format('YYYY-MM-DD'));
    };

    return (
        <>
            <div className="site-calendar-demo-card">
                <Calendar fullscreen={false} onSelect={onPanelChange} />
            </div>
            <br/>
            <Row>
                <Col span={8} offset={8}>
                    <Title level={5}>当月发运计划</Title>
                    <Space direction="vertical">
                        <InputNumber
                            style={{
                                width: 400,
                            }}
                            size="large"
                            addonAfter="$"
                            defaultValue={100}
                            value={open}
                        />
                        <InputNumber
                            style={{
                                width: 400,
                            }}
                            size="large"
                            defaultValue={1000}
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={onChange}
                            value={open}
                        />
                        <Button type="primary" size="large" onClick={onChange}>设定</Button>
                    </Space>
                </Col>
            </Row>
        </>
    );
};
class PageBusPlan extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "发运计划",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <DecimalStep/>
                                <br/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageBusPlan;


