import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'
import moment from "moment";

class Datasellpat extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            dataset: [
                {
                    dimensions: ['stockname', 'sum'],
                    source: [
                    ]
                }
            ],
            xAxis: {
                type: 'category',
                axisLabel: { interval: 0, rotate: 30 }
            },
            yAxis: {},
            series: {
                type: 'bar',
                encode: { x: 'name', y: 'score' }
            }
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        const start = moment().format('YYYY') + "-01-01";
        const end = moment().add(1, 'years').format('YYYY') + "-01-01";
        fetch("http://pd.pokemonbye.com:8087/api/getsellman?num=16&start=" + start + "&end=" + end)
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        const data = [];

        for (var i = 0; i < 2; i++) {

            const man = [];
            man.push((array[i].stockname))
            man.push((array[i].sum))

            data.push(man);
        }

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datasellpat'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            dataset: [
                {
                    dimensions: ['stockname', 'sum'],
                    source: array
                }
            ],
        });
    }

    render() {
        return (
            <div id="datasellpat" className="histogramlar"></div>
        )
    }
}

export default Datasellpat;
