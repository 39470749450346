import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DeleteOutlined, DownloadOutlined, FormOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Card,
    List, Table, Popconfirm, notification, Popover
} from 'antd';
import 'moment/locale/zh-cn';
import cookie from "react-cookies";
import {log} from "@antv/g2plot/lib/utils";

const {Title, Text} = Typography;
const {Content} = Layout;

const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const data = [
    {
        id: '1',
        key: '1',
        name: 'admin',
        password: '******',
        level: '管理员',
    },
    {
        id: '2',
        key: '2',
        name: 'analyst',
        password: '******',
        level: '数据分析师',
    },
];
const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '名字',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '账号',
        dataIndex: 'admin',
        key: 'admin',
    },
    {
        title: '小程序码',
        dataIndex: 'admin',
        key: 'admin',
        render: (_, record) => (
            <Space size="middle">
                {record.levelid==1?record.levelid:<Button disabled>无权限</Button>}
            </Space>
        ),
    },
    {
        title: '角色',
        dataIndex: 'level',
        key: 'level',
    },
    {
        title: '加入时间',
        dataIndex: 'pubtime',
        key: 'pubtime',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <>
            {record.levelid==1?
            <Space size="middle">
                <Go id={record.id}>编辑</Go>
                <Delete id={record.id}>删除</Delete>
            </Space>
                :
            <Button disabled>无权限</Button>
            }
            </>
        ),
    },
];
const columns2 = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '角色名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button type="primary" size="small" disabled>
                    删除
                </Button>
            </Space>
        ),
    },
];

const openNotification = () => {
    notification.open({
        message: '成功',
        description:
            '密码修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};

const handleOk = (id,value) => {
    console.log(id)
    console.log(value)
    setTimeout(() => {

        fetch("http://pd.pokemonbye.com:8086/api/setselladminpassword?password=" + value + "&id=" + id.id)
            .then(res => res.json())
            .then(
                (result) => {
                    openNotification();

                },
                (error) => {
                }
            )
    }, 1000);
};
const DecimalStep = (id) => {
    const [inputValue, setInputValue] = useState('');
    const onChange = (value) => {
        console.log(value.target.value)
        setInputValue(value.target.value);
    };
    return (
        <>
            <Input.Password onChange={onChange}></Input.Password>
            <br/>
            <br/>
            <Button type="default" size="small"  onClick={() => handleOk(id.id,inputValue)}>
                保存
            </Button>
        </>
    );
};
const content = (id) => (
    <>
        <Space direction="vertical"
               style={{
                   width: 300,
               }}>
            <DecimalStep id={id}/>
        </Space>
    </>
);
const Go = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popover content={() => content(id)} title="修改密码" trigger="click">
            <FormOutlined >
                Download
            </FormOutlined>
        </Popover>
    );
};
const Edit = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setselladminpassword?password=" + id.password + "&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        openNotification();

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
    <Popconfirm
        title="修改密码？"
        open={open}
        onConfirm={handleOk}
        okButtonProps={{
            loading: confirmLoading,
        }}
        onCancel={handleCancel}
    >
        <FormOutlined onClick={showPopconfirm}>
            Download
        </FormOutlined>
    </Popconfirm>
    );
};

const Delete = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setselladminstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <DeleteOutlined onClick={showPopconfirm}>
                Download
            </DeleteOutlined>
        </Popconfirm>
    );
};

const onGo = (id) => {
    window.location.href="/pagesellsysadmin"
};

class PageSellSys extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        let userList = cookie.load('userList');
        console.log(userList)
        this.state = {
            title: "系统管理",
            county: [],
            area: pid,
            list: [],
            userList: userList
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getselladminlist")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let levelid = this.state.userList.levelid;
                    for (let i = 0; i < array.length; i++) {
                        array[i].key = i + 1;
                        array[i].levelid = levelid;
                    }
                    console.log(array)
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8086/api/getselladminlevellist")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].key = i + 1;
                    }
                    this.setState({
                        isLoaded: true,
                        list2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="管理系统信息"
                            >
                                <Title level={5}>角色</Title>
                                <Table bordered={true} columns={columns2} dataSource={this.state.list2}/>
                                <Title level={5}>账号管理</Title>

                                <Button onClick={() => onGo()}>新增</Button>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellSys;


