import React, {Component} from "react";
import Leftmenu from "./leftmenu";
import Datamenu from "./datamenu";
import moment from "moment";

class Dataheader extends Component{
    constructor(props) {
        super(props);
        this.state = {
            dispaly: '',
            time: ''
        };
    }
    close(){
        this.setState({
            dispaly: '',
        });
    }
    show(){
        this.setState({
            dispaly: 'show',
        });
    }
    componentDidUpdate() {
    }
    handleClick = () => {
        this.setState({
            time: moment().format('YYYY-MM-DD HH:mm:ss')
        });
    }
    componentDidMount() {
        this.setState({
            time: moment().format('YYYY-MM-DD HH:mm:ss')
        });
        function run() {
            this.setState({
                time: moment().format('YYYY-MM-DD HH:mm:ss')
            });
        }

        setInterval(this.handleClick, 1000);
    }
    render() {
        return (
            <div className="topheader">
                <Datamenu value={this.state.dispaly} onClick={this.close}/>
                <div className="logo"/>
                <h1>陕西建材科技集团数据中心</h1>
                <h3>{this.state.time}</h3>
                <a className="datamenu" onClick={() => this.show()}>
                    <label htmlFor="ac"><i className="fa iconfont icon-icon-test"></i></label>
                </a>
                <select id="name" className="rightscreen right0">
                    <option value="1">富平水泥公司</option>
                    <option value="2">黄陵水泥公司</option>
                </select>
                <a className="rightbutton right0" href="/login">
                    管理中心
                </a>
            </div>
        );
    }
}

export default Dataheader;
