import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs,
    Badge
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const columns = [
    {
        title: '类型',
        dataIndex: 'stockname',
        key: 'stockname',
    },
    {
        title: '金额（元）',
        dataIndex: 'price',
        key: 'price',
        render: (price) => <p>{price ? (price/1).toFixed(2) : '0.00'}</p>,
    },
    {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
        render: (date) => <p>{date ? date.substring(0,19) : '0.00'}</p>,
    }
];

const Load = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8087/api/getsellbillbydate?start=" + start + "&end=" + end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                let max = 0;
                let num = 0;
                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                    num = i;
                    max = max + parseFloat(array[i].price);
                }
                that.setState({
                    isLoaded: true,
                    list: array,
                    num: num + 1,
                    max: max.toFixed(2)
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const Load2 = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystatedate?state=2&start=" + start + "&end=" + end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                let max = 0;
                let num = 0;
                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                    num = i;
                    max = max + parseFloat(array[i].price*array[i].value);
                }
                that.setState({
                    isLoaded: true,
                    list2: array,
                    num2: num + 1,
                    max2: max.toFixed(2)
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const Load3 = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8086/api/getsellreturndate?state=1&start=" + start + "&end=" + end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                let max = 0;
                let num = 0;
                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                    num = i;
                    max = max + parseFloat(array[i].return_price);
                }
                that.setState({
                    isLoaded: true,
                    list3: array,
                    num3: num + 1,
                    max3: max.toFixed(2)
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class PageFinSets extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "财务扎帐",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        Load(this,moment().subtract(1, "days").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
        Load2(this,moment().subtract(1, "days").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
        Load3(this,moment().subtract(1, "days").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                // Column configuration not to be checked
                name: record.name,
            }),
        };
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
            Load2(this,dateString[0], dateString[1])
            Load3(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Space>
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    options={[
                                        {
                                            value: '富平公司',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                                <RangePicker defaultValue={[moment().subtract(1, "days"), moment()]} onChange={onChange}/>
                                <Button>扎帐</Button>
                            </Space>
                                <br/>
                                <br/>
                                <Descriptions bordered>
                                    <Descriptions.Item label="合同金额（元）" span={2}>{this.state.max2}</Descriptions.Item>
                                    <Descriptions.Item label="合同数量">{this.state.num2}</Descriptions.Item>
                                    <Descriptions.Item label="回款金额（元）" span={2}>{this.state.max3}</Descriptions.Item>
                                    <Descriptions.Item label="回款单">{this.state.num3}</Descriptions.Item>
                                    <Descriptions.Item label="订单金额（元）" span={2}>{this.state.max}</Descriptions.Item>
                                    <Descriptions.Item label="订单量">{this.state.num}</Descriptions.Item>
                                </Descriptions>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list} rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageFinSets;


