import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm,
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined, UserOutlined} from '@ant-design/icons';
import 'moment/locale/zh-cn';
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;
const content = (<Select
        defaultValue="lucy"
        style={{
            width: 200,
        }}
        options={[
            {
                label: 'Manager',
                options: [
                    {
                        label: 'Jack',
                        value: 'jack',
                    },
                    {
                        label: 'Lucy',
                        value: 'lucy',
                    },
                ],
            },
            {
                label: 'Engineer',
                options: [
                    {
                        label: 'yiminghe',
                        value: 'Yiminghe',
                    },
                ],
            },
        ]}
    />
);

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const stateMenu = [
    {
        value: 2,
        label: '未签订'
    },
    {
        value: 3,
        label: '待审核'
    },
    {
        value: 4,
        label: '已签订'
    },
]

const load = (that,value) => {
    fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystate?state=" + value)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;

                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                    array[i].max = (array[i].value * array[i].price).toFixed(2);
                    array[i].href = "/pagebuscontract?id="+array[i].id;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
};
class PageBusContracts extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "合同管理",
            county: [],
            area: pid,
            list: [],
            st: 2
        };
    }

    componentDidMount() {

        fetch("http://pd.pokemonbye.com:8086/api/getsellclientlist")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    var menu = [];
                    for (let i = 0; i < array.length; i++) {
                        menu.push(
                            {
                                value: array[i].id,
                                label: array[i].name
                            }
                        )
                    }
                    this.setState({
                        isLoaded: true,
                        menu: menu
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        load(this,this.state.st)
    }

    render() {
        const onChange = (value) => {
            const clientid = this.state.clientid;
            this.setState({
                st: value
            });
            if(clientid){

                fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystateclient?state=" + value + "&id=" + clientid)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let array = result.data;

                            for (let i = 0; i < array.length; i++) {
                                array[i].key = i + 1;
                                array[i].max = array[i].value * array[i].price;
                                array[i].href = "/pagebuscontract?id="+array[i].id;
                            }
                            this.setState({
                                isLoaded: true,
                                list: array
                            });
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }else{

                load(this,value)
            }
        };
        const handleChange = (value) => {
            this.setState({
                clientid: value
            });
            const state = this.state.st;
            fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystateclient?state=" + state + "&id=" + value)
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data;

                        for (let i = 0; i < array.length; i++) {
                            array[i].key = i + 1;
                            array[i].max = array[i].value * array[i].price;
                            array[i].href = "/pagebuscontract?id="+array[i].id;
                        }
                        this.setState({
                            isLoaded: true,
                            list: array
                        });
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Title level={5}>客户合同档案</Title>
                                <Space>
                                <Select
                                    defaultValue="请选择"
                                    style={{
                                        width: 220,
                                    }}
                                    onChange={handleChange}
                                    options={this.state.menu}
                                />
                                <Select
                                    defaultValue="未签订"
                                    style={{
                                        width: 220,
                                    }}
                                    onChange={onChange}
                                    options={stateMenu}
                                />
                                </Space>
                                <br/>
                                <br/>
                                <List
                                    pagination={{
                                        onChange: (page) => {
                                            console.log(page);
                                        },
                                        pageSize: 9,
                                    }}
                                    grid={{
                                        gutter: 16,
                                        xxl: 3,
                                    }}
                                    dataSource={this.state.list}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Card
                                                title={item.num}
                                                extra={<a href={item.href}>查看</a>}
                                                hoverable
                                            >
                                                <p>产品规格：{item.stock}</p>
                                                <p>订购数量（吨）：{item.value}</p>
                                                <p>出厂单价（元/吨）：{item.price}</p>
                                                <p>合计金额（元）：{item.max}</p>
                                                <p>买受公司：{item.client}</p>
                                                <Row>
                                                    <Col span={20}>
                                                        <p>{item.state == 4?'签订时间：'+item.signtime:''}</p>
                                                    </Col>
                                                    <Col  span={4}>
                                                        {item.state == 4?
                                                            <Title level={5} type='success'>已签订</Title>
                                                            :
                                                            item.state == 3?
                                                                <Title level={5} type='warning'>待审核</Title>
                                                                :
                                                                <Title level={5} type='danger'>未签订</Title>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageBusContracts;


