import Leftmenu from "./leftmenu";
import Header from "./header";
import Pageheader from "./pageheader";
import Homeecharts from "./homeecharts";
import {Component} from "react";

class Datamenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
            display: 'show',
            show:'width:100px',
            none:'width:50px'
        }
    }
    close(){
        this.setState({
            dispaly: '1',
        });
    }

    render() {
        return (
            <div>
            {       this.props.value&&this.state.display?
                    <div className="datamenushow">
                        <div className="datamenubutton">
                            <a href="https://datav.res.smy.shccig.com/share/db5c544bc9d485fc1dec0682baca7263">总览大屏</a>
                        </div>
                        <div className="datamenubutton">
                            <a href="https://datav.res.smy.shccig.com/share/739a5af92f4eb0bebeb263243b09dd7d">党建大屏</a>
                        </div>
                        <div className="datamenubutton">
                            <a href="/datageneral">数据概况</a>
                        </div>
                        <div className="datamenubutton">
                            <a href="/datasell">销售情况</a>
                        </div>
                        <div className="datamenubutton">
                            <a href="/dataproduction">生产系统</a>
                        </div>
                        <div className="datamenubutton">
                            <a href="/datamaterials">原料车间</a>
                        </div>
                        <div className="datamenubutton">
                            <a href="/datafiring">烧成车间</a>
                        </div>
                        <div className="datamenubutton">
                            <a href="/datamade">制成车间</a>
                        </div>
                    </div>
                    :null
            }
            </div>
        );
    }
}

export default Datamenu;
