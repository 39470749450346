import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined, WechatOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs, Popover, Popconfirm, notification
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const Return = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellcontractstate?state=2&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="退回审核"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button onClick={showPopconfirm}>
                审核退回
            </Button>
        </Popconfirm>
    );
};

const To = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellcontractstatesigntime?state=4&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="通过审核"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button onClick={showPopconfirm}>
                审核通过
            </Button>
        </Popconfirm>
    );
};

const Limit = (id) => {
    const openNotification = (description) => {
        notification.open({
            message: '限提提醒',
            description: description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };
    const [inputLimit, setInputLimit] = useState(id.x);
    const onClick = () => {
        if(inputLimit){

            setTimeout(() => {

                fetch("http://pd.pokemonbye.com:8086/api/setsellcontractx?x=0&id=" + id.id)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            openNotification('解除限提成功，此合同将恢复提货能力');
                        },
                        (error) => {
                        }
                    )
            }, 1000);
            setInputLimit(false);
        }else{

            setTimeout(() => {

                fetch("http://pd.pokemonbye.com:8086/api/setsellcontractx?x=1&id=" + id.id)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            openNotification('限提成功，此合同将无法提货，如需要恢复需手动解除');
                        },
                        (error) => {
                        }
                    )
            }, 1000);
            setInputLimit(true);
        }
    };
    return (
        <Button type="primary" size="small" onClick={onClick} danger={inputLimit}>
            限提
        </Button>
    );
};

const DecimalStep = (id) => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <>
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={20000}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={20000}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    <Button type="default" size="small"  onClick={() => handleOk(id.id,inputValue)}>
        保存
    </Button>
        </>
    );
};

const handleOk = (id,value) => {
    setTimeout(() => {

        fetch("http://pd.pokemonbye.com:8086/api/setsellcontractrprice?rprice="+value+"&id=" + id.id)
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.reload();

                },
                (error) => {
                }
            )
    }, 1000);
};
const content = (id) => (
    <>
        <Space direction="vertical"
               style={{
                   width: 300,
               }}>
        <DecimalStep id={id}/>
        </Space>
    </>
);

const Go = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
    <Popover content={() => content(id)} title="调整缴纳金额" trigger="click">
        <Button size="small">
            缴纳金额管理
        </Button>
    </Popover>
    );
};

const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '合同编号',
        dataIndex: 'num',
        key: 'num',
    },
    {
        title: '客户',
        dataIndex: 'client',
        key: 'client',
    },
    {
        title: '采购产品',
        dataIndex: 'stock',
        key: 'stock',
    },
    {
        title: '采购量（吨）',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: '单价（元/吨）',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: '金额（元）',
        dataIndex: 'max',
        key: 'max',
        render: (price) => <p>{price ? (price/1).toFixed(2) : '0.00'}</p>,
    },
    {
        title: '提报时间',
        dataIndex: 'pubtime',
        key: 'pubtime',
        render: (date) => <p>{date ? date.substring(0,19) : '0.00'}</p>,
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <To id={record.id}></To>
                <Return id={record.id}></Return>
            </Space>
        ),
    },
];

const Load = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystatedate?state=2&start=" + start + "&end=" + end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                    array[i].max = array[i].value * array[i].price;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const confirm = (e) => {
    window.open('http://booleango-stsn.oss-cn-beijing.aliyuncs.com/huayandan.jpg');
};
class PageFinContracts extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "合同审核",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystate?state=3")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;

                    for (let i = 0; i < array.length; i++) {
                        array[i].key = i + 1;
                        array[i].max = (array[i].value * array[i].price).toFixed(2);
                        array[i].href = "/pagebuscontract?id="+array[i].id;
                    }
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const handleChange = (value) => {

            let array = this.state.menu;
            var name = '';
            for (let i = 0; i < array.length; i++) {
                if(array[i].value == value){
                    name = array[i].label;
                }
            }
            this.setState({
                name: name
            });
        };
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="待审核合同"
                            >
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageFinContracts;


