import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs, Popover
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '回款单编号',
        dataIndex: 'return_num',
        key: 'return_num',
    },
    {
        title: '客户',
        dataIndex: 'client_name',
        key: 'client_name',
    },
    {
        title: '合同编号',
        dataIndex: 'contract_num',
        key: 'contract_num',
    },
    {
        title: '回款金额（元）',
        dataIndex: 'return_price',
        key: 'return_price',
    },
    {
        title: '回款时间',
        dataIndex: 'return_pubtime',
        key: 'return_pubtime',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button size="Default">
                    回款设置
                </Button>
            </Space>
        ),
    }
];

const Load = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8086/api/getsellreturndate?state=1&start="+start+"&end="+end)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                    array[i].max = array[i].value * array[i].price;
                }
                console.log(array)
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const onGo = () => {
    window.location.href="/pagefinloan"
};
class PageFinLoans extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "贷款回收",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8087/api/getsellclientdao")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].value = array[i].stockid;
                        array[i].label = array[i].stockname;

                    }
                    this.setState({
                        isLoaded: true,
                        menu: array
                    });
                    Load(this,moment().format('YYYY-MM-DD'),moment().add(1, 'day').format('YYYY-MM-DD'));
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        Load(this,moment().format('YYYY-MM-DD'), moment().add(1, "days").format('YYYY-MM-DD'))
    }

    render() {
        const handleChange = (value) => {

            let array = this.state.menu;
            var name = '';
            for (let i = 0; i < array.length; i++) {
                if(array[i].value == value){
                    name = array[i].label;
                }
            }
            this.setState({
                name: name
            });
        };
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Space>
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    options={[
                                        {
                                            value: '富平公司',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                                <RangePicker defaultValue={[moment(), moment().add(1, "days")]} onChange={onChange}/>
                                    <Select
                                        defaultValue="请选择"
                                        style={{
                                            width: 220,
                                        }}
                                        onChange={handleChange}
                                        options={this.state.menu}
                                    />
                            </Space>
                                <br/>
                                <br/>
                                <Button onClick={() => onGo()}>新增</Button>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageFinLoans;


