import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'
import moment from "moment";

class Datamadeline extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
                xAxis: {
                    type: 'category',
                    data: [moment().subtract(7,'days').format('M-D'), moment().subtract(6,'days').format('M-D'), moment().subtract(5,'days').format('M-D'), moment().subtract(4,'days').format('M-D'), moment().subtract(3,'days').format('M-D'), moment().subtract(2,'days').format('M-D'), moment().subtract(1,'days').format('M-D')]
                },
                yAxis: {
                    type: 'value',
                    axisLabel:{
                        formatter:"{value}吨"
                    }
                },
                legend: {
                    data: ['1号窑', '2号窑', '5号窑']
                },
                series: [
                    {
                        name: '1号窑',
                        data: [1849.40, 1827.80, 1761.20, 1847.20, 1870.90, 3135.91, 1501.41],
                        type: 'line'
                    },
                    {
                        name: '2号窑',
                        data: [1875.90, 1771.70, 1822.30, 1804.30, 1606.80, 1761.90, 2903.00],
                        type: 'line'
                    },
                    {
                        name: '5号窑',
                        data: [1525.00, 1023.00, 985.00, 928.00,1407.00, 1879.00, 856.00],
                        type: 'line'
                    }
                ]
            };
        this.state = {
            option: option,
            array: [],
            array2: [],
            array3: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement2?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array2: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8088/api/getpdcement5?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array3: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let array = this.state.array;
        let array2 = this.state.array2;
        let array3 = this.state.array3;
        let data = [];
        let data2 = [];
        let data3 = [];
        for (var i = 0; i < array.length; i++) {
            data[i] = array[i].chanliang * 1;
        }
        for (var i = 0; i < array2.length; i++) {
            data2[i] = array2[i].chanliang * 1;
        }
        for (var i = 0; i < array3.length; i++) {
            data3[i] = array3[i].chanliang * 1;
        }
        let option = this.state.option;

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datamadeline'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);

        myChart.setOption({
            series: [
                {
                    name: '1号窑',
                    data: data.reverse(),
                    type: 'line'
                },
                {
                    name: '2号窑',
                    data: data2.reverse(),
                    type: 'line'
                },
                {
                    name: '5号窑',
                    data: data3.reverse(),
                    type: 'line'
                }
            ]
        });
    }

    render() {
        return (
            <div id="datamadeline" className="histogramm"></div>
        )
    }
}

export default Datamadeline;
