import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Form, Switch, notification,Badge,Modal,Drawer
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined, UserOutlined} from '@ant-design/icons';
import 'moment/locale/zh-cn';
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const Clause = (array) => {
    console.log(array.array)
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Button type="link" size="large" onClick={showDrawer}>合同条款</Button>
            <Drawer title="合同条款细则" placement="right" onClose={onClose} open={open}>
                <p>
                根据《中华人民共和国民法典》相关规定，为了保证双方的合法权益，双方在平等自愿、诚实守信的基础上经过充分协商，就双方合作事宜达成如下协议：
                </p>
                <p>
                一、	产品的生产厂家、名称、型号、数量及价格（见正业）
                </p>
                <p>
                二、 质量标准及验收
                    {array.array.clause}
                </p>
                <p>
                三、交货、计量方式及单证转移
                    {array.array.delivery}
                </p>
                <p>
                四、结算方式：按第1种方式执行：
                    {array.array.settle}
                银行转账汇款信息如下：
                名称：陕西富平生态水泥有限公司
                开户行：中国农业银行西安曲江新区支行
                账号：261016931990002
                行号：103791014032
                电子承兑背书转让汇款专用账户：
                名称：陕西富平生态水泥有限公司
                开户行：陕西煤业化工集团财务有限公司
                账号：261016931990001
                行号：907791000041
                货款支付仅限银行转账、银行承兑汇票两种方式。（备注：承兑仅限六个月内的银行承兑）
                </p>
                <p>
                五、双方责任
                1、出卖方责任：
                ①出卖方保证所供产品符合第二条第1款标准，若所供产品不符合要求，依照相关法规执行。
                ②受国家政策及行业政策影响、周边环境影响、厂内设备故障及原材料紧缺情况下，出卖方无法向买受方及时安排装车的，不视为出卖方违反合同关于交货的约定。
                ③为安全生产管理，出卖方需提供必要的安全防护设施及安全警示标识，出卖方制定《提货车辆安全管理规定》并应向买受方告知，出卖方在履行告知义务后有权对买受方进厂人员及车辆按照《提货车辆安全管理规定》进行管理。
                2、买受方责任：
                ①本合同所销售产品仅限于陕西省渭南市蒲城县紫荆街道使用，未经出卖方书面同意，买受方不得将本合同所订购的产品交由其它工地使用或转售，由此造成的一切后果由买受方承担。买受方超范围使用或转售产品给出卖方造成直接或间接损失的，买受方应按照实际执行价格上浮10%-50%向出卖方结算并追加支付货款，具体上浮比例由双方自行商定，同时出卖方有权因买受方的超范围使用或转售行为提前单方终止合同。如买受方追加支付的货款不足以弥补给出卖方造成的损失，出卖方有权要求买受方增加实际结算价格的上浮比例，以完全弥补出卖方损失为限。
                ②买受方须遵守出卖方安全管理制度，做好买受方进厂人员及司乘人员的安全宣传及教育工作。买受方应向提货的司乘人员及时宣告出卖方的《提货车辆安全管理规定》，买受方车辆及司乘人员进入厂区提货，应当遵守出卖方关于《提货车辆安全管理规定》，否则，按该规定处理。
                ③买受方在履行合同过程中，必须符合交通运输安全及环境保护相关要求，杜绝车辆与人员带病、带隐患、违章作业。买受方运输车辆、驾驶员、作业人员违反本合同约定发生各类人身、财产安全事故，均由买受方自行负责。买受方所有运输车辆应满足出卖方或提货厂区的环保要求，罐装车密封设备完好，箱式敞车全程覆盖篷布，车辆排放标准应符合运输沿线地方政府以及出卖方或提货厂区的要求。如买受方车辆不符合安全、环保运输要求或提供虚假车辆环保排放资料以获取运输资格的，一经发现出卖方有权停止供货，给出卖方造成损失的还应承担赔偿责任。
                ④买受方任何车辆在厂区内作业，造成对出卖方、买受方人员安全事故、设备设施的财产损失或对第三方造成损失的，责任由买受方承担。
                ⑤买受方采用本合同第四条第1款之外的结算方式时，买受方未按合同约定时间及时偿还货款造成逾期，出卖方有权停止供货；逾期超过10日未付款时，从逾期之日起每逾期一日按未偿还货款的4倍LPR向出卖方支付违约金，直至买受方偿还所欠全部款项。买受方违反本合同约定，应向出卖方赔偿全部损失，包括但不限于出卖方或承运方为追回或减少损失而产生的律师费、诉讼费、差旅费、仲裁费、鉴定费、保全申请费、保全保险费等实际产生费用。双方对买受方所欠货款逾期责任另有约定或买受方单独向出卖方出具过一次或多次付款承诺或还款承诺的，买受方的逾期付款责任按照买受方承担责任最重的约定或承诺执行。
                3、共同责任
                合同双方应严守有关商业秘密（如合同单价、数量等），如因泄密给合同相对方造成损失，由责任方承担因此给受害方造成的一切损失，且受害方有权终止合同。
                </p>
                <p>
                六、免责条款
                在本合同履行期间，因自然灾害、政府行为等具有不可预见性的原因，导致合同无法继续履行的，买卖双方均不负赔偿责任。
                </p>
                <p>
                七、合同争议的解决方式：
                本合同在履行过程中发生的争议，由双方当事人协商解决；协商调解不成的，依法向出卖方所在地人民法院起诉。
                </p>
                <p>
                八、其它约定：
                买卖双方应于每次发票交接时签署发票交接手续，每月对双方账面余额进行核对并签署对账单，在每年12月31日前应将双方全年业务往来账目进行核对并签署对账单，对账单上应列明全年的交货数量、货物总价值、已付款金额及未付款金额，做到款货两清。
                </p>
                <p>
                九、本合同如有未尽事宜，双方另行协商签订补充协议，在本合同签订前双方其它交易资料与本合同有冲突的以本协议为准（特别说明以相关交易资料为准的除外），补充协议与本合同具有同等法律效力。
                </p>
                <p>
                十、买卖双方一致确认本合同所列明的联系人、联系电话、电子邮箱、通讯地址等为双方履行合同、解决合同争议时接受相对方通知或法院诉讼文书的地址和联系方式。所有联系方式适用至本合同履行完毕或争议经诉讼至案件执行终结时止。买卖双方任意一方按照下列任意一种方式向相对方发送文件、文书即视为送达。买卖双方均承诺合同约定的联系方式真实有效。如任一联系方式发生变更在1个工作日内通知相对方，如因预留联系方式错误或变更后未及时通知相对方导致的己方的商业信件和诉讼文书不能送达的，法律后果自行承担。
                </p>
                <p>
                十一、本合同一式八份，出卖方六份，买受方二份，具有同等法律效力。
                </p>
                <p>
                十二、本合同自双方签字盖章之日生效。
                </p>

            </Drawer>
        </>
    );
};
const DecimalStep = (id) => {

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('是否提交此合同进行审核，审核后合同视为生效，不可撤回，请谨慎操作。');

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellcontractstatesigntime?state=3&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    return (
        <>
        <Button type="primary" size="large" onClick={showModal}>提交审核</Button>
            <Modal
                title="提交审核"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >

                <p>{modalText}</p>
            </Modal>
        </>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};

const openNotification = () => {
    notification.open({
        message: '提交成功',
        description:
            '档案修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};

const confirm = (e) => {
    window.open('http://booleango-stsn.oss-cn-beijing.aliyuncs.com/hetong.docx');
};

function numberParseChina(money) {
    //汉字的数字
    var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    //基本单位
    var cnIntRadice = ['', '拾', '佰', '仟'];
    //对应整数部分扩展单位
    var cnIntUnits = ['', '万', '亿', '兆'];
    //对应小数部分单位
    var cnDecUnits = ['角', '分', '毫', '厘'];
    //整数金额时后面跟的字符
    var cnInteger = '整';
    //整型完以后的单位
    var cnIntLast = '圆';
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = '';
    //分离金额后用的数组，预定义
    var parts;
    if (money == '') {
        return '';
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return '';
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
            var n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '') {
        chineseStr += cnInteger;
    }
    return chineseStr;
}
class PageBusContract extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        this.state = {
            title: "合同管理",
            county: [],
            id: id,
            array: {},
            data: {}
        }
    }

    componentDidMount() {  // 阿拉伯数字转换大写

        let id = this.state.id
        fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbyid?id=" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.data!=null&&result.data.length>0){
                        let array = result.data;
                        for (let i = 0; i < array.length; i++) {
                            array[i].max = (array[i].value * array[i].price).toFixed(2);
                            array[i].valuemax = numberParseChina(array[i].value * array[i].price);
                        }
                        this.setState({
                            isLoaded: true,
                            array: array[0]
                        });
                    }
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch("http://pd.pokemonbye.com:8086/api/getsellsysdata")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    if(array!=null&&array.length>0){
                        this.setState({
                            isLoaded: true,
                            data: array[0]
                        });
                    }
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    formRef = React.createRef();
    render() {
        const onFinish = (values) => {
            var list = {};
            list.name = values.name ? values.name : this.state.list.name;
            list.clientid = values.clientid ? values.clientid : this.state.list.clientid;
            list.num = values.num ? values.num : this.state.list.num;
            list.address = values.address ? values.address : this.state.list.address;
            list.tel = values.tel ? values.tel : this.state.list.tel;
            list.bank = values.bank ? values.bank : this.state.list.bank;
            list.banknum = values.banknum ? values.banknum : this.state.list.banknum;
            list.state = values.state ? '2' : '1';
            list.pubtime = values.pubtime ? values.pubtime : this.state.list.pubtime;

            var id = this.state.id
            fetch("https://pd.pokemonbye.com:8086/api/setsellclient?name="+list.name+"&address="+list.address+"&tel="+list.tel+"&num="+list.bank+"&bank="+list.bank+"&banknum="+list.banknum+"&state="+list.state+"&clientid="+list.clientid+"&id=" + id)
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data[0];
                        this.setState({
                            isLoaded: true,
                            list: array
                        });
                        openNotification();

                        //setTimeout('window.location.reload()',1000);
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >

                                <Row>
                                    <Col span={20}>
                                        <Space size="large">
                                            <Title level={4}>客户合同档案</Title>
                                            {this.state.array.state == 4?
                                                <>
                                                <Title level={4} type='success'>已签订</Title>
                                                </>
                                                :
                                                this.state.array.state == 3?
                                                    <>
                                                        <Title level={4} type='warning'>待审核</Title>
                                                    </>
                                                    :
                                                    <>
                                                        <Title level={4} type='danger'>未签订</Title>
                                                        <DecimalStep id={this.state.id}/>
                                                    </>
                                            }
                                        </Space>
                                    </Col>
                                    <Col span={4}>
                                        <Space size="large">
                                            <Button size="large" onClick={confirm}>纸质版合同</Button>
                                            <Clause array={this.state.array}/>
                                        </Space>
                                    </Col>
                                </Row>
                                <br/>
                                <br/>
                                <Descriptions bordered>
                                    <Descriptions.Item label="出卖方" span={2}>{this.state.data.sysdata_name}</Descriptions.Item>
                                    <Descriptions.Item label="签订地点">{this.state.data.sysdata_address}</Descriptions.Item>
                                    <Descriptions.Item label="买受方" span={2}>{this.state.array.client}</Descriptions.Item>
                                    <Descriptions.Item label="签订时间">{this.state.array.signtime}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="生产厂家">{this.state.data.sysdata_plant}</Descriptions.Item>
                                    <Descriptions.Item label="商标">{this.state.data.sysdata_brand}</Descriptions.Item>
                                    <Descriptions.Item label="产品规格">
                                        {this.state.array.stock}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="订购数量（吨）">
                                        {this.state.array.value}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="出厂单价（元/吨）">
                                        {this.state.array.price}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="合计金额（元）">
                                        {this.state.array.max}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="合计总额大写" span={3}>
                                        人民币：{this.state.array.valuemax}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="备注" span={3}>
                                        <p>1、本合同出厂单价价格为含税单价，出厂单价价格随行就市，若市场产品价格或出卖方销售价格发生重大波动，以出卖方正式的《调价函》或短信、微信通知到买受人被授权手机号、微信号为准结算，买受方收到前述价格调整通知后仍继续提货视为同意出卖方的调价结果；如买受方不接受出卖方价格调整，双方可提前终止合同。</p>
                                        <p>2、出卖方向买受方提供税率为13%的增值税专用发票，发票不作为买受方已付款凭证。在合同执行期间，如遇国家税收政策调整或税率变更，依据税收政策执行，税率调整不影响本合同约定出厂价格。</p>
                                    </Descriptions.Item>
                                </Descriptions>

                                <Row>
                                    <Col span={12}>
                                        <div className="site-card-border-less-wrapper">
                                            <Card
                                                title="出卖方"
                                                bordered
                                                type="inner"
                                            >
                                                <p>出卖方(章)：{this.state.data.sysdata_name}</p>
                                                <p>住所：{this.state.data.sysdata_address}</p>
                                                <p>法定代表人或授权代表人：{this.state.data.sysdata_legal}</p>
                                                <p>经办人：</p>
                                                <p>电话：{this.state.data.sysdata_tel}</p>
                                                <p>开户银行：{this.state.data.sysdata_bank}</p>
                                                <p>账号：{this.state.data.sysdata_banknum}</p>
                                                <p>户名：{this.state.data.sysdata_bankname}</p>
                                                <Row>
                                                    <Col span={8} offset={16}>
                                                        <p>签订日期：{this.state.array.signtime}</p>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <Card
                                            title="买受方"
                                            bordered
                                            type="inner"
                                        >
                                            <p>买受方(章)：{this.state.array.client}</p>
                                            <p>住所：{this.state.array.caddress}</p>
                                            <p>法定代表人或授权代表人：{this.state.array.clegal}</p>
                                            <p>经办人：{this.state.array.name}</p>
                                            <p>电话：{this.state.array.tel}</p>
                                            <p>开户银行：{this.state.array.cbank}</p>
                                            <p>账号：{this.state.array.cbanknum}</p>
                                            <p>户名：{this.state.array.cbankname}</p>
                                            <Row>
                                                <Col span={8} offset={16}>
                                                    <p>签订日期：{this.state.array.signtime}</p>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageBusContract;


