import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Card,
    List, Avatar, Form, Switch, notification
} from 'antd';
import 'moment/locale/zh-cn';

const {Title, Text} = Typography;
const {Content} = Layout;
const { Meta } = Card;

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const openNotification = () => {
    notification.open({
        message: '提交成功',
        description:
            '档案修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};

class PageSellMan extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "人员管理",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
        fetch("https://pd.pokemonbye.com:8086/api/getsellmanall")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].href = "/pagesellman?id="+array[i].id;
                    }
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    formRef = React.createRef();
    render() {
        const onFinish = (values) => {
            var list = {};
            list.name = values.name ? values.name : this.state.list.name;
            list.clientid = values.clientid ? values.clientid : this.state.list.clientid;
            list.num = values.num ? values.num : this.state.list.num;
            list.address = values.address ? values.address : this.state.list.address;
            list.tel = values.tel ? values.tel : this.state.list.tel;
            list.bank = values.bank ? values.bank : this.state.list.bank;
            list.banknum = values.banknum ? values.banknum : this.state.list.banknum;
            list.state = values.state ? '2' : '1';
            list.pubtime = values.pubtime ? values.pubtime : this.state.list.pubtime;

            var id = this.state.id
            fetch("https://pd.pokemonbye.com:8086/api/setsellclient?name="+list.name+"&address="+list.address+"&tel="+list.tel+"&num="+list.bank+"&bank="+list.bank+"&banknum="+list.banknum+"&state="+list.state+"&clientid="+list.clientid+"&id=" + id)
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data[0];
                        this.setState({
                            isLoaded: true,
                            list: array
                        });
                        openNotification();

                        //setTimeout('window.location.reload()',1000);
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="销售人员信息档案"
                            >
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 10,
                                    }}
                                    layout="horizontal"
                                    size="Large"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Form.Item label="姓名" name="name">
                                        <Input placeholder={this.state.list.name}/>
                                    </Form.Item>
                                    <Form.Item label="编号" name="clientid">
                                        <Input placeholder={this.state.list.clientid}/>
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 4,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">提交修改</Button>
                                    </Form.Item>
                                </Form>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellMan;


