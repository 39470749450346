import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs, Popover, Modal
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;



const handleOk = (id,value) => {
    setTimeout(() => {

        fetch("http://pd.pokemonbye.com:8086/api/setsellclientcredit?credit="+value+"&id=" + id.id)
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.reload();

                },
                (error) => {
                }
            )
    }, 1000);
};
const DecimalStep = (id) => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <>
            <p>信用度过低会影响提货（8以下进行提醒，6以下禁止提货）</p>
            <Row>
                <Col span={12}>
                    <Slider
                        min={0}
                        max={10}
                        onChange={onChange}
                        value={typeof inputValue === 'number' ? inputValue : 0}
                        step={1}
                    />
                </Col>
                <Col span={4}>
                    <InputNumber
                        min={0}
                        max={10}
                        style={{
                            margin: '0 16px',
                        }}
                        step={1}
                        value={inputValue}
                        onChange={onChange}
                    />
                </Col>
            </Row>
            <Button type="default" size="small"  onClick={() => handleOk(id.id,inputValue)}>
                保存
            </Button>
        </>
    );
};
const content = (id) => (
    <>
        <Space direction="vertical"
               style={{
                   width: 300,
               }}>
            <DecimalStep id={id}/>
        </Space>
    </>
);
const Go = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popover content={() => content(id)} title="调整信用度" trigger="click">
            <Button size="small">
                信用度管理
            </Button>
        </Popover>
    );
};

const App = (value) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent] = useState('查询中');
    const [content2, setContent2] = useState('');
    const showModal = () => {
        setIsModalOpen(true);
        setTimeout(() => {

            fetch("http://pd.pokemonbye.com:8086/api/getsellcontractsum?id=" + value.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.data!=null&&result.data.length>0&&result.data[0].value!=null){

                            setContent('总贷款金额：'+result.data[0].value+'￥')
                        }else{

                            setContent('总贷款金额：无记录')
                        }
                    },
                    (error) => {
                    }
                )
            fetch("http://pd.pokemonbye.com:8086/api/getsellcontractreturnsum?id=" + value.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.data!=null&&result.data.length>0&&result.data[0].value!=null){

                            setContent2('回款额：'+result.data[0].value+'￥')
                        }else{

                            setContent('回款额：无记录')
                        }
                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                查询
            </Button>
            <Modal title="贷款额度" open={isModalOpen}
                   footer={[
                       <Button key="back" onClick={handleCancel}>
                           关闭
                       </Button>
                   ]}>
                <p>{content}</p>
                <p>{content2}</p>
            </Modal>
        </>
    );
};

const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '客户编号',
        dataIndex: 'clientid',
        key: 'clientid',
    },
    {
        title: '客户名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '地址',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '联系方式',
        dataIndex: 'tel',
        key: 'tel',
    },
    {
        title: '加入日期',
        dataIndex: 'pubtime',
        key: 'pubtime',
        render: (pubtime) => <p>{pubtime.substring(0,10)}</p>,
    },
    {
        title: '信用度（0-10）',
        dataIndex: 'credit',
        key: 'credit',
    },
    {
        title: '贷款额度',
        dataIndex: 'truck',
        key: 'truck',
        render: (_, record) => (
            <Space size="middle">
                <App id={record.name}>查看贷款额度</App>
            </Space>
        ),
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Go id={record.id}>信用度管理</Go>
            </Space>
        ),
    },
];

const Load = (that,start,end) => {

    fetch("http://pd.pokemonbye.com:8086/api/getsellclient")
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;
                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class PageFinCredits extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "信用管理",
            county: [],
            area: pid,
            list: [],
            listman: [],
            listcus: [],
        };
    }

    componentDidMount() {
        Load(this,moment().format('YYYY-MM-DD'), moment().add(1, "days").format('YYYY-MM-DD'))
    }

    render() {
        const onChange = (date, dateString) => {
            console.log(date, dateString);
            Load(this,dateString[0], dateString[1])
        };
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="信用度及贷款限制设置"
                            >
                                <Space>
                                <Select
                                    defaultValue="富平公司"
                                    style={{
                                        width: 120,
                                    }}
                                    options={[
                                        {
                                            value: '富平公司',
                                            label: '富平公司',
                                        }
                                    ]}
                                />
                            </Space>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageFinCredits;


