import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Card,
    List,
    Popconfirm, Form, Switch, notification, Select
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";

const {Title, Text} = Typography;
const {Content} = Layout;

const Delete = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellstockstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        console.log('删除后将不可恢复');
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button onClick={showPopconfirm}>
                删除
            </Button>
        </Popconfirm>
    );
};
const onChange = (checked) => {
    console.log(`switch to ${checked}`);/*
    var list = this.state.list
    list.type = checked
    this.setState({
        isLoaded: true,
        list: list
    });*/
};
const openNotification = () => {
    notification.open({
        message: '提交成功',
        description:
            '产品信息修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};

class PageSellStock extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        this.state = {
            title: "产品编辑",
            county: [],
            id: id,
            list: [],
            options: []
        };
    }

    componentDidMount() {
        var id = this.state.id
        if(id){
        fetch("http://pd.pokemonbye.com:8086/api/getsellstockbyid?id=" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.data) {
                        let array = result.data[0];
                        this.setState({
                            isLoaded: true,
                            list: array
                        });
                        this.formRef.current.setFieldsValue(array)
                    }

                    fetch("http://pd.pokemonbye.com:8086/api/getsellstocktype")
                        .then(res => res.json())
                        .then(
                            (result) => {
                                let array = result.data;
                                let list = this.state.list;
                                var options = [];
                                var type = 1;
                                for (var i = 0; i < array.length; i++) {
                                    options.push(
                                        {
                                            value: array[i].id,
                                            label: array[i].type,
                                        }
                                    )/*
                                    if(array[i].type == list.type){
                                        type = array[i].id
                                    }*/
                                };
                                this.setState({
                                    isLoaded: true,
                                    options: options,
                                    type: type
                                });
                            },
                            // 注意：需要在此处处理错误
                            // 而不是使用 catch() 去捕获错误
                            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        }else{

            fetch("http://pd.pokemonbye.com:8086/api/getsellstocktype")
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data;
                        let list = this.state.list;
                        var options = [];
                        var type = 1;
                        for (var i = 0; i < array.length; i++) {
                            options.push(
                                {
                                    value: array[i].id,
                                    label: array[i].type,
                                }
                            )/*
                                    if(array[i].type == list.type){
                                        type = array[i].id
                                    }*/
                        };
                        this.setState({
                            isLoaded: true,
                            options: options,
                            type: type
                        });
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }
    formRef = React.createRef();
    render() {
        const onFinish = (values) => {
            var list = {};
            list.stockid = values.stockid ? values.stockid : this.state.list.stockid;
            list.stockname = values.stockname ? values.stockname : this.state.list.stockname;
            list.stockprice = values.stockprice ? values.stockprice : this.state.list.stockprice;
            list.stocknum = values.stocknum ? values.stocknum : this.state.list.stocknum;
            list.type = values.type ? values.type : this.state.type;
            list.time = moment().format('YYYY-MM-DD HH:mm:ss') ;
            console.log(list)
            if(this.state.id){
            list.id = this.state.id;
            fetch("http://pd.pokemonbye.com:8086/api/setsellstock?stockid="+list.stockid+"&stockname="+list.stockname+"&stocknum="+list.stocknum+"&stockprice="+list.stockprice+"&type="+list.type+"&pubtime="+list.time+"&id=" + list.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        openNotification();

                        setTimeout(window.location.href="/pagesellstocks",1000);
                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
            }else{

                fetch("http://pd.pokemonbye.com:8086/api/insertsellstock?stockid="+list.stockid+"&stockname="+list.stockname+"&stocknum="+list.stocknum+"&stockprice="+list.stockprice+"&type="+list.type+"&state=1"+"&pubtime="+list.time)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            openNotification();

                            setTimeout(window.location.href="/pagesellstocks",1000);
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 6,
                                    }}
                                    layout="horizontal"
                                    size="Large"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Form.Item label="产品编号" name="stockid">
                                        <Input disabled={false} bordered={false} placeholder={this.state.list.stockid}/>
                                    </Form.Item>
                                    <Form.Item label="产品名称" name="stockname">
                                        <Input placeholder={this.state.list.stockname}/>
                                    </Form.Item>
                                    <Form.Item label="类型" name="type">
                                        <Select
                                            defaultValue={this.state.list.type}
                                            style={{
                                                width: 120,
                                            }}
                                            options={this.state.options}
                                        />
                                    </Form.Item>
                                    <Form.Item label="一卡通编号" name="stocknum">
                                        <Input placeholder={this.state.list.stocknum}/>
                                    </Form.Item>
                                    <Form.Item label="产品价格" name="stockprice">
                                        <InputNumber addonAfter="元/吨" placeholder={this.state.list.stockprice} />
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 4,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">{this.state.id?'提交修改':'新建'}</Button>
                                    </Form.Item>
                                </Form>

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageSellStock;


