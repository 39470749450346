import React, {Component} from 'react';
import * as echarts from 'echarts';
import geo from '../json/xian.json'

class Datatitlemonth extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            series: [
                {
                    type: 'gauge',
                    center: ['50%', '60%'],
                    startAngle: 200,
                    endAngle: -20,
                    min: 0,
                    max: 30.00,
                    splitNumber: 10,
                    itemStyle: {
                        color: '#396cc7'
                    },
                    progress: {
                        show: true,
                        width: 10
                    },
                    pointer: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10
                        }
                    },
                    axisTick: {
                        distance: -20,
                        splitNumber: 3,
                        lineStyle: {
                            width: 2,
                            color: '#999'
                        }
                    },
                    splitLine: {
                        distance: -25,
                        length: 5,
                        lineStyle: {
                            width: 2,
                            color: '#999'
                        }
                    },
                    axisLabel: {
                        distance: -20,
                        color: '#999',
                        fontSize: 10
                    },
                    detail: {
                        valueAnimation: true,
                        width: '60%',
                        lineHeight: 40,
                        borderRadius: 8,
                        offsetCenter: [0, '-15%'],
                        fontSize: 20,
                        fontWeight: 'bolder',
                        formatter: '{value}万吨',
                        color: 'inherit'
                    },
                    data: [
                        {
                            value: 0.00
                        }
                    ]
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8087/api/getsellmonth")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option;
        let array = this.state.array;
        let sell = 0;

        for (var i = 0; i < array.length; i++) {

            if(array[i].stockid!="gaomeifeishi"&&array[i].stockid!="kuangfen95"&&array[i].stockid!="kuangfen95wg"&&array[i].stockid!="fuhekuangfen"){
                sell += parseFloat(array[i].sum) / 10000;
            }
        }
        const data = [
            {
                value: sell.toFixed(2)
            }
        ];

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datatitlemonth'),'dark');
        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);
        myChart.setOption({
            series: [{
                data
            }
            ]
        });
    }

    render() {
        return (
            <div id="datatitlemonth" className="histogramtitle"></div>
        )
    }
}

export default Datatitlemonth;
