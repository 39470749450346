import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm,
    Form,
    Radio,
    Switch,
    Cascader,
    TreeSelect,
    notification,
    message
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;
const openNotification = () => {
    notification.open({
        message: '提交成功',
        description:
            '档案修改成功',
        onClick: () => {
            console.log('提交成功');
        },
    });
};
const info = () => {
    message.info('This is a normal message');
};
class PageFinLoan extends Component{

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        const level = params.get("level");
        let source = '';
        switch (level){
            case "1":
                source = "管理员"
                break;
            case "2":
                source = "业务员"
                break;
            case "3":
                source = "司机"
                break;
        }
        this.state = {
            title: "新建回款单",
            source: source,
            county: [],
            id: id,
            level: level,
            list: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getsellcontractbystate?state=2")
            .then(res => res.json())
            .then(
                (result) => {
                    let options = [];
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        let l = {
                            value: array[i].id,
                            label: array[i].num
                        }
                        options.push(l)
                        array[i].id = i + 1
                    }
                    this.setState({
                        isLoaded: true,
                        menulist: array,
                        options: options
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        var id = this.state.id
        if(id) {
            fetch("http://pd.pokemonbye.com:8086/api/getsellclientwxbyid?id=" + id)
                .then(res => res.json())
                .then(
                    (result) => {
                        let array = result.data[0];
                        this.setState({
                            isLoaded: true,
                            list: array
                        });
                        this.formRef.current.setFieldsValue(array)

                    },
                    // 注意：需要在此处处理错误
                    // 而不是使用 catch() 去捕获错误
                    // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }
    formRef = React.createRef();
    render() {
        const handleChange = (value) => {
            console.log(value)
            this.setState({
                client: value
            });
        };
        const onFinish = (values) => {
            console.log(this.state.list)
            var list = {};
            list.return_price = values.return_price ? values.return_price : this.state.list.return_price;
            list.return_contract = values.return_contract ? values.return_contract : this.state.list.return_contract;

            var id = this.state.id
            if(this.state.id){
                fetch("http://pd.pokemonbye.com:8086/api/setsellclientwxbyid?&client="+list.clientid+"&level="+list.level+"&name="+list.name+"&tel="+list.tel+"&id="+id)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let array = result.data[0];
                            this.setState({
                                isLoaded: true,
                                list: array
                            });
                            openNotification();

                            setTimeout(window.location.href="/pagepurrecords",1000);
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }else{
                fetch("http://pd.pokemonbye.com:8086/api/insertsellreturn?&return_contract="+list.return_contract+"&return_price="+list.return_price)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let array = result.data[0];
                            this.setState({
                                isLoaded: true,
                                list: array
                            });
                            openNotification();

                            setTimeout(window.location.href="/pagefinloans",1000);
                        },
                        // 注意：需要在此处处理错误
                        // 而不是使用 catch() 去捕获错误
                        // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 10,
                                    }}
                                    layout="horizontal"
                                    size="Large"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Form.Item label="回款金额" name="return_price">
                                        <InputNumber
                                            style={{
                                                width: 220,
                                            }}
                                            placeholder={this.state.list.return_price}/>
                                    </Form.Item>
                                    <Form.Item label="选择合同" name="return_contract">
                                        <Select
                                            defaultValue={this.state.list.return_contract}
                                            style={{
                                                width: 220,
                                            }}
                                            options={this.state.options}
                                            onChange={handleChange}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 4,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">{this.state.id?'提交修改':'新建'}</Button>
                                    </Form.Item>
                                </Form>

                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageFinLoan;


