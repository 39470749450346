import React, {Component} from 'react';
import * as echarts from 'echarts';
import moment from "moment";

class Datalinebottom extends Component {
    constructor(props) {
        super(props);
        // 绘制图表
        let option = {
            backgroundColor:'rgba(128, 128, 128, 0)',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['万吨熟料净发电量', '万吨熟料总发电量', '余热净发电量', '余热总发电量']
            },
            xAxis: {
                type: 'category',
                data: [moment().subtract(7,'days').format('M-D'),moment().subtract(6,'days').format('M-D'), moment().subtract(5,'days').format('M-D'), moment().subtract(4,'days').format('M-D'), moment().subtract(3,'days').format('M-D'), moment().subtract(2,'days').format('M-D'), moment().subtract(1,'days').format('M-D')],
            },
            yAxis: {
                type: 'value',
                min: 100000,
                max: 400000,
                axisLabel:{
                    formatter:"{value}千瓦"
                }
            },
            series: [
                {
                    name: '万吨熟料净发电量',
                    type: 'line',
                },
                {
                    name: '万吨熟料总发电量',
                    type: 'line',
                },
                {
                    name: '余热净发电量',
                    type: 'line',
                },
                {
                    name: '余热总发电量',
                    type: 'line',
                }
            ]
        };
        this.state = {
            option: option,
            array: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8088/api/getpdpower?code=%E6%97%A5")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    this.setState({
                        isLoaded: true,
                        array: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let option = this.state.option
        let array = this.state.array;
        let data = [];
        let data2 = [];
        let data3 = [];
        let data4 = [];
        for (var i = 0; i < array.length; i++) {
            data[i] = (array[i].dunshuliaojingfadian * 10000).toFixed(2);
            data2[i] = (array[i].dunshuliaozongfadian * 10000).toFixed(2);
            data3[i] = (array[i].yurejingfadian * 1).toFixed(2);
            data4[i] = (array[i].yurezongfadian * 1).toFixed(2);
        }

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('datalinebottom'),'dark');

        //点击事件
        myChart.on('click', function (params) {
            //window.open('/list?area=' + encodeURIComponent(params.data.id), '_self');
        });
        myChart.setOption((option));
        option && myChart.setOption(option);

        myChart.setOption({
            series: [
                {
                    name: '万吨熟料净发电量',
                    type: 'line',
                    data: data.reverse()
                },
                {
                    name: '万吨熟料总发电量',
                    type: 'line',
                    data: data2.reverse()
                },
                {
                    name: '余热净发电量',
                    type: 'line',
                    data: data3.reverse()
                },
                {
                    name: '余热总发电量',
                    type: 'line',
                    data: data4.reverse()
                }
            ]
        });
    }

    render() {
        return (
            <div id="datalinebottom" className="histogrambottom"></div>
        )
    }
}

export default Datalinebottom;
