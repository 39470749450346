import Leftmenu from '../element/leftmenu';
import Header from '../element/header';
import Pageheader from "../element/pageheader";
import Pagelist from '../element/pagelist';
import Pagefilter from "../element/pagefilter";
import React, { Component } from "react";

class List extends Component{
    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
        fetch("http://ad.pokemonbye.com/api/getxian")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    let keyMap = {
                        "name": "value"
                    };
                    for (var i = 0; i < array.length; i++) {
                        var obj = array[i];
                        for (var key in obj) {
                            var newKey = keyMap[key];
                            if (newKey) {
                                obj[newKey] = obj[key];
                                delete obj[key];
                            }
                        }
                    };
                    this.setState({
                        isLoaded: true,
                        county: array
                    });
                    let listUrl = this.state.area !=null ? "http://ad.pokemonbye.com/api/getelevator?area="+this.state.area : "http://ad.pokemonbye.com/api/getelevator";
                    console.log(listUrl)
                    fetch(listUrl,{
                    })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    isLoaded: true,
                                    list: result.data
                                });
                            },
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Leftmenu/>
                <div className='mainpanel'>
                    <Header/>
                    <Pageheader value={this.state}/>
                    <div className='contentpanel'>
                        <Pagefilter value={this.state}/>
                        <Pagelist  value={this.state}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default List;
