import React, {Component,useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    List,
    Card,
    Avatar,
    Popover,
    Select,
    Popconfirm, Table, Tag, Modal, Form, Switch
} from 'antd';
import { EditOutlined, DeleteOutlined, WechatOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import moment from "moment";
const { Title,Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '账号',
        dataIndex: 'num',
        key: 'num',
    },
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '联系方式',
        dataIndex: 'tel',
        key: 'tel',
    },
    {
        title: '角色',
        dataIndex: 'lname',
        key: 'lname',
    },
    {
        title: '企业名称',
        dataIndex: 'cname',
        key: 'cname',
    },
    {
        title: '创建时间',
        dataIndex: 'pubtime',
        key: 'pubtime',
    },
    {
        title: '功能',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button type="default" size="small" onClick={() => onGo(record.id)}>
                    管理
                </Button>
                <Delete id={record.id}></Delete>
            </Space>
        ),
    },
];

const content = (
    <>
        <Space direction="vertical">
            <Select
        defaultValue="lucy"
        style={{
            width: 200,
        }}
        options={[
            {
                label: '未绑定',
                options: [
                    {
                        label: '陕西长城建筑制品有限公司',
                        value: 'jack',
                    },
                    {
                        label: '陕西龙腾世纪实业发展有限公司',
                        value: 'lucy',
                    },
                ],
            },
            {
                label: '已绑定',
                options: [
                    {
                        label: '渭南市宏建混凝土搅拌有限责任公司',
                        value: 'Yiminghe',
                    },
                ],
            },
        ]}
    />
<Button type="default" size="small">
    保存
</Button>
        </Space>
    </>
);

const ADD = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                新建账号
            </Button>
            <Modal
                open={open}
                title="账号新建"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        关闭
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        新建
                    </Button>,
                ]}
            >
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    layout="horizontal"
                    size="Large"
                >
                    <Form.Item label="客户编号">
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

const Load = (that,id) => {
    fetch("http://pd.pokemonbye.com:8086/api/getsellclientwxbyclient?client=" + id)
        .then(res => res.json())
        .then(
            (result) => {
                let array = result.data;

                for (let i = 0; i < array.length; i++) {
                    array[i].key = i + 1;
                }
                that.setState({
                    isLoaded: true,
                    list: array
                });
            },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                that.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

const Delete = (id) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);

            fetch("http://pd.pokemonbye.com:8086/api/setsellclientwxstate?state=0&id=" + id.id)
                .then(res => res.json())
                .then(
                    (result) => {
                        window.location.reload()

                    },
                    (error) => {
                    }
                )
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <Popconfirm
            title="删除后将不可恢复"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{
                loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
            <Button type="default" size="small" onClick={showPopconfirm}>
                删除
            </Button>
        </Popconfirm>
    );
};
const onGo = (id) => {
    window.location.href="/pagepurrecord?id="+id
};
const onGo1 = () => {
    window.location.href="/pagepurrecord?level=1"
};
const onGo2 = () => {
    window.location.href="/pagepurrecord?level=2"
};
const onGo3 = () => {
    window.location.href="/pagepurrecord?level=3"
};

const newcontent = (
    <div>
        <Space>
        <Button onClick={() => onGo1()}>管理员</Button>
        <Button onClick={() => onGo2()}>业务员</Button>
        <Button onClick={() => onGo3()}>司机</Button>
        </Space>
    </div>
);
const New = () => (
    <Popover content={newcontent} title="选择账号类型" trigger="click" placement="bottomLeft">
        <Button type="primary">新建账号</Button>
    </Popover>
);

class PagePurRecords extends Component{


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "客户账号资料",
            county: [],
            area: pid,
            list: [],
            menu: []
        };
    }

    componentDidMount() {
        fetch("http://pd.pokemonbye.com:8086/api/getsellclientlist")
            .then(res => res.json())
            .then(
                (result) => {
                    let options = [];
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        options.push(
                            {
                                value: array[i].id,
                                label: array[i].name
                            }
                        )
                    }
                    this.setState({
                        isLoaded: true,
                        options: options
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        fetch("http://pd.pokemonbye.com:8086/api/getsellclientwx")
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data;
                    for (let i = 0; i < array.length; i++) {
                        array[i].key = i + 1
                    }
                    this.setState({
                        isLoaded: true,
                        list: array
                    });
                },
                // 注意：需要在此处处理错误
                // 而不是使用 catch() 去捕获错误
                // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const handleChange = (value) => {
            Load(this,value)
        };
        return (
            <div>
                <Leftmenu />
                <div className="mainpanel">
                    <Header />
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                            >
                                <Space>
                                <New></New>
                                <Select
                                    defaultValue="请选择"
                                    style={{
                                        width: 220,
                                    }}
                                    onChange={handleChange}
                                    options={this.state.options}
                                />
                                    </Space>
                                <br/>
                                <br/>
                                <Table bordered={true} columns={columns} dataSource={this.state.list}/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PagePurRecords;


