import React, {Component, useState} from "react";
import Leftmenu from "../element/leftmenu";
import Header from "../element/header";
import PageBreadcrumb from "../element/pagebreadcrumb";
import {DownloadOutlined} from "@ant-design/icons";
import {
    Layout,
    Col,
    Row,
    PageHeader,
    Button,
    Descriptions,
    Typography,
    InputNumber,
    Slider,
    Input,
    Space,
    DatePicker,
    Select,
    Table,
    Tag,
    Radio,
    Tabs
} from 'antd';
import 'moment/locale/zh-cn';
import moment from "moment";
import Datasellbottom from "../element/datasellbottom";
import Datasellstaff from "../element/datasellstaff";
import Datasellonyear from "../element/datasellonyear";
import Datasellonmonth from "../element/datasellonmonth";

const {Title, Text} = Typography;
const {Content} = Layout;
const {RangePicker} = DatePicker;
const { TabPane } = Tabs;

const DecimalStep = () => {
    const [inputValue, setInputValue] = useState(0);
    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
    };
    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={10}
                    onChange={onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    step={0.01}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={10}
                    style={{
                        margin: '0 16px',
                    }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const handleChange = (value) => {
    console.log(`selected ${value}`);
};
const columns = [
    {
        title: '类型',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: '销量（吨）',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '销售额（万元）',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '订单量',
        dataIndex: 'address',
        key: 'address',
    }
];
const data = [
    {
        key: '1',
        name: '水泥P.O42.5级散',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];

const App = () => {
    const [size, setSize] = useState('middle');
    const handleSizeChange = (e) => {
        setSize(e.target.value);
    };
    return (
        <Space direction="vertical" size={12}>
            <Radio.Group value={size} onChange={handleSizeChange}>
                <Radio.Button value="日销">日销</Radio.Button>
                <Radio.Button value="月累计">月累计</Radio.Button>
                <Radio.Button value="年累计">年累计</Radio.Button>
            </Radio.Group>
        </Space>
    );
};

const content = ({ children, extra }) => (
    <div className="content">
        <div className="main">{children}</div>
        <div className="extra">{extra}</div>
    </div>
);

class PageDataCurve extends Component {


    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        const pid = params.get("area");
        this.state = {
            title: "销量曲线",
            county: [],
            area: pid,
            list: []
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Leftmenu/>
                <div className="mainpanel">
                    <Header/>
                    <PageBreadcrumb title={this.state.title}/>
                    <Content className="site-layout-background">
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                onBack={() => window.history.back()}
                                title={this.state.title}
                                subTitle="销量数据同比，环比曲线"
                            >
                                <Space>
                                    <Select
                                        defaultValue="富平公司"
                                        style={{
                                            width: 120,
                                        }}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: '0',
                                                label: '富平公司',
                                            }
                                        ]}
                                    />
                            </Space>
                                <br/>
                                <br/>
                                <Title level={4}>两年内销量曲线</Title>
                                <Datasellonyear />
                                <Title level={4}>同环比销量曲线</Title>
                                <Datasellonmonth/>
                            </PageHeader>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default PageDataCurve;


